import InitialState from './initial-state';
import { caseEditsActions } from '../actions/case-edits';
import { getDetail } from '../actions/cases.action';
import { cmxReducerFactory } from './reducer-utils';
import { launchPageActions } from '@codametrix/ui-common';

const reducer = cmxReducerFactory(InitialState.caseEdits)
  .case(caseEditsActions.updateValue, (state, fieldEdit) => {
    const { fieldKey, newValue } = fieldEdit;
    const { caseRun, caseEdits } = state;

    const initialVal = caseRun ? caseRun[fieldKey as keyof CMxAPI.CaseRun] : '';

    caseEdits[fieldKey] = {
      ...caseEdits[fieldKey],
      options: caseEdits[fieldKey]?.options ?? [],
      initialValue: initialVal,
      newValue,
      valueState: 'edit'
    };

    return state;
  })
  .case(getDetail.async.started, (state, payload) => {
    // Clear edits
    return { ...InitialState.caseEdits };
  })
  .cases([getDetail.async.done], (state, { result }) => {
    return {
      ...state,
      caseRun: result.caseRuns[0]
    };
  })
  .cases([launchPageActions.caseRunAvailable], (state, result) => {
    return {
      ...state,
      caseRun: result
    };
  })
  .case(
    caseEditsActions.loadAllPatientDetailOptions.async.started,
    (state, result) => {
      return {
        ...state,
        caseEdits: InitialState.caseEdits.caseEdits
      };
    }
  )
  .case(
    caseEditsActions.loadPatientDetailOptions.async.done,
    (state, { params, result }) => {
      const { dictionary } = params;
      const { caseRun, caseEdits } = state;

      const initialValue = caseRun
        ? caseRun[dictionary as keyof CMxAPI.CaseRun]
        : '';

      caseEdits[dictionary] = {
        options: result.map(item => item.label),
        initialValue,
        newValue: '',
        valueState: 'edit',
        mappedValues: result
      };
      return {
        ...state
      };
    }
  );

export default reducer;
