import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { runtimeOverrides } from '../runtime-overrides';

export let isNewDesign: boolean;

const newDesignMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  const {
    ui: { config }
  }: CMx.ApplicationState = api.getState();

  isNewDesign =
    (config.isNewDesign || runtimeOverrides?.newAdminDesign === 'true') &&
    runtimeOverrides?.newAdminDesign !== 'false';

  return next(action);
};

export default newDesignMiddleware;
