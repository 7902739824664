import { Divider, Grid, GridItem } from '@chakra-ui/react';
import React from 'react';
import {
  CheckboxMenu,
  CheckboxOption
} from '../../components/checkbox-menu/checkbox-menu';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as GridIcon } from '../../assets/images/grid.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/location.svg';
import { DatePickerComponent } from '../../components/datepicker-component/datepicker-component';
import { Button } from '../../components/button/button';
import { ButtonVariant } from '../../core/enums';
import { useDispatch } from 'react-redux';
import { applyDateFilter, applyFilters } from '../../actions/cases.action';
import { cmxDateTime, commonEnums as enums } from '@codametrix/ui-common';
import { styles } from './pop-up-filtersV2.styles';
import { Select } from '../../components/select/select';
import { applyFilters as applyDictionaryFilters } from '../../actions/dictionary-values';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';

const { FilterTypes } = enums;

const iconMapper = (categoryKey: string) => {
  switch (categoryKey) {
    case 'tenant_id':
      return <LocationIcon style={{ marginBottom: '1px' }} />;
    case 'customer_phase':
      return <GridIcon style={{ marginBottom: '1px' }} />;
    default:
      return <CheckCircle fill="#7498A6" style={{ marginBottom: '1px' }} />;
  }
};

const PopUpFiltersV2: React.FC<AppProps.PopUpFilterProps> = props => {
  const {
    criteriaFilter,
    sortablePageable,
    getDictionary,
    getStatuses,
    getTenants,
    clearFilters,
    activeOrgId,
    setFilterableOptions,
    isCases
  } = props;
  const classes = styles();
  const dispatch = useDispatch();

  const hasMultipleFilters = criteriaFilter?.filterForms?.length > 1;

  const handleFilterCategoryClick = (categoryIndex: string) => {
    switch (categoryIndex) {
      case 'procedure_type_category':
        getDictionary({
          categoryName: 'Procedure Type Categories',
          key: 'procedure_type_category'
        });
        break;
      case 'case_status':
        getStatuses({
          categoryName: 'casestatus',
          key: 'case_status'
        });
        break;
      case 'tenant_id':
        getTenants(activeOrgId);
        break;
    }
  };

  const handleFilterSubmit = (
    key: string,
    selectedOptions: CheckboxOption[]
  ) => {
    var state: { key: string; [key: string]: any } = {
      key
    };

    let filters: CMxCommonApp.Filter[] = [];

    if (criteriaFilter.filters) {
      filters = criteriaFilter.filters
        .filter(filter => {
          return (
            filter.type !== 'GREATER_THAN_EQUAL' &&
            filter.type !== 'LESS_THAN_EQUAL'
          );
        })
        .filter(filter => {
          return filter.key !== state.key;
        });
    }

    let newFilter: CMxCommonApp.Filter;
    let terms: string[] = [];
    let keyName: string;

    const keys = Object.keys(state);
    keys.forEach(key => {
      if (key !== 'key') {
        if (state[key] === true) {
          terms.push(key);
        }
      } else {
        keyName = state[key];
      }

      terms = [];

      selectedOptions.forEach(option => {
        terms.push(option.value);
      });

      if (keyName !== undefined) {
        newFilter = {
          key: keyName,
          terms: terms,
          type: FilterTypes.IN
        };

        if (terms.length !== 0) {
          filters.push(newFilter);
        }

        var listOptions = sortablePageable;
        listOptions.filterableOptions.filters = filters;
        listOptions.filterableOptions.dateFilter =
          sortablePageable.filterableOptions.dateFilter;
        listOptions.pageableDefinition.pageable.pageNumber = 0;
        listOptions.pageableDefinition.number = 0;
        listOptions.pageableDefinition.first = true;
        listOptions.pageableDefinition.last = false;
        listOptions.pageableDefinition.pageable.offset = 0;
        setFilterableOptions &&
          setFilterableOptions({
            dateFilter: sortablePageable.filterableOptions.dateFilter,
            filters
          });
        if (sortablePageable.filterableOptions.filters) {
          dispatch(applyFilters(sortablePageable.filterableOptions.filters));
        } else {
          dispatch(applyFilters([]));
        }
      }
    });
  };

  const handleDateFilterSubmit = (
    startDate: Date | null,
    endDate: Date | null,
    filterKey: string
  ) => {
    let newFilter: CMxCommonApp.DateFilter | undefined;

    if (startDate && endDate) {
      newFilter = {
        key: filterKey,
        from: cmxDateTime.format(
          startDate.toISOString(),
          cmxDateTime.FORMATS.DATE
        ),
        to: cmxDateTime.format(endDate.toISOString(), cmxDateTime.FORMATS.DATE),
        type: FilterTypes.BETWEEN
      };
    } else {
      newFilter = undefined;
    }

    var listOptions = sortablePageable;
    listOptions.filterableOptions.filters = sortablePageable.filterableOptions.filters?.filter(
      filter => {
        return (
          filter.type !== 'GREATER_THAN_EQUAL' &&
          filter.type !== 'LESS_THAN_EQUAL'
        );
      }
    );
    listOptions.filterableOptions.dateFilter = newFilter;
    listOptions.pageableDefinition.pageable.pageNumber = 0;
    listOptions.pageableDefinition.number = 0;
    listOptions.pageableDefinition.first = true;
    listOptions.pageableDefinition.last = false;
    listOptions.pageableDefinition.pageable.offset = 0;
    setFilterableOptions &&
      setFilterableOptions({
        dateFilter: newFilter,
        filters: sortablePageable.filterableOptions.filters
      });
    dispatch(
      applyDateFilter(
        sortablePageable.filterableOptions.dateFilter as CMxCommonApp.DateFilter
      )
    );
  };

  const handleSelectFilterSubmit = (
    event: React.MouseEvent<HTMLElement>,
    form: AppProps.CriteriaForm
  ) => {
    const target = event.currentTarget as HTMLButtonElement;
    let filters: CMxCommonApp.Filter[] = [];

    filters = criteriaFilter.filters
      .filter(filter => {
        return (
          filter.type !== 'GREATER_THAN_EQUAL' &&
          filter.type !== 'LESS_THAN_EQUAL'
        );
      })
      .filter(filter => {
        return filter.key !== form.key;
      });

    let filterType: CMxCommonApp.FilterTypes = FilterTypes.IN;
    if (form.formDefinition.fieldGroups?.length) {
      filterType =
        form.formDefinition.fieldGroups[0].fields.find(
          field => field.key === target.value
        )?.filterType || filterType;
    }

    const newFilter = {
      key: form.key,
      terms: [target.value],
      type: filterType
    };

    filters.push(newFilter);

    var listOptions = sortablePageable;
    listOptions.filterableOptions.filters = filters;
    listOptions.filterableOptions.dateFilter =
      sortablePageable.filterableOptions.dateFilter;
    if (target.value) {
      dispatch(applyDictionaryFilters(target.value));
    } else {
      dispatch(applyDictionaryFilters(''));
    }
  };

  const getCheckboxItems = (items: CMxCommonApp.FieldDefinition[]) => {
    return items?.map(item => {
      return {
        label: item.helpText ?? '',
        value: item.key ?? '',
        children: item.children
      };
    }) as CheckboxOption[];
  };

  const filterDropdowns = criteriaFilter?.filterForms
    .filter(form => {
      return !form.disabled;
    })
    .map((form, index) => {
      if (form.isExtra) {
        let selected: CMxCommonApp.FieldDefinition[] = [];
        if (form.formDefinition.fieldGroups) {
          form.formDefinition.fieldGroups[0].fields?.[0]?.children?.forEach(
            (field: CMxCommonApp.FieldDefinition) => {
              criteriaFilter?.filters
                ?.find(filter => {
                  return filter.key === form.key;
                })
                ?.terms.forEach(term => {
                  term === field.key && selected.push(field);
                });
            }
          );
        }

        return (
          <>
            {index > 0 && (
              <GridItem>
                <Divider orientation="vertical" sx={classes.divider} />
              </GridItem>
            )}
            <GridItem
              key={form.name}
              id={form.key}
              onClick={() =>
                !form?.formDefinition?.fieldGroups?.[0]?.fields?.length &&
                handleFilterCategoryClick(form.key)
              }
              data-testid="extraFilter">
              <CheckboxMenu
                options={
                  (form?.formDefinition?.fieldGroups?.[0]?.fields &&
                    getCheckboxItems(
                      form.formDefinition.fieldGroups[0].fields
                    )) ??
                  []
                }
                label={form.name}
                onApplyFilters={selectedOptions => {
                  handleFilterSubmit(form.key, selectedOptions);
                }}
                selectedOptions={getCheckboxItems(selected) ?? []}
                menuIcon={iconMapper(form.key)}
                isTree
              />
            </GridItem>
          </>
        );
      }
      if (form.isDate) {
        let startDate = new Date(
          cmxDateTime.format(
            criteriaFilter?.dateFilter?.from!,
            cmxDateTime.FORMATS.DATE
          )
        );
        let endDate = new Date(
          cmxDateTime.format(
            criteriaFilter?.dateFilter?.to!,
            cmxDateTime.FORMATS.DATE
          )
        );
        // Fixing display date off by 1
        startDate.setDate(startDate.getDate() + 1);
        endDate.setDate(endDate.getDate() + 1);
        return (
          <>
            {index > 0 && (
              <GridItem>
                <Divider orientation="vertical" sx={classes.divider} />
              </GridItem>
            )}
            <GridItem width="160px">
              <DatePickerComponent
                startDate={criteriaFilter?.dateFilter?.to ? startDate : null}
                endDate={criteriaFilter?.dateFilter?.from ? endDate : null}
                onSelection={(startDate, endDate) => {
                  startDate &&
                    endDate &&
                    handleDateFilterSubmit(startDate, endDate, form.key);
                }}
                iconLeft={<CalendarIcon />}
                showActiveBackground={isCases}
              />
            </GridItem>
          </>
        );
      }
      if (form.isSelect === true) {
        let defaultVal: string = '';

        if (form.formDefinition.fieldGroups) {
          form.formDefinition.fieldGroups.forEach(group => {
            group.fields.forEach(field => {
              const filterMatch = criteriaFilter?.filters
                ?.find(filter => {
                  return filter.key === form.key;
                })
                ?.terms.find(term => {
                  return term === field.value;
                });

              if (filterMatch) {
                defaultVal = field.label ?? '';
              }
            });
          });
          if (defaultVal.length === 0) {
            const defaultField = form.formDefinition?.fieldGroups[0].fields[0];
            defaultVal = defaultField.label ?? '';
          }
        }

        return (
          <>
            <Select
              items={
                form.formDefinition.fieldGroups?.length
                  ? form.formDefinition.fieldGroups[0].fields?.map(field => {
                      return { label: field.label ?? null, value: field.key };
                    })
                  : []
              }
              onChange={event => handleSelectFilterSubmit(event, form)}
              dataTestId={'searchSelect'}
              value={defaultVal}
            />
          </>
        );
      }

      let selected: CMxCommonApp.FieldDefinition[] = [];
      if (form.formDefinition.fieldGroups) {
        form.formDefinition.fieldGroups[0].fields.forEach(field => {
          criteriaFilter?.filters
            ?.find(filter => {
              return filter.key === form.key;
            })
            ?.terms.forEach(term => {
              term === field.key && selected.push(field);
            });
        });
      }

      return (
        <>
          {index > 0 && (
            <GridItem>
              <Divider orientation="vertical" sx={classes.divider} />
            </GridItem>
          )}
          <GridItem
            key={form.name}
            id={form.key}
            onClick={() =>
              !form?.formDefinition?.fieldGroups?.[0]?.fields?.length &&
              handleFilterCategoryClick(form.key)
            }
            data-testid={`checkboxFilter${index}`}>
            <CheckboxMenu
              options={
                (form?.formDefinition?.fieldGroups?.[0]?.fields &&
                  getCheckboxItems(
                    form.formDefinition.fieldGroups[0].fields
                  )) ??
                []
              }
              label={form.name}
              onApplyFilters={selectedOptions => {
                handleFilterSubmit(form.key, selectedOptions);
              }}
              selectedOptions={getCheckboxItems(selected) ?? []}
              menuIcon={iconMapper(form.key)}
            />
          </GridItem>
        </>
      );
    });

  return (
    <div>
      <Grid display={'flex'}>
        {filterDropdowns}
        {hasMultipleFilters && (
          <>
            <GridItem>
              <Divider orientation="vertical" sx={classes.divider} />
            </GridItem>
            <GridItem>
              <Button
                label="clear all"
                variant={ButtonVariant.LINKS}
                height={'24px'}
                onClick={() => clearFilters!()}
                dataTestId="clearAllButton"
              />
            </GridItem>
          </>
        )}
      </Grid>
    </div>
  );
};

PopUpFiltersV2.defaultProps = {
  getDictionary: (categoryParams: AppProps.CategoryParams) => {},
  getStatuses: (categoryParams: AppProps.CategoryParams) => {},
  list: () => {},
  getTenants: () => {}
};

PopUpFiltersV2.displayName = 'PopupFiltersV2';

export { PopUpFiltersV2 };
