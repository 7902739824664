import { Flex } from '@chakra-ui/react';
import { goBack } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveDictionaryRow } from '../../../actions/dictionary-values';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { ModalComponent } from '../../../components/Modal/modal';
import { Form } from '../form';
import { WarningTwoIcon } from '@chakra-ui/icons';
import { styles } from '../dictionary-styles';

const AddRowForm = () => {
  const classes = styles();
  const rowEditForm = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.rowAddForm;
  });

  const selectedDictionary = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.selectedDictionary;
  });

  const items = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.items;
  });

  const [rowItem, setRowItem] = useState<Record<string, string>>({});
  const [duplicateKeyMessage, setDuplicateKeyMessage] = useState<boolean>(
    false
  );

  const getMaxRowIndex = () => {
    if (items.length > 0) {
      const maxItem = items.reduce((prev, current) =>
        prev && prev.rowIndex > current.rowIndex ? prev : current
      );
      return maxItem.rowIndex;
    }
    return 0;
  };

  const dispatch = useDispatch();

  const handleAction = () => {
    dispatch(goBack());
  };

  const handleFormChange = (prop: string, value: string) => {
    let rowItemToUpdate = { ...rowItem };
    rowItemToUpdate[prop] = value;
    setRowItem(rowItemToUpdate);
  };

  const handleSave = (row: any) => {
    const cells: CMxAPI.CellDto[] = [];
    let payload: CMxAPI.DictionaryRowDto = {
      id: null,
      tenantId: selectedDictionary.tenantId.uuid,
      rowIndex: getMaxRowIndex() + 1,
      status: selectedDictionary.status,
      editStatus: selectedDictionary.editStatus,
      effectiveDate: selectedDictionary.effectiveDate,
      endDate: selectedDictionary.endDate,
      effectiveDateLocal: null,
      endDateLocal: null,
      dictionaryId: selectedDictionary.id,
      dictionaryName: selectedDictionary.name,
      dictionary: selectedDictionary,
      cells: cells
    };
    selectedDictionary.columnDefinitions.forEach(colDef =>
      cells.push({
        id: row[colDef.name + 'Id'],
        tenantId: selectedDictionary.tenantId.uuid,
        value: row[colDef.name],
        codeType: '',
        status: selectedDictionary.status,
        editStatus: selectedDictionary.editStatus,
        columnDefinition: colDef,
        columnDefinitionId: colDef.id,
        rowId: row.id,
        rowIndex: row.rowIndex,
        columnName: colDef.name,
        effectiveDate: selectedDictionary.effectiveDate,
        endDate: selectedDictionary.endDate,
        effectiveDateLocal: null,
        endDateLocal: null,
        row: null
      })
    );
    const keyColumn = selectedDictionary.columnDefinitions.find(
      colDef => colDef.keyColumnIndicator
    );
    const keyColumnId = keyColumn?.id;
    const keyColumnName = keyColumn?.name;
    const newKeyValue = cells.find(
      cell => cell.columnDefinitionId === keyColumnId
    )?.value;
    if (keyColumnName) {
      const keys = items.map(item => item[keyColumnName]);
      if (newKeyValue && keys.includes(newKeyValue)) {
        setDuplicateKeyMessage(true);
        return;
      }
    }

    dispatch(saveDictionaryRow(payload));
    dispatch(goBack());
  };

  const enabler = () => {
    return true;
  };

  return (
    <>
      <Flex marginBottom={5}>
        <BreadCrumbsComponent />
      </Flex>
      <Form
        formDefinition={rowEditForm}
        dataItem={rowItem}
        inline={false}
        enable={enabler}
        onAction={handleAction}
        onPropChange={handleFormChange}
        onSave={() => handleSave(rowItem)}
        title="Add Row"
        errors={{
          status: '',
          message: null,
          errors: [],
          fieldErrors: {}
        }}
      />
      <ModalComponent
        size={'s'}
        isOpen={duplicateKeyMessage}
        handleClose={() => setDuplicateKeyMessage(false)}
        showCloseIcon={true}
        modalHeader={
          <Flex sx={classes['duplicateKeyErrorHeader']}>
            <WarningTwoIcon /> Add Row Error
          </Flex>
        }
        modalContent={
          <div>
            Entered key column already exists in this dictionary. Please enter a
            different value.
          </div>
        }></ModalComponent>
    </>
  );
};

AddRowForm.displayName = 'AddRowForm';
export { AddRowForm };
