/// <reference path="../../src/types.d.ts" />
import { AmplifyCore } from '@codametrix/ui-common';
import { cases } from './state/cases';
import { cmxState } from './state/cmx-application';
import { login } from './state/login';
import { organization } from './state/organization';
import { router } from './state/router';
import { ui } from './state/ui';
import { users } from './state/users';
import { auditLog } from './state/audit-log';
import { assigningAuthorities } from './state/assigning-authority';
import { analytics } from './state/analytics';
import { dictionary } from './state/dictionary';
import { dictionaryValues } from './state/dictionary-values';
import { forgotPassword } from './state/forgot-password';
import { eula } from './state/eula';
import { dictionaryTenants } from './state/dictionary-tenants';
import { dictionaryRequest } from './state/dictionary-request';
import { codeLookup } from './state/code-lookup';
import { accountSettings } from './state/account-settings';
import { externalApplications } from './state/external-application';
import { caseConfig } from './state/case-config/case-config';
import { group } from './state/groups';
import { rulesList } from './state/rules-list';
import { caseEdits } from './state/case-edits';
import { patientTimeline } from './state/patient-timeline.state';
import { createCase } from './state/create-case.state';
import { mergeCases } from './state/merge-cases.state';
import { attachDocs } from './state/attach-docs.state';
import { providerDetails } from './state/provider-details-state';
import { normalTime } from './state/normal-time';
import { myStatus } from './state/my-status.state';
import { capturedTime } from './state/captured-time.state';
import { coderNotes } from './state/coder-notes.state';

const AppState: CMx.ApplicationState = {
  analytics,
  cases,
  ui,
  users,
  auditLog,
  login,
  organization,
  cmx: cmxState,
  router,
  assigningAuthorities,
  dictionary,
  dictionaryValues,
  eula,
  dictionaryTenants,
  forgotPassword,
  dictionaryRequest,
  codeLookup,
  accountSettings,
  myStatus,
  capturedTime,
  externalApplications,
  caseConfig,
  group,
  rulesList,
  patientTimeline,
  createCase,
  mergeCases,
  attachDocs,
  ampUI: { ...AmplifyCore.state.ampUI },
  invoiceMetadata: { ...AmplifyCore.state.invoiceMetadata },
  codeBench: { ...AmplifyCore.state.codeBenchState },
  caseEdits,
  providerDetails,
  coderNotes,
  workingHours: normalTime
};

export default AppState;
