import { codeBenchActions, commonEnums } from '@codametrix/ui-common';
import { getPrevOrNextCase } from '../../actions/cases.action';

const { loadNextOrPreviousCase } = codeBenchActions;
export const serviceDeskMiddleware = (store: any) => (next: any) => (
  action: any
) => {
  if (action.type === loadNextOrPreviousCase.type && action.payload) {
    const status = action.payload.status?.toUpperCase();
    const cursorAPIPayload = action.payload?.cursorAPIPayload;
    const cursorAPIContext = cursorAPIPayload?.cursorAPIContext;
    if (status === commonEnums.FeedbackMeta.SUCCESS) {
      if (cursorAPIContext?.servicedeskMode && cursorAPIContext?.workitemId) {
        store.dispatch(getPrevOrNextCase(cursorAPIPayload));
      }
    }
  }
  return next(action);
};
