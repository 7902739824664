import actionCreatorFactory from 'typescript-fsa';
import { CaseEdits } from './action-types';
import { api } from '../core/net';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import {
  cmxDateTime,
  HttpMethods,
  codeBenchActions
} from '@codametrix/ui-common';
import { patientDetailDictionaries } from '../reducers/state/case-edits';

const { fetchActivity } = codeBenchActions;

const actionCreator = actionCreatorFactory();

type UpdatedValue = {
  fieldKey: string;
  newValue: string;
};

const updateValue = actionCreator<UpdatedValue>(CaseEdits.UPDATE_VALUE);

const createAsync = asyncFactory<Accelerate.BenchApplicationState>(
  actionCreator
);
interface loadPatientDetailsPayload {
  activeTenantId: string;
  dictionary: string;
}

const loadPatientDetailOptions = createAsync<
  loadPatientDetailsPayload,
  { label: string; value: string }[],
  CMxCommonApp.SubmitError
>(CaseEdits.LOAD_PATIENT_DETAIL_OPTIONS, async (payload, dispatch) => {
  const { activeTenantId, dictionary } = payload;
  const dictionaryInfo = patientDetailDictionaries[dictionary];
  let fieldOptions: { label: string; value: string }[] = [];
  try {
    if (dictionaryInfo) {
      let { dictionaryName, columnName } = dictionaryInfo as any;

      const searchParams = new URLSearchParams({
        tenantId: activeTenantId,
        derivedIndicator: 'true',
        effectiveDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE),
        dictionaryName: dictionaryName as string,
        columnName: columnName as string
      });

      const res = await api<CMxAPI.RowData>({
        endpoint: `/dictionary/row/unrestricted/v2?${searchParams.toString()}`,
        init: {
          method: HttpMethods.GET
        }
      });

      fieldOptions = res.rowValues.map(row => {
        if (dictionaryName === 'Department') {
          return {
            label: row['Unit/Department'],
            value: row['Unit/Department ID']
          };
        } else {
          return {
            label: row[columnName],
            value: row[columnName]
          };
        }
      });
    }
  } catch (e) {
    console.log(e);
  }

  return fieldOptions;
});

const loadAllPatientDetailOptions = createAsync<
  string,
  void,
  CMxCommonApp.SubmitError
>(CaseEdits.LOAD_ALL_PATIENT_DETAIL_OPTIONS, async (tenantId, dispatch) => {
  Object.keys(patientDetailDictionaries).forEach(dictionary => {
    dispatch(
      loadPatientDetailOptions({
        activeTenantId: tenantId,
        dictionary
      })
    );
  });
});

type UpdatedCaseFields = {
  caseRunUid: number;
  serviceLine: string;
  caseUpdates: any;
  initialVal: any;
  mappedValues: any;
};

const updateCase = createAsync<
  UpdatedCaseFields,
  void,
  CMxCommonApp.SubmitError
>(CaseEdits.UPDATE_CASE, async (params, dispatch) => {
  const {
    caseRunUid,
    serviceLine,
    caseUpdates,
    initialVal,
    mappedValues
  } = params;

  if (Object.keys(caseUpdates).includes('scheduling_department')) {
    const code = mappedValues.find((map: { label: string; value: string }) => {
      return map.label === caseUpdates['scheduling_department'];
    })?.value;

    caseUpdates['scheduling_department_code'] = code;
  }

  try {
    const updatedProcessInstance = await api<AmplifyAPI.ProcessInstance>({
      endpoint: `/coding-api/processinstance/${serviceLine}/${caseRunUid}/v1`,
      init: {
        method: HttpMethods.PUT,
        body: JSON.stringify(caseUpdates)
      }
    });

    dispatch(fetchActivity(updatedProcessInstance));
  } catch (e) {
    dispatch(updateValue(initialVal));
  }
});

export const caseEditsActions = {
  updateValue,
  loadPatientDetailOptions,
  loadAllPatientDetailOptions,
  updateCase
};
