import { User } from './action-types';
import { push } from 'connected-react-router';
import { USER_STUB } from '../tests/fixtures/stubs/user';
import { showFeedback } from './ui';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { Dispatch } from 'redux';
import {
  authenticationContext,
  commonEnums,
  HttpMethods
} from '@codametrix/ui-common';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const { FilterTypes } = commonEnums;
/** factories for actions */
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.UserState>(actionCreator);
/** The sync action creators */
const userList = actionCreator<CMxAPI.PageableList<CMxAPI.User>>(User.LIST);
const userSelected = actionCreator<CMxAPI.User>(User.USER_SELECTED, {
  ...Object.freeze({ user: true })
});
const updateRole = actionCreator<CMxAPI.User>(User.ROLE_UPDATE);
const availableRoles = actionCreator<CMxAPI.SystemRole[]>(User.AVAILABLE_ROLES);
const availableOrgs = actionCreator<CMxAPI.Organization>(
  User.AVAILABLE_ORGANIZATIONS
);
const formSubmissionError = actionCreator<CMxCommonApp.FormErrors>(
  User.FORM_SUBMISSION_ERROR
);
const addUserDetails = actionCreator<CMxAPI.User>(User.ADD_USER_DETAILS);

const toggleForcePasswordOption = actionCreator<boolean>(
  User.TOGGLE_PASSWORD_OPTIONS
);

const togglePasswordChangeModal = actionCreator<boolean>(
  User.TOGGLE_PASSWORD_CHANGE_MODAL
);

const toggleFilterOn = actionCreator<boolean>(User.TOGGLE_FILTER_ON);
const toggleShowErrors = actionCreator<boolean>(User.TOGGLE_SHOW_ERRORS);

/** async actions */

const list = createAsync<
  CMxCommonApp.SortablePageable<CMxAPI.User>,
  CMxAPI.PageableList<CMxAPI.User>,
  CMxCommonApp.SubmitError
>(User.LIST, async (params, dispatch) => {
  const sortablePageable = params;

  const searchParams = paginationUtils.searchParamsFactory(sortablePageable);

  let body: any[] = [];

  const filters = sortablePageable.filterableOptions.filters;

  if (filters) {
    filters.forEach(filter => {
      body.push(filter);
    });
  } else {
    body.push({
      key: 'role',
      terms: ['true'],
      type: FilterTypes.IN
    });
  }

  const users = await api<CMxAPI.PageableList<CMxAPI.User>>({
    endpoint: `/users/list/v1?${searchParams.toString()}`,
    init: {
      method: HttpMethods.POST
    },
    body: body
  });

  dispatch(userList(users));
  return users;
});

const updateUser = createAsync<SaveArgs, CMxAPI.User, CMxCommonApp.SubmitError>(
  User.ROLE_UPDATE,
  async (params, dispatch) => {
    const { user } = params;
    dispatch(updateRole(user));
    return user;
  }
);

type SaveArgs = {
  token: CMxCommonApp.BearerToken;
  user: CMxAPI.User;
};

/** The asynchronous login action; Error type is optional */
const save = createAsync<SaveArgs, CMxAPI.User, CMxCommonApp.SubmitError>(
  'Save User',
  async (params, dispatch) => {
    const { user, token } = params;
    const isUpdate = user.id !== null;
    const method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;
    const endpoint: string = isUpdate
      ? `/users/${user.userId}/v1`
      : `/users/v1`;

    const userDetails = await api<CMxAPI.User>({
      endpoint,
      init: { method },
      headers: {
        Authorization: token.token
      },
      body: user
    });

    dispatch(push(`/cmx/admin/users/`));

    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Saved information for ${userDetails.firstName} ${userDetails.lastName}.`,
        dismissable: true
      })
    );

    return userDetails;
  }
);

const addUser = createAsync<CMx.AddUserArgs, void, CMxCommonApp.SubmitError>(
  'Add User',
  async (params, dispatch) => {
    const { orgContext } = params;
    dispatch(userSelected(USER_STUB));
    dispatch(push(`/cmx/admin/users/new/`));
    dispatchOrgAndRoles(dispatch, orgContext);
  }
);

const dispatchOrgAndRoles = async (
  dispatch: Dispatch,
  orgContext: CMxAPI.OrganizationContext
) => {
  const org = await api<CMxAPI.Organization>({
    endpoint: `/organization/id/v1?organizationId=${orgContext.organizationId}`
  });
  dispatch(availableOrgs(org));

  const roles = await api<CMxAPI.SystemRole[]>({
    endpoint: `/roles/v1/`
  });
  dispatch(availableRoles(roles));
};

const getDetail = createAsync<
  CMx.UserArgs,
  CMxAPI.User,
  CMxCommonApp.SubmitError
>(User.USER_SELECTED, async (params, dispatch) => {
  const { user, orgContext } = params;
  const userDetails = await api<CMxAPI.User>({
    endpoint: `/users/${user.userId}/v1`
  });
  dispatch(userSelected(userDetails));
  dispatch(push(`/cmx/admin/users/${userDetails.id}/`));
  dispatchOrgAndRoles(dispatch, orgContext);

  return userDetails;
});

const changeUsersPassword = createAsync<
  CMx.ChangeUserPasswordData,
  void,
  CMxCommonApp.SubmitError
>(
  User.CHANGE_USERS_PASSWORD,
  async (userData, dispatch): Promise<void> => {
    const { selectedUser, ...passwords } = userData;

    const adminToken = authenticationContext.getToken();

    await api<CMxAPI.User>({
      endpoint: `/users/${selectedUser.userId}/v1`,
      init: { method: HttpMethods.PUT },
      headers: {
        Authorization: adminToken
      },
      body: {
        ...selectedUser,
        forcePasswordChange: true,
        password: passwords.newPassword,
        confirmPassword: passwords.newPasswordConfirm
      }
    });
  }
);

export const actions = {
  list,
  userList,
  userSelected,
  updateRole,
  availableRoles,
  availableOrgs,
  formSubmissionError,
  save,
  addUser,
  addUserDetails,
  toggleForcePasswordOption,
  togglePasswordChangeModal,
  changeUsersPassword,
  toggleFilterOn,
  toggleShowErrors
};
export {
  getDetail,
  updateUser,
  addUser,
  save,
  list,
  addUserDetails,
  toggleForcePasswordOption,
  togglePasswordChangeModal,
  changeUsersPassword,
  toggleFilterOn,
  toggleShowErrors
};
