import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveDefaults,
  saveFormData,
  toggleIsOpen,
  updateFormDefinition
} from '../../actions/account-settings';
import { listNormalTime, saveNormalTime } from '../../actions/normal-time';
import { Button } from '../../components/button/button';
import { ModalComponent } from '../../components/Modal/modal';
import { MenuItemType, Select } from '../../components/select/select';
import { styles } from './account-settings.styles';
import {
  defaultServiceLine as serviceLineField,
  defaultOrg as orgField,
  defaultHomepage as homepageField
} from '../../reducers/state/account-settings';
import theme from '../../theme';
import { WorkingHours } from '../time-entry/working-hours';

export const AccountSettings = () => {
  const classes = styles();
  const {
    accountDefaults: { formDefinition, formObject }
  } = useSelector((state: CMx.ApplicationState) => state.accountSettings);

  const { context } = useSelector((state: CMx.ApplicationState) => state.ui);
  const { savedHours } = useSelector(
    (state: CMx.ApplicationState) => state.workingHours
  );
  const { user, contexts } = context;
  const dispatch = useDispatch();

  const resources = context.activeContext?.resources ?? [];
  const timeEntryResource = new Set(['protected_time']);
  const timeEntry = resources.some(resource =>
    timeEntryResource.has(resource.resourceName)
  );

  const [hours, setHours] = useState<{ [key: string]: number }>(savedHours);

  const [activeDays, setActiveDays] = useState<{ [key: string]: boolean }>({
    Sunday: false,
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false
  });

  useEffect(() => {
    if (timeEntry) {
      const userId = user.userId;
      const tenantId = context.activeContext?.tenantId || '';
      dispatch(listNormalTime({ dateToday: new Date(), userId, tenantId }));
    }
  }, [user.userId, context.activeContext?.tenantId, dispatch, timeEntry]);

  const handlePropChange = (prop: string, value: string) => {
    const selectedOrg = contexts.find(x => {
      return x.organizationId === formObject.defaultOrg;
    });
    if (prop === orgField.name) {
      dispatch(
        saveFormData({
          defaultOrg: parseInt(value),
          defaultServiceLine: -1,
          defaultHomepage: formObject.defaultHomepage
        })
      );
      dispatch(updateFormDefinition(context));
    }
    if (prop === serviceLineField.name) {
      dispatch(
        saveFormData({
          defaultOrg: formObject.defaultOrg,
          defaultServiceLine: parseInt(value),
          defaultHomepage: formObject.defaultHomepage
        })
      );
    }
    if (prop === homepageField.name && selectedOrg) {
      dispatch(
        saveFormData({
          defaultOrg: undefined,
          defaultServiceLine: undefined,
          defaultHomepage: {
            path: value,
            tenantId: selectedOrg.tenantId
          }
        })
      );
    }
    if (prop === homepageField.name && !selectedOrg) {
      dispatch(
        saveFormData({
          defaultOrg: undefined,
          defaultServiceLine: undefined,
          defaultHomepage: {
            path: value,
            tenantId: ''
          }
        })
      );
    }
  };

  const handleClose = () => {
    dispatch(toggleIsOpen(false));
  };

  const handleSave = () => {
    if (timeEntry) {
      let activeHours: { [key: string]: number } = {};
      Object.keys(hours).forEach(day => {
        if (activeDays[day] || (hours[day] >= 0 && hours[day] <= 24)) {
          activeHours[day] = hours[day];
        } else {
          activeHours[day] = 0;
        }
      });
      dispatch(saveNormalTime({ hours: activeHours, user, context }));
    }
    if (user.id) {
      const payload: CMx.SaveAccountDefaultPayload = {
        userId: user.id,
        ...formObject
      };
      dispatch(saveDefaults(payload));
    }
  };

  const onOptionChange = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement;
    const { name, value } = target;
    handlePropChange(name, value);
  };

  const optionConverter = (field: CMxCommonApp.FieldDefinition) => {
    const { options, labelField, valueField } = field;
    let opts: MenuItemType[] = [];
    options?.map((item: any) => {
      opts?.push({ label: item[labelField], value: item[valueField] });
      return {};
    });
    return opts;
  };

  const getSelectedValue = (
    field: CMxCommonApp.FieldDefinition,
    key: string
  ) => {
    const options = field?.options;
    const res: any = options?.filter(
      (item: any) => String(item[field.valueField]) === key
    );
    const selectedName = res && res?.[0]?.[field.labelField];
    return selectedName;
  };

  //formObject needs string values for now, since Form does not observe number values
  let formObjectStringValues: any = {};

  for (const [key, value] of Object.entries(formObject)) {
    formObjectStringValues[key] =
      key === 'defaultHomepage' ? value?.path : value?.toString();
  }
  return (
    <ModalComponent
      size={'xl'}
      width={'52rem'}
      isOpen={true}
      handleClose={handleClose}
      showCloseIcon={true}
      modalHeader={<div>Account Settings</div>}
      modalContent={
        <Flex flexDirection={'column'}>
          {formDefinition.fieldGroups?.map(item => {
            return (
              <fieldset style={classes.fieldset}>
                <legend style={classes.legend}>{item.label}</legend>
                <Flex
                  flexDirection={'column'}
                  rowGap={5}
                  padding={
                    theme.space[4] +
                    ' ' +
                    theme.space[16] +
                    ' ' +
                    theme.space[16] +
                    ' ' +
                    theme.space[16]
                  }>
                  {item.fields.map(field => {
                    return (
                      <Flex
                        flexDirection={'row'}
                        columnGap={5}
                        width={'66%'}
                        alignItems={'center'}>
                        <Text role="textbox" width={'25%'} paddingBottom={5}>
                          {field.label}
                        </Text>
                        <Flex flexDirection={'column'} width={'100%'}>
                          <div className="form-group">
                            <Select
                              items={optionConverter(field)}
                              name={field.name}
                              dataTestId={field.name}
                              value={
                                formObjectStringValues[field.key] &&
                                getSelectedValue(
                                  field,
                                  formObjectStringValues[field.key]
                                )
                              }
                              onChange={onOptionChange}
                            />

                            <Text role="textbox" sx={classes.label}>
                              {field.helpText}
                            </Text>
                          </div>
                        </Flex>
                      </Flex>
                    );
                  })}
                </Flex>
              </fieldset>
            );
          })}
          {timeEntry && (
            <fieldset style={classes.fieldset}>
              <legend style={classes.legend}>Working Hours</legend>
              <WorkingHours
                hours={hours}
                activeDays={activeDays}
                setHours={setHours}
                setActiveDays={setActiveDays}
              />
            </fieldset>
          )}
        </Flex>
      }
      modalFooter={
        <>
          <Button
            dataTestId="saveBtn"
            label={'Save'}
            onClick={handleSave}></Button>
        </>
      }
    />
  );
};
