export enum Login {
  LOGIN = 'LOGIN',
  LOGIN_STARTED = 'LOGIN_STARTED',
  LOGOUT = 'AUTH_LOGOUT',
  TIMEOUT = 'AUTH_TIMEOUT',
  FAILED = 'AUTH_FAILED',
  SUCCESS = 'AUTH_SUCCESS',
  INITIAL = 'AUTH_INITIAL',
  IN_PROGRESS = 'AUTH_IN_PROGRESS',
  FORCE_PASSWORD_CHANGE = 'FORCE_PASSWORD_CHANGE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_INITIAL = 'CHANGE_PASSWORD_INITIAL',
  CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED = '_CHANGE_PASSWORD_FAILED',
  AUTHENTICATE = 'AUTHENTICATE',
  COGNITO_AUTH = 'COGNITO_AUTH',
  JSPRING_AUTH = 'JSPRING_AUTH',
  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  POST_AUTH_NAVIGATE = 'POST_AUTH_NAVIGATE',
  GET_CONFIG = 'GET_CONFIG',
  SSO_LOGIN = 'SSO_LOGIN',
  EXCHANGE_TOKEN_SSO = 'EXCHANGE_TOKEN_SSO'
}

export enum CodeLookup {
  LOOKUP_CODES = 'LOOKUP_CODES'
}
export enum ForgotPassword {
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  SAVE_PASSWORD_DTO = 'SAVE_PASSWORD_DTO',
  SUBMIT_NEW_PASSWORD = 'SUBMIT_NEW_PASSWORD',
  INIT = 'INIT',
  SUBMIT_FORGOT_PASSWORD = 'SUBMIT_FORGOT_PASSWORD',
  _SUBMIT_NEW_PASSWORD = '_SUBMIT_NEW_PASSWORD'
}

export enum CaseDocuments {
  LOAD = 'CASE_DOCUMENTS_LOAD',
  CASE_DETAIL = 'CASE_DETAIL',
  FETCH_DOCUMENT = 'FETCH_DOCUMENT',
  FETCH_CASERUN_DOCUMENTS = 'FETCH_CASERUN_DOCUMENTS',
  DOCUMENT_METADATA = 'DOCUMENT_METADATA',
  NOTE = 'DOC_SERVICE_NOTE'
}

export enum Auth {
  TOKEN_AVAILABLE = 'TOKEN_AVAILABLE',
  TOGGLE_SSO_USER = 'TOGGLE_SSO_USER',
  SHOULD_REFRESH = 'SHOULD_REFRESH'
}

export enum Analytics {
  INSTANTIATE_DASHBOARD = 'INSTANTIATE_DASHBOARD',
  SELECTED = 'ANALYTICS_SELECTED',
  GET_TABLEAU_TOKEN = 'GET_TABLEAU_TOKEN',
  SET_ACTIVE_DASHBOARD = 'SET_ACTIVE_DASHBOARD'
}

export enum UserContext {
  JUMP_CONTEXT = 'JUMP_CONTEXT',
  CHOOSE_CONTEXT = 'CHOOSE_CONTEXT',
  DISPLAY_CONTEXTS = 'DISPLAY_CONTEXTS',
  LIST = 'LIST_CONTEXTS',
  REFRESH_CONTEXT = 'REFRESH_CONTEXT',
  ONLY_REFRESH_CONTEXT = 'ONLY_REFRESH_CONTEXT'
}

export enum UserConfig {
  CONFIG_AVAILABLE = 'CONFIG_AVAILABLE'
}

export enum ShortcutAction {
  NOT_FOUND = 'NOT_FOUND'
}

export enum Organization {
  ASSOCIATION_CREATED = 'ASSOCIATION_CREATED',
  NAVIGATE_ANCESTOR = 'NAVIGATE_ANCESTOR',
  NAVIGATE = 'ORG_NAVIGATE',
  ADD_CHILD = 'ADD_CHILD',
  GET_ORGANIZATION = 'GET_ORGANIZATION',
  LIST = 'LIST_ORGANIZATIONS',
  AVAILABLE_ASSOCIATIONS = 'AVAILABLE_ASSOCIATIONS',
  ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED',
  SAVE = 'ORGANIZATION_SAVE',
  IN_PROGRESS = 'IN_PROGRESS',
  FORM_SUBMISSION = 'FORM_SUBMISSION',
  FORM_SUBMISSION_ERROR = 'FORM_SUBMISSION_ERROR',
  ORG_CHILDREN = 'ORG_CHILDREN',
  RELATIONSHIP = 'RELATIONSHIP',
  ORG_TO_ORG_RELATIONSHIP = 'ORG_TO_ORG_RELATIONSHIP',
  TOGGLE_EDIT = 'TOGGLE_EDIT',
  GET_TENANT_FILTER = 'TENANT_FILTER',
  SERVICE_LINES = 'SERVICE_LINES',
  STORE_ACTIVE = 'STORE_ACTIVE',
  TIMEZONES = 'TIMEZONES',
  GET_FEATURES = 'GET_FEATURES',
  UPDATE_FEATURES = 'UPDATE_FEATURES',
  REPLACE_ORGANIZATION_STATE = 'REPLACE_ORGANIZATION_STATE'
}

export enum AssigningAuthorities {
  GET_HOSPITALS = 'GET_HOSPITALS',
  LOAD_FORM = 'LOAD_FORM',
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE'
}

export enum User {
  LIST = 'LIST_USERS',
  USER_SELECTED = 'USER_SELECTED',
  ROLE_UPDATE = 'ROLE_UPDATE',
  FORM_SUBMISSION = 'FORM_SUBMISSION',
  FORM_SUBMISSION_ERROR = 'USER_FORM_SUBMISSION_ERROR',
  AVAILABLE_ORGANIZATIONS = 'AVAILABLE_ORGANIZATIONS',
  AVAILABLE_ROLES = 'AVAILABLE_ROLES',
  ADD_USER_DETAILS = 'ADD_USER_DETAILS',
  TOGGLE_PASSWORD_OPTIONS = 'TOGGLE_PASSWORD_OPTIONS',
  TOGGLE_PASSWORD_CHANGE_MODAL = 'TOGGLE_PASSWORD_CHANGE_MODAL',
  CHANGE_USERS_PASSWORD = 'CHANGE_USERS_PASSWORD',
  TOGGLE_FILTER_ON = 'TOGGLE_FILTER_ON',
  TOGGLE_SHOW_ERRORS = 'TOGGLE_SHOW_ERRORS'
}

export enum AuditLog {
  LIST_AUDIT_LOG = 'LIST_AUDIT_LOG',
  FILTER = 'CASE_FILTER',
  DATE_FILTER = 'DATE_FILTER',
  CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS',
  SET_AUDIT_LOG_TYPE = 'SET_AUDIT_LOG_TYPE'
}

export enum Metadata {
  SOURCES = 'METADATA_SOURCES',
  LIST_DEFINITIONS = 'LIST_DEFINITIONS',
  DEFINITION_SELECTED = 'DEFINITION_SELECTED',
  FETCH_DETAIL = 'FETCH_DEFINTION_DETAIL',
  SAVE_DEFINITION = 'SAVE_DEFINITION'
}

export enum Group {
  LIST = 'LIST_GROUPS',
  SELECTED = 'GROUP_SELECTED',
  NAVIGATE = 'GROUP_NAVIGATE',
  SAVE = 'SAVE_GROUP',
  DELETE = 'DELETE_GROUP',
  FETCH_DETAIL = 'FETCH_GROUP_DETAIL',
  CHOOSE_GROUP = 'CHOOSE_GROUP',
  LIST_GROUP_MEMBERS = 'LIST_GROUP_MEMBERS',
  ADD_GROUP_USER = 'ADD_GROUP_USER'
}

export enum Dictionary {
  TENANT_SELECTED = 'TENANT_SELECTED',
  LIST = 'LIST_DICTIONARY',
  SELECTED = 'DICTIONARY_SELECTED',
  CHOOSE_ORG = 'CHOOSE_ORG',
  CHOOSE_DICTIONARY = 'CHOOSE_DICTIONARY',
  VALUE_SELECTED = 'DICTIONARY_VALUE_SELECTED',
  FETCH_DETAIL = 'FETCH_DICTIONARY_DETAIL',
  SAVE = 'SAVE_DICTIONARY',
  NAVIGATE = 'DICTIONARY_NAVIGATE',
  PUSH_PATH = 'PUSH_PATH',
  SAVE_ASSOCIATED_DICTIONARIES = 'SAVE_ASSOCIATED_DICTIONARIES',
  UPDATE_PUBLISHED_METADATA = 'UPDATE_PUBLISHED_METADATA'
}

export enum DictionaryValues {
  LIST_VALUES = 'LIST_DICTIONARY_VALUES',
  LOAD_VALUE_LIST = 'VALUE_LIST',
  LOAD_VALUE = 'LOAD_DICTIONARY_VALUE',
  TENANT = 'TENANT_LIST_VALUES',
  SAVE_VALUE = 'SAVE_VALUE_VALUES',
  CHOOSE_ROW = 'CHOOSE_ROW_VALUES',
  LOAD_DICTIONARY = 'LOAD_DICTIONARY_VALUES',
  PUBLISH_DICTIONARY = 'PUBLISH_DICTIONARY_VALUES',
  DOWNLOAD_DICTIONARY = 'DOWNLOAD_DICTIONARY_VALUES',
  DOWNLOAD_DICTIONARY_BY_REQUEST_ID = 'DOWNLOAD_DICTIONARY_BY_REQUEST_ID',
  LIST_ASSOCIATED_DICTIONARIES = 'LIST_ASSOCIATED_DICTIONARIES_VALUES',
  SET_STATUS_FILTER = 'SET_STATUS_FILTER_VALUES',
  DELETE_DICTIONARY = 'DELETE_DICTIONARY',
  TOGGLE_DELETE_MODAL = 'TOGGLE_DELETE_MODAL',
  TOGGLE_INFO_MODAL = 'TOGGLE_INFO_MODAL',
  TOGGLE_SECURITY_MODAL = 'TOGGLE_SECURITY_MODAL',
  UNRESTRICT_DICTIONARY = 'UNRESTRICT_DICTIONARY',
  LIST_DICTIONARY_HISTORY = 'LIST_DICTIONARY_HISTORY',
  UPDATE_DICTIONARY_DESCRIPTION = 'UPDATE_DICTIONARY_DESCRIPTION',
  UPDATE_COLUMN_DEFS = 'UPDATE_COLUMN_DEFS'
}

export enum DictionaryUpload {
  UPLOAD_DICTIONARY = 'UPLOAD_DICTIONARY',
  UPLOAD_INIT = 'UPLOAD_INIT',
  LIST_DICTIONARIES = 'LIST_DICTIONARIES',
  LIST_COLUMNS = 'LIST_COLUMNS',
  CHOOSE_COL_REFERENCE = 'CHOOSE_COL_REFERENCE',
  ADD_COLUMN_METADATA = 'ADD_COLUMN_METADATA',
  REMOVE_COLUMN_METADATA = 'REMOVE_COLUMN_METADATA',
  TOGGLE_UPLOAD_TYPE = 'TOGGLE_UPLOAD_TYPE'
}

export enum AsyncDictionaryRequest {
  ASYNC_REQUEST_INIT = 'ASYNC_REQUEST_INIT',
  ASYNC_REQUEST_PROGRESS = 'ASYNC_REQUEST_PROGRESS',
  ASYNC_REQUEST_COMPLETE = 'ASYNC_REQUEST_COMPLETE',
  FETCH_REQUEST_STATUS = 'FETCH_REQUEST_STATUS',
  SELECT_DICTIONARY = 'SELECT_DICTIONARY'
}

export enum ColumnMetadataEditor {
  SELECT_KEY_COLUMN = 'SELECT_KEY_COLUMN',
  CONFIRM_MAPPINGS_EXISTS = 'CONFIM_MAPPINGS_EXISTS',
  SET_DICTIONARY_MAPPINGS = 'SET_DICTIONARY_MAPPINGS',
  SAVE_COLUMN_METADATA = 'SAVE_COLUMN_METADATA',
  INITIALIZE = 'INITIALIZE_COL_METADATA_EDITOR',
  SELECT_ACTIVE_COLUMN = 'SELECT_ACTIVE_COLUMN',
  SAVE_COLUMN_MAPPING = 'SAVE_COLUMN_MAPPING',
  REMOVE_COLUMN_MAPPING = 'REMOVE_COLUMN_MAPPING'
}

export enum ArchiveModal {
  TOGGLE_ARCHIVE_MODAL = 'TOGGLE_ARCHIVE_MODAL',
  SELECT_ARCHIVE_ROW = 'SELECT_ARCHIVE_ROW',
  ARCHIVE_CHILDREN = 'ARCHIVE_CHILDREN',
  SAVE_POTENTIAL_ARCHIVES = 'SAVE_POTENTIAL_ARCHIVES',
  ARCHIVE_ROW = 'ARCHIVE_ROW'
}
export enum ServiceLine {
  CHOOSE_SERVICE_LINE = 'CHOOSE_SERVICE_LINE',
  SAVE_SERVICE_LINE = 'SAVE_SERVICE_LINE',
  NAVIGATE_SERVICE_LINE = 'NAVIGATE_SERVICE_LINE'
}

export enum UserPreferences {
  SAVE_PREFERENCE = 'SAVE_PREFERENCE',
  DELETE_PREFERENCE_VALUE = 'DELETE_PREFERENCE_VALUE',
  LOAD_USER_PREFERENCES = 'LOAD_USER_PREFERENCES',
  UPDATE_PREFERENCE_VALUE = 'UPDATE_PREFERENCE_VALUE'
}

export enum AccountSettings {
  TOGGLE_IS_OPEN = 'TOGGLE_IS_OPEN',
  SAVE_FORM_DATA = 'SAVE_FORM_DATA',
  SAVE_DEFAULTS = 'SAVE_DEFAULTS',
  UPDATE_FORM_DEFINITION = 'UPDATE_FORM_DEFINITION'
}

export enum NormalTime {
  SAVE_NORMAL_TIME = 'SAVE_NORMAL_TIME',
  LIST_NORMAL_TIME = 'LIST_NORMAL_TIME'
}

export enum MyStatus {
  TOGGLE_IS_OPEN_MY_STATUS = 'TOGGLE_IS_OPEN_MY_STATUS',
  UPDATE_STATUS = 'UPDATE_STATUS',
  LIST_STATUS = 'LIST_STATUS'
}

export enum CapturedTime {
  TOGGLE_IS_OPEN_CAPTURED_TIME = 'TOGGLE_IS_OPEN_CAPTURED_TIME',
  LIST_CAPTURED_TIME_FOR_WEEK = 'LIST_CAPTURED_TIME_FOR_WEEK',
  LIST_PROTECTED_TIME_FOR_WEEK = 'LIST_PROTECTED_TIME_FOR_WEEK',
  DELETE_PROTECTED_TIME_ENTRY = 'DELETE_PROTECTED_TIME_ENTRY',
  UPDATE_PROTECTED_TIME_ENTRY = 'UPDATE_PROTECTED_TIME_ENTRY',
  ADD_PROTECTED_TIME_ENTRY = 'ADD_PROTECTED_TIME_ENTRY',
  GET_MAX_ALLOWED_BACKDATE = 'GET_MAX_ALLOWED_BACKDATE'
}

export enum ExternalApplications {
  LIST_EXTERNAL_APPS = 'LIST_EXTERNAL_APPS',
  SELECT_EXTERNAL_APP = 'SELECT_EXTERNAL_APP',
  LIST_EXTERNAL_APP_USERS = 'LIST_EXTERNAL_APP_USERS',
  SELECT_EXTERNAL_APP_USER = 'SELECT_EXTERNAL_APP_USER',
  SAVE_EXTERNAL_APP_USER = 'SAVE_EXTERNAL_APP_USER',
  SELECT_ORG_EXTERNAL_APP = 'SELECT_ORG_EXTERNAL_APP',
  SET_STATE_TO_DEFAULT = 'SET_STATE_TO_DEFAULT'
}

export enum CaseConfig {
  LIST_CASE_CONFIGURATIONS = 'LIST_CASE_CONFIGURATIONS',
  SAVE_CASE_CONFIGURATION = 'SAVE_CASE_CONFIGURATION',
  SELECT_CASE_CONFIG_SECTION = 'SELECT_CASE_CONFIG_SECTION',
  REDUX_SAVE_CASE_CONFIGS = 'REDUX_SAVE_CASE_CONFIGS',
  LOAD_CASE_CONFIG_OPTIONS = 'LOAD_CASE_CONFIG_OPTIONS'
}

export enum Loading {
  LOADING_ACTION = 'LOADING_ACTION'
}

export enum RulesList {
  LIST_RULES = 'LIST_RULES',
  GET_XML = 'GET_XML',
  CHOOSE_RULES_DIAGRAM = 'CHOOSE_RULES_DIAGRAM'
}

export enum CaseEdits {
  SET_ACTIVE_FIELD = 'SET_ACTIVE_FIELD',
  UPDATE_VALUE = 'UPDATE_VALUE',
  LOAD_PATIENT_DETAIL_OPTIONS = 'LOAD_PATIENT_DETAIL_OPTIONS',
  LOAD_ALL_PATIENT_DETAIL_OPTIONS = 'LOAD_ALL_PATIENT_DETAIL_OPTIONS',
  UPDATE_CASE = 'UPDATE_CASE',
  NAVIGATE_CODER_COMMENTS = 'NAVIGATE_CODER_COMMENTS'
}
