import { Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  toggleInfoModal,
  updateDictionaryDescription
} from '../../../../actions/dictionary-values';
import { Button } from '../../../../components/button/button';
import Input from '../../../../components/input/input';
import { ModalComponent } from '../../../../components/Modal/modal';
import { ButtonVariant } from '../../../../core/enums';
import { styles } from './info-modal.styles';

export type infoModalProps = {
  selectedDictionary: CMxAPI.Dictionary;
  viewOnly: boolean;
};

export const InfoModal: React.FC<infoModalProps> = props => {
  const { selectedDictionary, viewOnly } = props;
  const dispatch = useDispatch();
  const [isEdit, updateIsEdit] = useState<boolean>(false);
  const [description, updateDescription] = useState<string>(
    selectedDictionary?.description ?? ''
  );
  const [purpose, updatePurpose] = useState<string>(
    selectedDictionary?.purpose ?? ''
  );
  const _handleClose = () => {
    dispatch(toggleInfoModal(false));
  };

  const classes = styles();
  const handleSaveInfo = () => {
    const payload: CMxAPI.Dictionary = {
      ...props.selectedDictionary,
      description:
        description.length === 0
          ? props.selectedDictionary.description
          : description,
      purpose: purpose.length === 0 ? props.selectedDictionary.purpose : purpose
    };
    if (description.length === 0) {
      updateDescription(selectedDictionary.description);
    }
    if (purpose.length === 0) {
      updatePurpose(selectedDictionary.purpose);
    }
    dispatch(updateDictionaryDescription(payload));
    updateIsEdit(false);
  };
  return isEdit ? (
    <ModalComponent
      size={'xl'}
      showCloseIcon={true}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<div data-testid="modal-header">Dictionary Info</div>}
      modalContent={
        <Flex flexDirection={'column'} rowGap={5} sx={classes.editMode}>
          <Input
            label="Description:"
            isDisabled={viewOnly}
            onChange={e => {
              updateDescription(e.target.value);
            }}
            name={'Description'}
            placeholder={'Description'}
            value={description ?? ''}
          />
          <Input
            label="Purpose:"
            isDisabled={viewOnly}
            onChange={e => updatePurpose(e.target.value)}
            name={'Purpose'}
            placeholder={'Purpose'}
            value={purpose ?? ''}
          />
        </Flex>
      }
      modalFooter={
        <Flex flexDirection={'row'} columnGap={3} sx={classes.footer}>
          <Flex>
            <Button
              label={'Cancel'}
              onClick={_handleClose}
              variant={ButtonVariant.SECONDARY}
            />
          </Flex>
          <Flex>
            <Button
              label={'Save'}
              disabled={viewOnly}
              onClick={handleSaveInfo}
            />
          </Flex>
        </Flex>
      }
    />
  ) : (
    <ModalComponent
      size={'xl'}
      showCloseIcon={true}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<div>Dictionary Info</div>}
      modalContent={
        <div style={classes.viewMode}>
          <h5>Description:</h5>
          <p>{description}</p>
          <div style={classes.contentSpacing}>
            <h5>Purpose:</h5>
            <p>{purpose}</p>
          </div>
        </div>
      }
      modalFooter={
        <Flex flexDirection={'row'} columnGap={3} sx={classes.footer}>
          <Flex>
            <Button
              label={'Cancel'}
              onClick={_handleClose}
              variant={ButtonVariant.SECONDARY}
            />
          </Flex>
          <Flex>
            <Button
              label={'Edit'}
              disabled={viewOnly}
              onClick={() => updateIsEdit(true)}
            />
          </Flex>
        </Flex>
      }
    />
  );
};
