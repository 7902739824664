/// <reference path="../../types.d.ts" />
import { connect } from 'react-redux';

import CMx from './CMx';
import { CMxComponents } from '@codametrix/ui-components/src/types';
import { push } from 'connected-react-router';

import { jumpContext } from '../../actions/contexts';
import {
  navigate,
  dismissFeedback,
  fetchContexts,
  changeAdminTab
} from '../../actions/ui';
import { buildDispatchable } from '../../actions/_action-utilities';
import { logoutApp } from '../../actions/login.action';
import { authenticationChannel, commonActions } from '@codametrix/ui-common';
import { navigateServiceLine } from '../../actions/service-lines';
import { toggleIsOpen } from '../../actions/account-settings';
import { toggleIsOpenMyStatus } from '../../actions/my-status';
import {
  toggleIsOpenCapturedTime,
  listCapturedTimeForWeek,
  listProtectedTimeForWeek,
  updateProtectedTimeEntry,
  addProtectedTimeEntry,
  deleteProtectedTimeEntry,
  getMaxAllowedBackdate
} from '../../actions/captured-time';
import { listStatus, updateStatus } from '../../actions/my-status';
import { showProductivityManagerReport } from '../../actions/analytics';

const mapStateToProps = function(state: CMx.ApplicationState) {
  const { ui, analytics } = state;
  return {
    ...state.cmx,
    ...ui,
    ...state.myStatus,
    caseLoadState: state.cases.loadState,
    activeUser: state.ui.activeUser,
    eula: state.eula,
    accountSettingsIsOpen: state.accountSettings.isOpen,
    myStatusIsOpen: state.myStatus.myStatusIsOpen,
    capturedTimeIsOpen: state.capturedTime.capturedTimeIsOpen,
    showSideNavBar: state.codeBench.showSideNavBar,
    showDashboardOptions: analytics.showDashboardOptions
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onLoad: (navData: CMx.NavData) => {
      runDispatchable(async () => {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        if (params.has('channel')) {
          await authenticationChannel.ready;
        }
        return dispatch(fetchContexts(navData));
      });
    },
    handleOrganizationChoice: (choice: CMx.OrganizationFormChoice) => {
      runDispatchable(() => {
        const promise = dispatch(
          jumpContext({
            organizationId: `${choice.organizationId}`,
            servicelineId: choice.servicelineId
          })
        );
        // jump to a meaningless url, this will make sure that componentWillMount will
        // run when again.

        dispatch(push(`/cmx/jump`));

        return promise;
      });
    },

    handleServiceLineChoice: (
      choice: CMx.ServiceLine,
      context: CMx.Context,
      actionGroups: CMxAPI.NavActionGroup[]
    ) => {
      runDispatchable(() => {
        return dispatch(navigateServiceLine({ choice, context, actionGroups }));
      });
    },
    onSelection: (selection: CMxAPI.NavAction) => {
      let subNav;
      if (selection.subActions) {
        subNav = selection.subActions.find(subAction => {
          return subAction.active === true;
        });
      }
      dispatch(navigate(subNav ? subNav : selection));
      dispatch(push(subNav ? `/cmx/${subNav.path}` : `/cmx/${selection.path}`));
    },
    dismissFeedback: (message: commonActions.CMxApplication.FEEDBACK) =>
      dispatch(dismissFeedback(true)),
    logout: (orgCode: string) => {
      dispatch(logoutApp({ showModal: true, orgCode }));
    },
    navigateEula: () => {
      dispatch(push('/eula/'));
    },
    selectAdminTab: (
      navPath: string,
      activeSubAction: CMxComponents.TabDef,
      adminTabs: CMxComponents.TabDef[]
    ) => {
      runDispatchable(() => {
        dispatch(push('/cmx/' + navPath));
        dispatch(navigate(activeSubAction));
        return dispatch(changeAdminTab(adminTabs));
      });
    },
    openAccountSettings: () => {
      runDispatchable(() => {
        dispatch(push(`#AccountSettings`));
        return dispatch(toggleIsOpen(true));
      });
    },
    openMyStatus: () => {
      runDispatchable(() => {
        dispatch(push(`#MyStatus`));
        return dispatch(toggleIsOpenMyStatus(true));
      });
    },
    openCapturedTime: () => {
      runDispatchable(() => {
        dispatch(push(`#CapturedTime`));
        return dispatch(toggleIsOpenCapturedTime(true));
      });
    },
    listStatus: (activeTenantId: string) => {
      runDispatchable(() => {
        return dispatch(listStatus({ activeTenantId }));
      });
    },
    updateStatus: (status: string, user: CMxAPI.User, context: CMx.Context) => {
      runDispatchable(() => {
        return dispatch(updateStatus({ status, user, context }));
      });
    },
    listCapturedTime: (
      startDate: Date,
      endDate: Date,
      user: CMxAPI.User,
      context: CMx.Context
    ) => {
      runDispatchable(() => {
        return dispatch(
          listCapturedTimeForWeek({ startDate, endDate, user, context })
        );
      });
    },
    listProtectedTime: (
      startDate: Date,
      endDate: Date,
      user: CMxAPI.User,
      context: CMx.Context
    ) => {
      runDispatchable(() => {
        return dispatch(
          listProtectedTimeForWeek({ startDate, endDate, user, context })
        );
      });
    },
    deleteProtectedTimeEntry: (workLogId: string) => {
      runDispatchable(() => {
        return dispatch(deleteProtectedTimeEntry({ workLogId }));
      });
    },
    updateProtectedTimeEntry: (
      workLogId: string,
      workLog: CMxAPI.ProtectedTime
    ) => {
      runDispatchable(() => {
        return dispatch(updateProtectedTimeEntry({ workLogId, workLog }));
      });
    },
    addProtectedTimeEntry: (
      workLogProtectedRequestDto: CMxAPI.ProtectedTime
    ) => {
      runDispatchable(() => {
        return dispatch(addProtectedTimeEntry({ workLogProtectedRequestDto }));
      });
    },
    getMaxAllowedBackdate: (key: string, tenantId: string) => {
      runDispatchable(() => {
        return dispatch(getMaxAllowedBackdate({ key, tenantId }));
      });
    },
    showProductivityManagerReport: (key: string, tenantId: string) => {
      runDispatchable(() => {
        return dispatch(showProductivityManagerReport({ key, tenantId }));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CMx);
