function rejectWithMessage(error: Error): Promise<string> {
  return Promise.reject(error.message);
}

type Replacer = (key: string, val: any) => void;
type Reviver = (obj: any) => any;

const engine = (key: string, replacer?: Replacer, reviver?: Reviver) => ({
  load() {
    return new Promise(resolve => {
      const jsonState = sessionStorage.getItem(key);
      resolve(JSON.parse(jsonState as string, reviver) || {});
    }).catch(rejectWithMessage);
  },

  save(state: CMx.ApplicationState) {
    return new Promise(resolve => {
      const jsonState = JSON.stringify(state, replacer);
      sessionStorage.setItem(key, jsonState);
      resolve();
    }).catch(rejectWithMessage);
  },
  clear() {
    sessionStorage.removeItem(key);
  }
});

export default engine;

export function replacer(this: any, key: string, value: any) {
  const originalObject = this[key];
  if (originalObject instanceof Map) {
    return {
      dataType: 'Map',
      value: Array.from(originalObject.entries())
    };
  } else {
    return value;
  }
}

export function reviver(key: string, value: any) {
  if (key === '') {
    return value;
  }
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === 'Map') {
      return new Map(value.value);
    }
  }
  return value;
}
