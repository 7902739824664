/// <reference path="../../types.d.ts"" />

import { Card } from '@codametrix/ui-components';
import classnames, { Argument } from 'classnames';
import React, { useState, useEffect } from 'react';
import './dashboard.scss';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'tableau-viz': {
        id: string;
        src: string;
        token: string | undefined;
        toolbar: string;
        children: any;
      };

      'viz-filter': {
        field: string;
        value: string;
      };
    }
  }
}

const listeners = [
  'firstinteractive',
  'filterchanged',
  'parameterchanged',
  'markselectionchanged'
];

const TableauDashboard = (props: AppProps.DashboardView) => {
  const {
    tableauInfo,
    instantiateDashboard,
    serviceLine,
    tableauToken,
    activeHealthSystemCode,
    activeDashboard,
    registerActivity
  } = props;

  const dashboards: { [key: string]: string } = Object.freeze({
    case: tableauInfo?.automationDashboardPath ?? '',
    operational: tableauInfo?.productivityManagerReportPath ?? ''
  });

  const [instantiated, setInstantiated] = useState<boolean>(false);

  useEffect(() => {
    if (tableauInfo?.enabled) {
      if (!instantiated) {
        setInstantiated(true);
        instantiateDashboard(serviceLine);
      }

      // Get the viz object from the HTML web component
      const viz = document.querySelector('tableau-viz');

      listeners.forEach(listener => {
        viz?.addEventListener(listener, registerActivity);
      });
    }
  }, [
    instantiated,
    instantiateDashboard,
    registerActivity,
    tableauInfo?.enabled,
    serviceLine,
    tableauToken
  ]);

  useEffect(() => {
    return () => {
      setInstantiated(false);

      const viz = document.querySelector('tableau-viz');
      listeners.forEach(listener => {
        viz?.removeEventListener(listener, registerActivity);
      });
    };
  }, [registerActivity]);

  const { loadState } = props;
  const styleClasses: Argument = ['dashboard-view'];
  const showCard = loadState.error || tableauInfo?.enabled === false;
  let message;
  if (showCard) {
    message = loadState.error
      ? `An error has occurred loading the dashboard.`
      : `Analytics is not enabled.  Please contact someone from CodaMetrix for more information.`;
  }

  return (
    <div className={classnames(styleClasses)}>
      {tableauInfo?.enabled && instantiated ? (
        <tableau-viz
          id="tableauViz"
          src={`${tableauInfo?.siteUrl}${dashboards[activeDashboard]}`}
          token={tableauToken}
          toolbar="hidden">
          <viz-filter
            field="Service Line"
            value={`${serviceLine?.name?.replace(/ /g, '')}`}></viz-filter>
          <viz-filter
            field="Health System Code"
            value={activeHealthSystemCode}></viz-filter>
        </tableau-viz>
      ) : (
        <Card className="content-vacant">
          {' '}
          <h2>{message}</h2>
        </Card>
      )}
    </div>
  );
};

export default TableauDashboard;
