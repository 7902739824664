import { buildDispatchable } from '../../../actions/_action-utilities';
import {
  list,
  getDictionary,
  getCaseFilter,
  getTenantFilter,
  navigateDetail,
  clearAllFilters,
  getShowOrderNumber
} from '../../../actions/cases.action';
import {
  onListNavigateGenerator,
  onDetailNavigate
} from '../../utils/view-utils';
import { commonEnums } from '@codametrix/ui-common';
import {
  EndpointData,
  HTTPMethodType
} from '../../../components/data-grid/data-grid-utils';

const { FilterKeys } = commonEnums;
type CaseListDispatches = Pick<
  AppProps.CaseListProps,
  | 'list'
  | 'getDictionary'
  | 'onSelection'
  | 'getStatuses'
  | 'getTenants'
  | 'onNavigate'
  | 'clearFilters'
  | 'showOrderNumber'
>;

type CaseStateProps = Pick<
  AppProps.CaseListProps,
  'items' | 'activeOrgId' | 'serviceLine'
>;

export const mapStateToProps = function(
  state: CMx.ApplicationState
): CaseStateProps {
  const { cases, ui } = state as CMx.ApplicationState;

  var filterForms = cases.criteriaFilter.filterForms;

  if (ui.context.activeContext && ui.context.activeContext.parentLevel > 1) {
    filterForms = cases.criteriaFilter.filterForms.filter(form => {
      return form.key !== FilterKeys.TENANT;
    });
  }

  const casesFiltered = {
    ...cases,
    criteriaFilter: {
      ...cases.criteriaFilter,
      filterForms: filterForms
    }
  };

  return {
    ...casesFiltered,
    activeOrgId: ui.context.activeContext?.organizationId || -1,
    serviceLine: ui.serviceLine
  };
};

export const mapDispatchToProps = (dispatch: any): CaseListDispatches => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    onNavigate: onListNavigateGenerator(dispatch),
    onSelection: (caseInstance: CMxAPI.CaseRun) => {
      runDispatchable(() => {
        onDetailNavigate(dispatch);
        return dispatch(navigateDetail(caseInstance));
      });
    },
    getDictionary: (params: AppProps.CategoryParams) => {
      runDispatchable(() => {
        return dispatch(getDictionary(params));
      });
    },
    getStatuses: (params: AppProps.CategoryParams) => {
      runDispatchable(() => {
        return dispatch(getCaseFilter(params));
      });
    },
    getTenants: (params: number) => {
      runDispatchable(() => {
        return dispatch(getTenantFilter(params));
      });
    },
    list: (options: CMx.CaseListPayload) => {
      runDispatchable(() => {
        return dispatch(list(options));
      });
    },
    clearFilters: () => {
      runDispatchable(() => {
        return dispatch(clearAllFilters());
      });
    },
    showOrderNumber: (key: string, tenantId: string) => {
      runDispatchable(() => {
        return dispatch(getShowOrderNumber({ key, tenantId }));
      });
    }
  };
};

export const caseListEndPoint = (
  serviceLineConfigPath: string,
  caseListVersion: string,
  apiProxyServiceActive: boolean
) => {
  const caseListEnpPointPath = apiProxyServiceActive
    ? `/api/cases/${serviceLineConfigPath.toUpperCase()}/caseinstance/list/v2`
    : `${serviceLineConfigPath}/caseinstance/list/${caseListVersion}`;

  const caseListEndPoint: EndpointData = {
    endPoint: caseListEnpPointPath,
    HTTPMethodType: HTTPMethodType.POST
  };
  return caseListEndPoint;
};
