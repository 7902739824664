import { commonActions, logger } from '@codametrix/ui-common';
import { Action } from 'typescript-fsa';
import { IS_PRODUCTION } from '../environment';
import { setIsActive } from '../net';
import store from '../../store/store';

const activityEvents = new Set<string>([
  `${commonActions.AuthenticatedWindow.ACTIVITY}`
]);

const isBroadcast = (item: Action<any>) => {
  return item.meta?.broadcast ?? false;
};

const senderSubscriptions: CMx.ChannelSubscription[] = [
  {
    match: isBroadcast
  },
  {
    observe: (item: Action<any>) => {
      isBroadcast(item) && console.log(`Broadcasting: ${item.type}`, item);
    }
  }
];

const ChildWindowActivityObserver = {
  observe: (item: Action<any>) => {
    const suppressRedux = item.meta?.suppressRedux ?? false;
    if (suppressRedux) {
      if (activityEvents.has(item.type)) {
        logger.log(`ChildWindowActivityObserver`, item);
        setIsActive(true);
      }
    } else {
      //
      if (item.meta) {
        item.meta.broadcast = false;
      }
      store.dispatch(item);
    }
    return false;
  }
};

const receiverSubscriptions: CMx.ChannelSubscription[] = [
  ChildWindowActivityObserver
];

if (!IS_PRODUCTION) {
  receiverSubscriptions.push({
    observe: (msg: Action<any>) => {
      console.log(`message received`, msg);
    }
  });
}

export { senderSubscriptions, receiverSubscriptions };
