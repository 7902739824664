import { api } from '../../core/net';
import { HttpMethods } from '@codametrix/ui-common';
import { IServerSideGetRowsParams } from 'ag-grid-community';
import { loadingAction } from '../../actions/ui';

export type EndpointData = {
  endPoint: string;
  HTTPMethodType: string;
  params?: string[][];
};

export const HTTPMethodType = {
  POST: 'POST',
  GET: 'GET'
};

const searchParamsFactory = (
  params: IServerSideGetRowsParams,
  paginationPageSize: number,
  endPointParams: string[][]
): URLSearchParams => {
  let sorts: string[][] = [];
  let pageables: string[][] = [];

  if (Array.isArray(params.request.sortModel)) {
    sorts = params.request.sortModel.map(opt => [
      `sort`,
      `${opt.colId},${opt.sort}`
    ]);
  }

  pageables = [
    ['page', `${(params.request.startRow ?? 0) / paginationPageSize}`],
    ['size', `${paginationPageSize}`]
  ];

  const searchParams = new URLSearchParams([
    ...pageables,
    ...sorts,
    ...endPointParams
  ]);
  return searchParams;
};

export const getDataSource = <T = any>(
  endpoint: EndpointData,
  filterableOptions: CMxCommonApp.FilterableOption | undefined,
  paginationPageSize: number,
  dispatch: any,
  contentFilter?: Function,
  loaderInfo?: string,
  bodyObject?: object
) => {
  return {
    async getRows(params: IServerSideGetRowsParams) {
      // Make a request to the server for the required page of data
      const queryParams = searchParamsFactory(
        params,
        paginationPageSize,
        endpoint.params ?? []
      );
      let body: any[] = [];
      if (filterableOptions?.filters) {
        body = filterableOptions.filters;
      }

      if (filterableOptions?.dateFilter) {
        body = body.filter(filter => {
          return (
            filter.type !== 'GREATER_THAN_EQUAL' ||
            filter.type !== 'LESS_THAN_EQUAL'
          );
        });
        body.push({
          key: filterableOptions.dateFilter.key,
          terms: [filterableOptions.dateFilter.from],
          type: 'GREATER_THAN_EQUAL'
        });
        body.push({
          key: filterableOptions.dateFilter.key,
          terms: [filterableOptions.dateFilter.to],
          type: 'LESS_THAN_EQUAL'
        });
      }
      try {
        dispatch(loadingAction({ isLoading: true, loadingMsg: loaderInfo }));
        const data = await api<CMxAPI.PageableList<T>>(
          {
            endpoint: `${endpoint.endPoint}?${queryParams.toString()}`,
            init: {
              method:
                endpoint.HTTPMethodType === HTTPMethodType.POST
                  ? HttpMethods.POST
                  : HttpMethods.GET
            },
            body:
              endpoint.HTTPMethodType === 'POST'
                ? bodyObject ?? body
                : undefined
          },
          true,
          true
        );
        const content = contentFilter ? contentFilter(data) : data?.content;
        params.success({
          rowData: content,
          rowCount: data?.totalElements
        });
      } catch (error) {
        params.fail();
      } finally {
        dispatch(loadingAction({ isLoading: false }));
      }
    }
  };
};
