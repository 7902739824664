import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import {
  authenticationChannel,
  authenticationContext,
  commonActions,
  sessionManagement,
  uiActions
} from '@codametrix/ui-common';
import { Auth, UserContext } from '../../actions/action-types';
import { jumpContext } from '../../actions/contexts';

const { UserInterface } = commonActions;
const { activityMonitor } = sessionManagement;

export let isSSOUser = false;

export const AuthTokenMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  // Do stuff
  if (action.type === Auth.TOKEN_AVAILABLE) {
    // store the authorization
    const { payload } = action;
    authenticationContext.setToken(payload.token);
    if (payload.expiryTimeToLive) {
      activityMonitor.expiryTimeout = payload.expiryTimeToLive;
    }
  } else if (action.type === UserContext.CHOOSE_CONTEXT) {
    const { payload } = action;
    // capture expiry ttls in both TOKEN_AVAILABLE and CHOOSE_CONTEXT
    // it is not available in ALL cases due to the user set-up.
    // some users with a single context don't have the ttl.
    if (payload.expiryTimeToLive) {
      activityMonitor.expiryTimeout = payload.expiryTimeToLive;
    }
    authenticationContext.setContextId(payload.activeContextId);
  } else if (action.type === Auth.TOGGLE_SSO_USER) {
    const { payload } = action;
    isSSOUser = payload;
  } else if (action.type === UserInterface.LOGOUT) {
    activityMonitor.stop();
    next(uiActions.resetCommonState());
  } else if (action.type === jumpContext.async.done.type) {
    // as soon as the context switch is done
    // we need to switch to a new channel.
    authenticationChannel.close();
    sessionStorage.removeItem('channel');
    window.location.reload();
  }

  return next(action);
};

export default AuthTokenMiddleware;
