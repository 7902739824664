import React from 'react';
import { styles } from '../org-serviceline/org-servicelines.styles';

import {
  PopoverBody,
  Stack,
  Box,
  Flex,
  Text,
  PopoverContent,
  Portal
} from '@chakra-ui/react';

const dashboards = ['Case Analytics', 'Operational Analytics'];

type DashBoardSelectProps = {
  dashboardSelectionOpened: boolean;
  activeDashboard: string;
  handleDashboardSelection: (boardName: string) => void;
};

export const DashboardSelection: React.FC<DashBoardSelectProps> = ({
  dashboardSelectionOpened,
  activeDashboard,
  handleDashboardSelection,
  ...props
}) => {
  const classes = styles();

  return (
    <Portal>
      <Flex className="dashboardSelection">
        <PopoverContent sx={classes['.mainGrid']} className="popOverStyle">
          <PopoverBody sx={classes['.bodyGrid']}>
            <Stack direction="row">
              <Box sx={classes['.popoverInsightsBox']}>
                {dashboardSelectionOpened &&
                  dashboards.map((boardName, index) => {
                    return (
                      <Flex
                        sx={classes['.switchItem']}
                        key={boardName}
                        onClick={() => {
                          handleDashboardSelection(boardName);
                        }}
                        className={`hoverCursor ${
                          activeDashboard ===
                          boardName.split(' ')[0].toLowerCase()
                            ? 'selectedItem'
                            : ''
                        }`}>
                        <Text>{boardName}</Text>
                      </Flex>
                    );
                  })}
              </Box>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Flex>
    </Portal>
  );
};
