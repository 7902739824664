import { Dispatch } from 'redux';
import { replace } from 'connected-react-router';
import { cmxURL } from '@codametrix/ui-common';

export const isBack = () => {
  const loc = document.location?.toString() ?? '';
  return new URL(loc).searchParams.has('back');
};

export const onDetailNavigate = (dispatch: Dispatch) => {
  const loc = document.location?.toString() ?? '';
  const url = new URL(loc);
  if (!url.searchParams.has('back')) {
    url.searchParams.append('back', 'true');
  }
  const newLoc = cmxURL.pathBuilder(url);
  dispatch(replace(newLoc));
};

export const onListNavigateGenerator = (dispatch: Dispatch) => {
  return () => {
    const loc = document.location?.toString() ?? '';
    const url = new URL(loc);
    if (url.searchParams.has('back')) {
      url.searchParams.delete('back');
      const newLoc = cmxURL.pathBuilder(url);
      dispatch(replace(newLoc));
    }
  };
};
