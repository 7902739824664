import React from 'react';
import { useSelector } from 'react-redux';
import { dictionaryListcolDefs } from './dictionary-history-cols';
import { DataGrid } from '../../../components/data-grid/data-grid';
import {
  EndpointData,
  HTTPMethodType
} from '../../../components/data-grid/data-grid-utils';
import { commonEnums } from '@codametrix/ui-common';
import { Button, Flex } from '@chakra-ui/react';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { ColDef } from 'ag-grid-community';
import { ReactComponent as DownloadIcon } from '../../../assets/images/download2.svg';

const { FilterTypes } = commonEnums;

type DictionaryHistoryProps = {
  downloadDictionaryByRequestID: (requestId: string) => void;
};

export const DictionaryHistory: React.FC<DictionaryHistoryProps> = props => {
  const { downloadDictionaryByRequestID } = props;
  // get idDictionary for current dictionary on state
  const idDictionary = useSelector((state: CMx.ApplicationState) => {
    return state.dictionary.selectedDictionaryId;
  });

  const filterableOptions: CMxCommonApp.FilterableOption = {
    filters: [
      {
        key: 'dictionary_id',
        terms: [idDictionary?.toString() ?? ''],
        type: FilterTypes.EQ
      }
    ]
  };

  const endpoint = () => {
    const endPoint: EndpointData = {
      endPoint: `/dictionary/request/v2?`,
      HTTPMethodType: HTTPMethodType.POST
    };
    return endPoint;
  };

  const handleDownloadDictionary = (requestId: string) => {
    downloadDictionaryByRequestID(requestId);
  };

  const updateColDefs = (columnDefs: ColDef[]) => {
    const cols = columnDefs.map((col: ColDef) => {
      let column: ColDef = { ...col };
      if (col.headerName === 'Download') {
        column = {
          ...column,
          cellRenderer: (params: any) => {
            if (
              params.data.dictionaryCsvS3Uri &&
              params.data.dictionaryCsvS3Uri.length > 0
            ) {
              return (
                <Button
                  variant="link"
                  onClick={() => handleDownloadDictionary(params.data.id)}>
                  <DownloadIcon />
                </Button>
              );
            } else {
              return '';
            }
          }
        };
      }
      return column;
    });

    return cols;
  };

  return (
    <div>
      <Flex marginBottom={5}>
        <BreadCrumbsComponent />
      </Flex>
      <DataGrid
        columnDefs={updateColDefs(dictionaryListcolDefs)}
        endpointData={endpoint()}
        serverSideInfiniteScroll
        filterableOptions={filterableOptions}
        paginationPageSize={200}
      />
    </div>
  );
};

export default DictionaryHistory;
