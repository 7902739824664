import { Flex } from '@chakra-ui/react';
import { goBack } from 'connected-react-router';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BreadCrumbsComponent } from '../../components/breadcrumbs/breadcrumbsComponent';
import { Form } from './form';
import { saveDictionaryRow } from '../../actions/dictionary-values';

const DictionaryValueDetail = () => {
  const item = useSelector((appState: CMx.ApplicationState) => {
    const index = appState.dictionaryValues.selectedRowIndex;
    return (
      appState.dictionaryValues.items.find(item => item.rowIndex === index) ||
      {}
    );
  });

  const rowEditForm = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.rowEditForm;
  });

  const selectedDictionary = useSelector((appState: CMx.ApplicationState) => {
    return appState.dictionaryValues.selectedDictionary;
  });

  const [rowItem, setRowItem] = useState(item);

  const dispatch = useDispatch();

  const handleAction = () => {
    dispatch(goBack());
  };

  const handleSave = (row: any) => {
    const cells: CMxAPI.CellDto[] = [];
    let payload: CMxAPI.DictionaryRowDto = {
      id: row.id,
      tenantId: selectedDictionary.tenantId.uuid,
      rowIndex: row.rowIndex,
      status: selectedDictionary.status,
      editStatus: selectedDictionary.editStatus,
      effectiveDate: selectedDictionary.effectiveDate,
      endDate: selectedDictionary.endDate,
      effectiveDateLocal: null,
      endDateLocal: null,
      dictionaryId: selectedDictionary.id,
      dictionaryName: selectedDictionary.name,
      dictionary: selectedDictionary,
      cells: cells
    };
    selectedDictionary.columnDefinitions.forEach(colDef =>
      cells.push({
        id: row[colDef.name + 'Id'],
        tenantId: selectedDictionary.tenantId.uuid,
        value: row[colDef.name],
        codeType: '',
        status: selectedDictionary.status,
        editStatus: selectedDictionary.editStatus,
        columnDefinition: colDef,
        columnDefinitionId: colDef.id,
        rowId: row.id,
        rowIndex: row.rowIndex,
        columnName: colDef.name,
        effectiveDate: selectedDictionary.effectiveDate,
        endDate: selectedDictionary.endDate,
        effectiveDateLocal: null,
        endDateLocal: null,
        row: null
      })
    );
    dispatch(saveDictionaryRow(payload));
    dispatch(goBack());
  };

  const handleFormChange = (prop: string, value: string) => {
    let rowItemToUpdate = { ...rowItem };
    rowItemToUpdate[prop] = value;
    setRowItem(rowItemToUpdate);
  };

  const enabler = () => {
    return true;
  };

  return (
    <>
      <Flex marginBottom={5}>
        <BreadCrumbsComponent />
      </Flex>
      <Form
        formDefinition={rowEditForm}
        dataItem={rowItem}
        inline={false}
        enable={enabler}
        onAction={handleAction}
        onPropChange={handleFormChange}
        onSave={() => handleSave(rowItem)}
        title="Edit Dictionary"
        errors={{
          status: '',
          message: null,
          errors: [],
          fieldErrors: {}
        }}
      />
    </>
  );
};

DictionaryValueDetail.displayName = 'DictionaryValueDetail';
export { DictionaryValueDetail };
