import {
  AmplifyMiddlewares,
  sessionManagement,
  ActivityMonitor,
  AmplifyCore,
  xcaliberActions
} from '@codametrix/ui-common';
import store from '../store/store';

const { activityMonitor } = sessionManagement;

export const getProviderDirectoryClient = () => {
  //@ts-ignore
  const xcaliberClient = XcaliberWidgets.init({
    theme: {},
    getBearerToken: () => {
      const token = AmplifyMiddlewares.fetchXcaliberToken();
      return Promise.resolve(`Bearer ${token.token}`);
    }
  });

  const refreshHandler = async () => {
    const tokenResponse: CMxAPI.XCaliberTokenResponse = await AmplifyCore.fetchXCaliberToken();
    await store.dispatch(xcaliberActions.xcaliberTokenAvailable(tokenResponse));
    if (xcaliberClient) {
      xcaliberClient.refreshToken();
    }
  };
  activityMonitor.on(ActivityMonitor.events.DID_REFRESH, refreshHandler);
  return xcaliberClient;
};
