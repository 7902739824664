import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Analytics } from '../../actions/action-types';

let fns = new Map();

const importScript = (resourceUrl: string, id: string) => {
  if (document.getElementById(id)) {
    return fns.get(id);
  } else {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = false;
    script.id = id;
    script.type = 'module';
    document.body.appendChild(script);

    const removerFn = () => {
      const scriptTag = document.getElementById(id);
      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
    fns.set(id, removerFn);
    return removerFn;
  }
};

const importTableauMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  if (action.type === Analytics.GET_TABLEAU_TOKEN + '_STARTED') {
    importScript(
      'https://prod-useast-b.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js',
      'tableau-embed-lib'
    );
  }

  return next(action);
};

export { importScript, importTableauMiddleware };
