import {
  PatientEncounter,
  Conditional,
  InfoCell
} from '@codametrix/shared-views';
import {
  PatientEncounterDashboardColumns,
  codeBenchActions,
  commonEnums,
  AmplifyCore,
  cmxDateTime
} from '@codametrix/ui-common';
import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createPortal } from 'react-dom';
import dashboardTheme from '../../theme';
import { styles } from './patient-timeline-styles';
import {
  eventTypes,
  filteredEventTypes,
  resolveBodyTemplate,
  resolveHeadTemplate,
  resolveFootTemplate,
  isInteractionFromCase,
  Message,
  MessageHeader,
  MessageBody,
  MessageFooter
} from './patient-timeline-entries';
import { MenuButton, MenuItem } from '@codametrix/shared-views';
import { ButtonToggle } from '../../components/button-toggle/button-toggle';
import { LoadingSlugs } from '@codametrix/ui-components';
import { CheckboxMenu } from '../../components/checkbox-menu/checkbox-menu';
import { CheckboxOption } from '../../components/checkbox-menu/checkbox-menu';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import './patient-timeline-styles.scss';
import { HStack, ChakraProvider, Flex, Box } from '@chakra-ui/react';
import theme from '../../theme';

type CaseEntriesProp = {
  newPage: number;
  localEntriesSumCounts?: number;
  dateStr?: string;
  messageTypes?: CheckboxOption[];
};

const { conditionalUtils } = AmplifyCore;

const classes = styles();
const fieldEditorInfo = {
  caseEdits: {
    caseEdits: {}
  },
  updateValue: (val: any) => console.log(val),
  updateCase: (val: any) => console.log(val)
};

const filteredTypes: string[] = ['ADT'];

const fallbackElement = document.createElement('div');

const isEntryIntoDateRange = (
  event_datetime: string,
  days: number
): boolean => {
  const currentDate = new Date();
  const parsedDate = new Date(cmxDateTime.clearSquareBraces(event_datetime));
  const timeDifferenceInDays = Math.floor(
    (currentDate.getTime() - parsedDate.getTime()) / (24 * 60 * 60 * 1000)
  );

  return timeDifferenceInDays <= days;
};

const PatientTimeline = () => {
  const [showAll, setShowAll] = useState(false);

  const dispatch = useDispatch();
  const {
    activeCaseRun,
    isOpen,
    entries,
    entriesLoading,
    entriesMessageCountsLoading,
    entriesMessageCounts,
    entriesSumCounts,
    entriesLastPage,
    filterTypes,
    entriesFailed,
    dateFilter,
    entryPage,
    dateFilterStr,
    isFirstLoad,
    orderTypeStatus
  } = useSelector((state: CMx.ApplicationState) => {
    return state.patientTimeline;
  });

  const { capabilities } = useSelector((state: CMx.ApplicationState) => {
    return state.capabilities;
  });

  const isTimelineInfiniteLoadOn = conditionalUtils.capabilityValue(
    capabilities?.timelineInfiniteLoad?.on
  );
  const filterOps = isTimelineInfiniteLoadOn
    ? {
        dateStr: dateFilterStr,
        messageTypes: filterTypes
      }
    : {};

  // get message counts for mrn
  useEffect(() => {
    if (isOpen) {
      if (isTimelineInfiniteLoadOn) {
        dispatch(
          codeBenchActions.getPatientMessageCountsWithFilter({
            caseRun: activeCaseRun
          })
        );
      } else {
        dispatch(
          codeBenchActions.getPatientMessageCounts({ caseRun: activeCaseRun })
        );
      }
    }
    // eslint-disable-next-line
  }, [activeCaseRun, isOpen]);

  const dispatchCaseEntries = useCallback(
    async (caseEntriesProp: CaseEntriesProp) => {
      const {
        newPage,
        localEntriesSumCounts,
        dateStr,
        messageTypes
      } = caseEntriesProp;
      const pageCount =
        !isTimelineInfiniteLoadOn && !!localEntriesSumCounts
          ? localEntriesSumCounts
          : 200;
      const filterOps = isTimelineInfiniteLoadOn
        ? {
            messageTypeFilter: messageTypes ?? filterTypes ?? [],
            dateFilter: dateStr ?? dateFilterStr ?? ''
          }
        : {};
      if (!entriesLoading) {
        try {
          try {
            if (isTimelineInfiniteLoadOn) {
              await dispatch(
                codeBenchActions.getCaseEntriesWithFilter({
                  caseRun: activeCaseRun,
                  limit: pageCount,
                  page: newPage,
                  timelineInfiniteLoad: capabilities?.timelineInfiniteLoad?.on,
                  ...filterOps
                })
              );
            } else {
              await dispatch(
                codeBenchActions.getCaseEntries({
                  caseRun: activeCaseRun,
                  limit: pageCount,
                  page: newPage,
                  timelineInfiniteLoad: capabilities?.timelineInfiniteLoad?.on
                })
              );
            }
          } catch (err) {
            throw err;
          }

          setShouldDispatch(true);

          if (newPage !== 1) {
            const cmxDrawerBody = document.getElementById('cmx-drawer-body');
            const drawerBody = cmxDrawerBody?.closest('.chakra-modal__body');
            if (drawerBody) {
              drawerBody.scrollTop =
                drawerBody?.scrollHeight - drawerBody.clientHeight - 20;
            }
          }
        } catch (err) {
          throw err;
        }
      }
    },
    // eslint-disable-next-line
    [activeCaseRun, dispatch]
  );

  // fetch for the very first time
  useEffect(() => {
    if (
      entriesSumCounts !== 0 &&
      activeCaseRun?.patient_identifier?.number &&
      isOpen &&
      isFirstLoad
    ) {
      setShowAll(false);
      dispatchCaseEntries({
        newPage: entryPage,
        localEntriesSumCounts: entriesSumCounts,
        ...filterOps
      });
    }
    // eslint-disable-next-line
  }, [isOpen, entriesSumCounts, activeCaseRun?.patient_identifier?.number]);

  const [shouldDispatch, setShouldDispatch] = useState(true);

  // request every time scroll touches the final position
  useEffect(() => {
    const cmxDrawerBody = document.getElementById('cmx-drawer-body');
    const drawerBody = cmxDrawerBody?.closest('.chakra-modal__body');

    const handleScroll = () => {
      if (drawerBody) {
        const distanceToBottom =
          drawerBody.scrollHeight -
          (drawerBody.scrollTop + drawerBody.clientHeight);

        if (drawerBody && shouldDispatch) {
          if (distanceToBottom <= 5 && !entriesLastPage) {
            setShouldDispatch(false);
            drawerBody?.removeEventListener('scroll', handleScroll);
            dispatchCaseEntries({
              newPage: entryPage + 1,
              localEntriesSumCounts: entriesSumCounts,
              ...filterOps
            });
          }
        }
      }
    };

    if (
      drawerBody &&
      isOpen &&
      conditionalUtils.capabilityValue(capabilities?.timelineInfiniteLoad?.on)
    ) {
      drawerBody.addEventListener('scroll', handleScroll);
    }

    if (entriesLastPage) {
      drawerBody?.removeEventListener('scroll', handleScroll);
    }

    return () => {
      // Clean up the event listener when the component unmounts
      if (drawerBody) {
        drawerBody.removeEventListener('scroll', handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [isOpen, shouldDispatch, entryPage, entriesLastPage, dispatchCaseEntries]);

  let filteredEntries = entries?.filter(entry =>
    Object.keys(eventTypes).includes(entry?.message?.type)
  );

  const lengthNoSupportedInter = filteredEntries?.length;
  const hasFilterTypes = filterTypes?.length > 0;

  if (!showAll && !hasFilterTypes && !isTimelineInfiniteLoadOn) {
    filteredEntries = entries?.filter(
      entry =>
        !(
          filteredTypes.includes(entry?.message?.type) &&
          filteredEventTypes[entry?.message?.type] &&
          !filteredEventTypes[entry?.message?.type].includes(
            entry?.message?.code
          )
        )
    );
  }

  const lengthNoShownInter = filteredEntries?.length;

  const setFilterTypes = (filterTypes: CheckboxOption[]) => {
    if (isTimelineInfiniteLoadOn) {
      dispatch(codeBenchActions.setFilterTypesInfinite(filterTypes));
      dispatchCaseEntries({
        newPage: 1,
        localEntriesSumCounts: entriesSumCounts,
        dateStr: dateFilterStr,
        messageTypes: filterTypes
      });
    } else {
      dispatch(codeBenchActions.setFilterTypes(filterTypes));
    }
  };

  const setFilterDates = (filterDate: commonEnums.DateFilterLabelEnum) => {
    let startDate = '';
    let dateFilterState: CMxCommonApp.TimelineDateFilter = {
      dateFilter: filterDate
    };
    let requestBody: CMxCommonApp.TimelineCountParams = {
      caseRun: activeCaseRun
    };
    if (filterDate !== commonEnums.DateFilterLabelEnum.ALL_EVENTS) {
      startDate = getDateForPastPeriod(filterDate)?.toString();
      requestBody = {
        ...requestBody,
        dateFilter: startDate
      };
      dateFilterState = {
        ...dateFilterState,
        dateFilterStr: startDate
      };
    }
    if (isTimelineInfiniteLoadOn) {
      dispatch(codeBenchActions.setFilterDatesInfinite(dateFilterState));
      dispatch(codeBenchActions.getPatientMessageCountsWithFilter(requestBody));
      dispatchCaseEntries({
        newPage: 1,
        localEntriesSumCounts: entriesSumCounts,
        dateStr: startDate,
        messageTypes: filterTypes
      });
    } else {
      dispatch(codeBenchActions.setFilterDates(dateFilterState));
      dispatch(codeBenchActions.getPatientMessageCounts(requestBody));
    }
  };

  const getDateForPastPeriod = (dateFilter: string): Date => {
    let days = 0;
    switch (dateFilter) {
      case commonEnums.DateFilterLabelEnum.SEVEN_DAYS:
        days = 7;
        break;
      case commonEnums.DateFilterLabelEnum.THIRTY_DAYS:
        days = 30;
        break;
      case commonEnums.DateFilterLabelEnum.THREE_YEARS:
        days = 3 * 365;
        break;
    }
    const today = new Date();
    const pastDate = new Date(today);
    pastDate.setDate(today.getDate() - days);
    return cmxDateTime.format(pastDate.toISOString(), cmxDateTime.FORMATS.DATE);
  };

  // filter by types
  if (hasFilterTypes) {
    filteredEntries = filteredEntries.filter(entry =>
      filterTypes.map(fType => fType.value).includes(entry?.message?.type)
    );
  }

  const isEntryIntoDateRangeFilter = (
    entry: CMxAPI.TimelineEntry,
    numberDays: number
  ) => {
    return isEntryIntoDateRange(
      entry.message.type === 'MDM'
        ? entry?.note_date
        : entry.message.type === 'SIU'
        ? entry?.service_date
        : entry?.meta?.event_datetime,
      numberDays
    );
  };

  // filter by date
  if (!isTimelineInfiniteLoadOn) {
    switch (dateFilter) {
      case commonEnums.DateFilterLabelEnum.SEVEN_DAYS:
        filteredEntries = filteredEntries.filter(entry =>
          isEntryIntoDateRangeFilter(entry, 7)
        );
        break;
      case commonEnums.DateFilterLabelEnum.THIRTY_DAYS:
        filteredEntries = filteredEntries.filter(entry =>
          isEntryIntoDateRangeFilter(entry, 30)
        );
        break;
      case commonEnums.DateFilterLabelEnum.THREE_YEARS:
        filteredEntries = filteredEntries.filter(entry =>
          isEntryIntoDateRangeFilter(entry, 365 * 3)
        );
        break;
    }
  }

  const entriesMessageType = Object.keys(commonEnums.EntriesMessageType).sort(
    (entryTypeA: string, entryTypeB: string) => {
      if (
        commonEnums.EntriesMessageType[
          entryTypeB as keyof typeof commonEnums.EntriesMessageType
        ] <
        commonEnums.EntriesMessageType[
          entryTypeA as keyof typeof commonEnums.EntriesMessageType
        ]
      ) {
        return 1;
      } else {
        return -1;
      }
    }
  );

  return (
    <ChakraProvider theme={dashboardTheme}>
      <Conditional on={isOpen}>
        {(entriesLoading || entriesMessageCountsLoading) &&
        entryPage === 1 &&
        shouldDispatch ? (
          <>
            {createPortal(
              <>
                <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
              </>,
              document.getElementById('cmx-drawer-header') || fallbackElement
            )}

            {createPortal(
              <>
                <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
                <LoadingSlugs width="80" key="loading-slugs" numberItems={1} />
                <LoadingSlugs key="loading-slugs" numberItems={3} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              </>,
              document.getElementById('cmx-drawer-body') || fallbackElement
            )}
          </>
        ) : (
          <>
            <Conditional on={!!document.getElementById('cmx-drawer-header')}>
              <>
                {createPortal(
                  <div className="more-patient-title">More Patient Data</div>,
                  document.getElementById('cmx-drawer-header') ||
                    fallbackElement
                )}
              </>
            </Conditional>
            <Conditional on={!!document.getElementById('cmx-drawer-body')}>
              <>
                {createPortal(
                  <div className="patient-timeline-body">
                    <Box __css={classes.stickyInfo}>
                      {/* @ts-ignore */}
                      <Box __css={classes.patientBlock}>
                        <PatientEncounter
                          propertyConfig={
                            PatientEncounterDashboardColumns.patientTimeline
                          }
                          fieldEditorInfo={fieldEditorInfo}
                          activeItem={activeCaseRun}
                        />
                      </Box>
                      <div style={classes.caseComparison}>
                        <h4>Compare with current case</h4>

                        <div style={classes.caseComparisonFields}>
                          <InfoCell
                            label="date of service"
                            value={
                              activeCaseRun.date_of_service?.toString() ?? ''
                            }
                            cellKey="drawer-date-of-service"
                          />

                          <InfoCell
                            label="specialty"
                            value={activeCaseRun.specialty?.toString() ?? ''}
                            cellKey="drawer-specialty"
                          />
                        </div>
                      </div>

                      {/* @ts-ignore */}
                      <Flex style={classes.filtersBox}>
                        <HStack
                          style={classes.filtersSection}
                          className={`filters-section ${
                            !!filterTypes.length ? 'filter-type-active' : ''
                          }`}>
                          <>
                            <div style={classes.filterType}>
                              <CheckboxMenu
                                options={entriesMessageType.map(key => ({
                                  label: `${
                                    commonEnums.EntriesMessageType[
                                      key as keyof typeof commonEnums.EntriesMessageType
                                    ]
                                  } (${
                                    entriesMessageCounts?.length > 0
                                      ? entriesMessageCounts.find(
                                          entryMessageCount =>
                                            entryMessageCount.message_type ===
                                            key
                                        )?.count ?? 0
                                      : 'N/A'
                                  })`,
                                  value: key
                                }))}
                                label="Select Type"
                                onApplyFilters={setFilterTypes}
                                selectedOptions={filterTypes}
                                menuRightIcon={
                                  <ChevronDown
                                    fill={theme.colors.foundation[200]}
                                    width="11px"
                                  />
                                }
                              />
                            </div>

                            <div style={classes.filterDate}>
                              <MenuButton
                                label={dateFilter}
                                className={`${
                                  dateFilter !==
                                  commonEnums.DateFilterLabelEnum.ALL_EVENTS
                                    ? 'filter-active'
                                    : ''
                                }`}>
                                <MenuItem
                                  onSelect={() =>
                                    setFilterDates(
                                      commonEnums.DateFilterLabelEnum.ALL_EVENTS
                                    )
                                  }>
                                  All Events
                                </MenuItem>
                                <MenuItem
                                  onSelect={() =>
                                    setFilterDates(
                                      commonEnums.DateFilterLabelEnum.SEVEN_DAYS
                                    )
                                  }>
                                  Past 7 days
                                </MenuItem>
                                <MenuItem
                                  onSelect={() =>
                                    setFilterDates(
                                      commonEnums.DateFilterLabelEnum
                                        .THIRTY_DAYS
                                    )
                                  }>
                                  Past 30 days
                                </MenuItem>
                                <MenuItem
                                  onSelect={() =>
                                    setFilterDates(
                                      commonEnums.DateFilterLabelEnum
                                        .THREE_YEARS
                                    )
                                  }>
                                  Past 3 years
                                </MenuItem>
                              </MenuButton>
                            </div>
                          </>

                          <div style={classes.filterShowAll}>
                            {((showAll && filterTypes?.length === 0) ||
                              (filteredEntries?.length > 0 &&
                                lengthNoSupportedInter !==
                                  lengthNoShownInter)) && (
                              <ButtonToggle
                                style={classes.buttonToggle}
                                label={
                                  showAll ? 'Showing All' : 'Not Showing All'
                                }
                                active={showAll}
                                onSelect={() => setShowAll(!showAll)}
                              />
                            )}
                          </div>
                          <div className="tenantTimezoneSection">
                            <span className="field-label">
                              All times listed in
                            </span>
                            <span className="field-data">
                              {activeCaseRun.tenant_timezone
                                ?.toString()
                                .replace('_', ' ') ?? ''}
                            </span>
                          </div>
                        </HStack>
                      </Flex>
                    </Box>
                    <div
                      style={classes.entriesSection}
                      className="entries-section">
                      {filteredEntries?.length > 0 ? (
                        filteredEntries.map((entry, entryIndex) => {
                          return (
                            <Message
                              type={entry?.message?.type}
                              match={isInteractionFromCase(
                                entry,
                                activeCaseRun
                              )}
                              size="md"
                              key={`entry-${entryIndex}`}
                              data-entry-type={entry?.message?.type}
                              data-entry-code={entry?.message?.code}>
                              <MessageHeader>
                                {resolveHeadTemplate(
                                  activeCaseRun,
                                  entry,
                                  orderTypeStatus
                                )}
                              </MessageHeader>
                              <MessageBody>
                                {resolveBodyTemplate(activeCaseRun, entry)}
                              </MessageBody>
                              <MessageFooter>
                                {resolveFootTemplate(activeCaseRun, entry)}
                              </MessageFooter>
                            </Message>
                          );
                        })
                      ) : (
                        <>
                          {!entriesFailed && !entriesLoading && (
                            <div
                              className="empty-message"
                              style={classes.notFoundMessage}>
                              Events for this patient have not been found.
                            </div>
                          )}
                        </>
                      )}
                    </div>

                    {entriesLoading && (
                      <LoadingSlugs
                        width="100"
                        key="loading-slugs"
                        numberItems={3}
                      />
                    )}

                    {entriesFailed && (
                      <div
                        className="empty-message"
                        style={classes.notFoundMessageError}>
                        Error loading entries, try again later.
                      </div>
                    )}
                  </div>,
                  document.getElementById('cmx-drawer-body') || fallbackElement
                )}
              </>
            </Conditional>
          </>
        )}
      </Conditional>
    </ChakraProvider>
  );
};
PatientTimeline.displayName = 'PatientTimeline';

export { PatientTimeline };
