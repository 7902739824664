import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Conditional } from '@codametrix/shared-views';
import { createPortal } from 'react-dom';
import { LoadingSlugs } from '@codametrix/ui-components';
import './coder-notes.scss';
import { Avatar } from '@chakra-ui/react';
import * as userUtils from '../../core/utils/user-utils';
import { Textarea } from '../../components/textarea/textarea';
import { codeBenchActions } from '@codametrix/ui-common';
import { isEmpty } from 'lodash';
import { CoderNoteCard } from './coder-note-card';
import { toggleDrawer } from '../../actions/ui';

export type CoderNoteEditProps = {
  noteId: string;
  isEditEnabled: boolean;
};

export type ConfirmModalProps = {
  enabled: boolean;
  title: string;
  proceedLabel: string;
  dismissLabel: string;
};

const fallbackElement = document.createElement('div');

const CoderNotes = () => {
  const {
    isOpen,
    servicesLoading,
    coderNotesParams,
    coderNotesList,
    activeUser,
    coderNote
  } = useSelector((state: CMx.ApplicationState) => {
    return state.coderNotes;
  });

  const dispatch = useDispatch();
  const [coderNoteContent, setCoderNoteContent] = useState('');
  const [editMode, setEditMode] = useState<CoderNoteEditProps>({
    noteId: '',
    isEditEnabled: false
  });
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setCoderNoteContent(coderNote);
  }, [coderNote]);

  useEffect(() => {
    setCoderNoteContent('');
    toggleEditMode(false);
  }, [isOpen]);

  /** Focus and active cursor on text area by default
   * and also when edit mode is disabled on note */
  useEffect(() => {
    if (!editMode?.isEditEnabled) {
      textareaRef.current?.focus();
    }
  }, [editMode]);

  /** Handler for change in add note content */
  const handleCoderNoteChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    let inputValue = e.target.value;
    setCoderNoteContent(inputValue);
  };

  /** Handler for cancel on Add Note,
   *  should close the drawer and reset the component state */
  const handleCoderNoteCancel = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch(toggleDrawer(false));
    setCoderNoteContent('');
  };

  /** Handler for add coder notes*/
  const handleAddCoderNotes = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if (!isEmpty(coderNoteContent)) {
      dispatch(
        codeBenchActions.addCoderNote({
          patientMRN: coderNotesParams.patientMRN,
          encounterUid: coderNotesParams.encounterUid,
          noteContent: coderNoteContent
        })
      );
    }
  };

  /** Handler to save edited note */
  const handleEditCoderNotes = (note: AmplifyAPI.CoderNoteRow) => {
    dispatch(
      codeBenchActions.updateCoderNote({
        encounterUid: coderNotesParams.encounterUid,
        patientMRN: coderNotesParams.patientMRN,
        noteId: note.note_id,
        noteContent: note.note_content
      })
    );
    toggleEditMode(false);
  };

  /** Handler to delete coder note*/
  const handleDeleteCoderNotes = (note: AmplifyAPI.CoderNoteRow) => {
    dispatch(
      codeBenchActions.deleteCoderNote({
        encounterUid: coderNotesParams.encounterUid,
        noteId: note.note_id
      })
    );
  };

  /** toggle edit mode of note and ensure only one note is enabled to edit at a time */
  const toggleEditMode = (toggle: boolean, noteId?: string) => {
    setEditMode({
      noteId: noteId ?? '',
      isEditEnabled: toggle
    });
  };

  /** Click on ESC should close the drawer */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      if (!editMode?.isEditEnabled) dispatch(toggleDrawer(false));
    }
  };

  const userAvatarInitials = userUtils.getAvatarInitials(
    activeUser.firstName,
    activeUser.lastName
  );
  const userAvatar = (
    /* @ts-ignore */
    <Avatar
      name={userAvatarInitials}
      bgColor="#6B8D99"
      color="#FFFFFF"
      size="sm"
    />
  );

  return (
    <div onKeyDown={handleKeyDown}>
      <Conditional on={isOpen}>
        {servicesLoading ? (
          <>
            {createPortal(
              <>
                <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
                <LoadingSlugs width="25" key="loading-slugs" numberItems={1} />
                <LoadingSlugs width="80" key="loading-slugs" numberItems={1} />
              </>,
              document.getElementById('cmx-drawer-header') || fallbackElement
            )}

            {createPortal(
              <>
                <LoadingSlugs key="loading-slugs" numberItems={3} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
                <br />
                <LoadingSlugs width="60" key="loading-slugs" numberItems={5} />
              </>,
              document.getElementById('cmx-drawer-body') || fallbackElement
            )}
          </>
        ) : (
          <>
            <Conditional on={!!document.getElementById('cmx-drawer-header')}>
              <>
                {createPortal(
                  <>Notes for Encounter #{coderNotesParams.encounter}</>,
                  document.getElementById('cmx-drawer-header') ||
                    fallbackElement
                )}
              </>
            </Conditional>
            <Conditional on={!!document.getElementById('cmx-drawer-body')}>
              <>
                {createPortal(
                  <>
                    {coderNotesList?.map((entry, entryIndex) => {
                      return (
                        <CoderNoteCard
                          note={entry}
                          activeUser={activeUser}
                          editMode={editMode}
                          toggleEditMode={toggleEditMode}
                          handleEditCoderNotes={handleEditCoderNotes}
                          handleDeleteCoderNotes={handleDeleteCoderNotes}
                        />
                      );
                    })}
                  </>,
                  document.getElementById('cmx-drawer-body') || fallbackElement
                )}
              </>
            </Conditional>
            <Conditional on={!!document.getElementById('cmx-drawer-footer')}>
              <>
                {createPortal(
                  <div className="coder-notes-send">
                    <Textarea
                      leftIcon={userAvatar}
                      placeholder="Add Note"
                      value={coderNoteContent}
                      disabled={editMode.isEditEnabled}
                      onChange={handleCoderNoteChange}
                      ref={textareaRef}
                    />
                    <div className="coder-note-actions">
                      <button
                        onClick={ev => {
                          handleCoderNoteCancel(ev);
                        }}
                        className={`chakra-secondary-button ${
                          editMode.isEditEnabled ? 'disabled' : ''
                        }`}>
                        Cancel
                      </button>
                      <button
                        onClick={ev => handleAddCoderNotes(ev)}
                        className={`chakra-primary-button ${
                          editMode.isEditEnabled || isEmpty(coderNoteContent)
                            ? 'disabled'
                            : ''
                        }`}>
                        Save
                      </button>
                    </div>
                  </div>,
                  document.getElementById('cmx-drawer-footer') ||
                    fallbackElement
                )}
              </>
            </Conditional>
          </>
        )}
      </Conditional>
    </div>
  );
};
CoderNotes.displayName = 'CoderNotes';

export { CoderNotes };
