import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleDeleteModal,
  deleteDictionary
} from '../../../../actions/dictionary-values';
import { Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import { ModalComponent } from '../../../../components/Modal/modal';
import { Button } from '../../../../components/button/button';
import { Checkbox } from '../../../../components/checkbox/checkbox';
import theme from '../../../../theme';

export const DeleteModal = () => {
  const dispatch = useDispatch();

  const { associatedDictionaries } = useSelector(
    (state: CMx.ApplicationState) => state.dictionary
  );

  let defaults: { [key: string]: boolean } = {};
  associatedDictionaries.forEach(dictionary => {
    defaults[dictionary.id] = false;
  });

  const [deleteSelection, changeDeleteSelection] = useState<{
    [key: string]: boolean;
  }>(defaults);

  const _handleDelete = (deleteIds: string[]) => {
    dispatch(deleteDictionary(deleteIds));
  };

  const _handleSubmit = () => {
    const deleteIds = Object.keys(deleteSelection).filter(
      id => deleteSelection[id]
    );
    _handleDelete(deleteIds);
  };

  const _handleClose = () => {
    dispatch(toggleDeleteModal(false));
  };

  const dictionariesForm = associatedDictionaries.map(dictionary => {
    return {
      key: dictionary.id.toString(),
      helpText: dictionary.status
    };
  });

  const name = associatedDictionaries[0]?.name ?? '';

  const handleServiceLineSelection = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const values = { ...deleteSelection };
    values[event.target.id] = event.target.checked;
    changeDeleteSelection(values);
  };

  return associatedDictionaries.length > 1 ? (
    <ModalComponent
      size={'xl'}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<Text>Multiple Dictionaries Found</Text>}
      modalContent={
        <Grid sx={{ margin: `0 ${theme.space[16]}` }}>
          {name} exists in multiple states. Which would you like to delete?
          <GridItem sx={{ margin: `0 0 0 ${theme.space[16]}` }}>
            {dictionariesForm.map(item => {
              return (
                <Checkbox
                  label={item.helpText}
                  key={item.key}
                  onChange={handleServiceLineSelection}
                  id={item.key}
                  isChecked={deleteSelection[`${item.key}`]}
                  dataTestId={item.helpText}
                  name={item.helpText}
                />
              );
            })}
          </GridItem>
        </Grid>
      }
      modalFooter={
        <Flex columnGap={'8px'}>
          <Button
            label={'Cancel'}
            onClick={_handleClose}
            name={'Cancel'}
            dataTestId={'Cancel'}
          />{' '}
          <Button
            label={'Delete Selection'}
            onClick={_handleSubmit}
            disabled={!Object.values(deleteSelection).includes(true)}
            dataTestId={'deleteBtn'}
            name={'Delete'}
          />
        </Flex>
      }
    />
  ) : (
    <ModalComponent
      size={'xl'}
      isOpen={true}
      handleClose={_handleClose}
      modalHeader={<Text>Are you sure?</Text>}
      modalContent={
        <Text sx={{ margin: `0 ${theme.space[16]}` }}>Delete {name}?</Text>
      }
      modalFooter={
        <Flex columnGap={'8px'}>
          <Button
            label={'Cancel'}
            onClick={_handleClose}
            name={'Cancel'}
            dataTestId={'Cancel'}
          />{' '}
          <Button
            label={'Delete'}
            dataTestId={'deleteBtn'}
            name={'Delete'}
            onClick={() =>
              _handleDelete([associatedDictionaries[0].id.toString()])
            }
          />
        </Flex>
      }
    />
  );
};
