import { authenticationChannel } from '@codametrix/ui-common';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { Action } from 'typescript-fsa';
import {
  isFSA,
  isSubscriptionObserver,
  isSubscriptionTransformer,
  passthrough,
  receiverFactory
} from '../receivers/genericReceiverFactory';

export const authenticationChannelMiddlewareFactory = (
  subscriptions: CMx.ChannelSubscription[]
) => {
  const senders = subscriptions.filter(isSubscriptionTransformer);
  const observers = subscriptions.filter(isSubscriptionObserver);

  senders.forEach(senders => {
    senders.transform = senders.transform ?? passthrough;
  });

  const _generatedMiddleware: Middleware = (api: MiddlewareAPI) => (
    next: Dispatch<AnyAction>
  ) => (action: Action<any>) => {
    if (isFSA(action)) {
      const dispatchables = senders.filter(sender => sender.match(action));
      dispatchables.forEach(dispatchable => {
        const transform = dispatchable.transform as CMx.ActionTransformer;
        const toDispatch = transform({ ...action });
        authenticationChannel.postMessage(toDispatch);
      });
      // perhaps not necessary, but added here for symmetry with the receivers.
      observers.forEach(observer => observer.observe(action));
    }
    next(action);
  };
  return _generatedMiddleware;
};

export const authenticationChannelReceiverFactory = receiverFactory(
  authenticationChannel
);

export default authenticationChannelMiddlewareFactory;
