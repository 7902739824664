/// <reference path="../types.d.ts" />
import { Dispatch } from 'redux';
import { replace } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import { Organization, RulesList } from './action-types';
import { isServiceOrganization } from './_action-utilities';
import { STUB_ORG_RELATIONSHIP } from '../views/organizations/stub-organization';
import { showFeedback, loadingAction } from './ui';
import { api } from '../core/net';
import { cmxDateTime, HttpMethods, cmxTypeguards } from '@codametrix/ui-common';
import { inProgress } from './common';
import { USER_ACTION_METADATA } from './action-constants';
import { RelationshipEditType } from '../core/enums';
import { objectUtils } from '@codametrix/ui-common';
import { isNewDesign } from '../core/middleware/new-design-middleware';

const { isOrganizationRelationship } = cmxTypeguards;

const CODER_CONFIG = 'coder_config';

/* --------------------------------
 * actions
 * -------------------------------- */

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.OrganizationState>(actionCreator);

const toggleEdit = actionCreator<boolean>(Organization.TOGGLE_EDIT, {
  ...USER_ACTION_METADATA
});
const navigateAncestor = actionCreator<CMxAPI.Organization>(
  Organization.NAVIGATE_ANCESTOR,
  { ...USER_ACTION_METADATA }
);
const availableAssociations = actionCreator<CMxAPI.Organization[]>(
  Organization.AVAILABLE_ASSOCIATIONS
);
const establishRelationship = actionCreator<CMxAPI.OrganizationRelationship>(
  Organization.RELATIONSHIP
);
const serviceLinesAvailable = actionCreator<CMxAPI.ServiceLine[]>(
  Organization.SERVICE_LINES
);

const orgRelationships = actionCreator<CMxAPI.OrganizationRelationship[]>(
  Organization.ORG_TO_ORG_RELATIONSHIP
);

const organizationSelected = actionCreator<CMxAPI.Organization>(
  Organization.ORGANIZATION_SELECTED
);
const organizationChildren = actionCreator<CMxAPI.Organization[]>(
  Organization.ORG_CHILDREN
);
const addChildOrgType = actionCreator<ChildOrgType>(Organization.ADD_CHILD);
const associationCreated = actionCreator<void>(
  Organization.ASSOCIATION_CREATED
);

const chooseDiagram = actionCreator<string>(RulesList.CHOOSE_RULES_DIAGRAM);

const timezonesAvailable = actionCreator<string[]>(Organization.TIMEZONES);

/* --------------------------------
 * types
 * -------------------------------- */

type TokenAndOrg = {
  token: CMxCommonApp.BearerToken;
  organization: CMxAPI.Organization;
};
type ChildOrgType = boolean | string;

type CreateAssociation = {
  token: CMxCommonApp.BearerToken;
  edits: CMx.PartialRelationshipEdit[];
  newOrg: CMxAPI.Organization;
};

type AddChildOrg = {
  viewOnly: boolean;
  token: CMxCommonApp.BearerToken;
  childOrgType: boolean | string;
  parentOrg: CMxAPI.Organization;
  isAddChild?: boolean;
};

/* --------------------------------
 * private functions
 * -------------------------------- */

const _updateAssociation = async (
  relationshipToSave: CMxAPI.OrganizationRelationship
): Promise<CMxAPI.OrganizationRelationship> => {
  try {
    const isUpdate = relationshipToSave.id !== null;
    let method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;
    const relationship = await api<CMxAPI.OrganizationRelationship>({
      endpoint: `/organization/relationship/v1`,
      init: {
        method
      },
      body: relationshipToSave
    });
    return relationship;
  } catch (e) {
    console.log(e);
  }
  return Promise.reject();
};

const _getOrganization = async (
  { id, referringOrgId }: CMx.GetOrganizationArgs,
  dispatch: Dispatch
) => {
  dispatch(inProgress());
  const endpoint = isNaN(id as number)
    ? `/organization/tenant/v1?tenantId=${id}`
    : `/organization/id/v1?organizationId=${id}`;
  const orgOpts = {
    endpoint: endpoint
  };

  let organization;
  try {
    isNewDesign && dispatch(loadingAction({ isLoading: true }));
    organization = await api<CMxAPI.Organization>(orgOpts);
  } finally {
    isNewDesign && dispatch(loadingAction({ isLoading: false }));
  }

  if (
    organization.displayName === null ||
    organization.displayName === undefined
  ) {
    organization.displayName = organization.organizationName;
  }
  if (referringOrgId) {
    organization.referringOrgId = referringOrgId;
  }

  dispatch(organizationSelected(organization));

  if (!isServiceOrganization(organization.organizationType)) {
    dispatch(establishRelationship(STUB_ORG_RELATIONSHIP));
  } else {
    const params = new URLSearchParams({
      toOrganizationId: `${organization.id}`,
      fromOrganizationId: `${referringOrgId || organization.parentId}`
    });

    const relationships = await api<CMxAPI.OrganizationRelationship[]>({
      endpoint: `/organization/relationship/v2?${params.toString()}`
    });

    dispatch(orgRelationships(relationships));
  }
  dispatch(organizationChildren(organization.children));
  dispatch(replace(`/cmx/admin/orgs/${organization.id}/edit`));
};

const _getActiveOrginization = async (id: number, dispatch: Dispatch) => {
  dispatch(inProgress());
  const endpoint = isNaN(id as number)
    ? `/organization/tenant/v1?tenantId=${id}`
    : `/organization/id/v1?organizationId=${id}`;
  const orgOpts = {
    endpoint: endpoint
  };
  const organization = await api<CMxAPI.Organization>(orgOpts);

  if (
    organization.displayName === null ||
    organization.displayName === undefined
  ) {
    organization.displayName = organization.organizationName;
  }

  return organization;
};

/* --------------------------------
 * public api
 * -------------------------------- */

const navigate = createAsync<TokenAndOrg, void, CMxCommonApp.SubmitError>(
  Organization.NAVIGATE,
  async ({ token, organization }, dispatch) => {
    //dispatch(navigateAncestor(organization));
    dispatch(toggleEdit(false));
    _getOrganization({ token, id: organization.id }, dispatch);
    return;
  }
);

const addChildOrg = createAsync<AddChildOrg, void, CMxCommonApp.SubmitError>(
  Organization.ADD_CHILD,
  async (
    { viewOnly, childOrgType, parentOrg, isAddChild = true },
    dispatch
  ) => {
    dispatch(toggleEdit(false));
    dispatch(inProgress());

    if (
      !viewOnly &&
      parentOrg &&
      isServiceOrganization(childOrgType as string)
    ) {
      try {
        const orgOpts = {
          endpoint: `/organization/parent/v1?tenantId=${parentOrg.parentTenantId}`
        };
        const orgs = await api<CMxAPI.Organization[]>(orgOpts);
        const matchOrgType = (kid: any) =>
          kid.organizationType === childOrgType;
        const alreadyAssigned = (parentOrg.children || [])
          .filter(matchOrgType)
          .map(kid => kid.id);
        const alreadyAssignedSet = new Set(alreadyAssigned);
        const associations: CMxAPI.Organization[] = orgs.filter(matchOrgType);
        const associationsToSend = associations.filter(
          kid => !alreadyAssignedSet.has(kid.id)
        );

        dispatch(availableAssociations(associationsToSend));
      } catch (e) {
        console.log(e);
      }
    }

    isAddChild && dispatch(addChildOrgType(childOrgType));
  }
);

const doAssociation = createAsync<
  CreateAssociation,
  void,
  CMxCommonApp.SubmitError
>(
  Organization.ASSOCIATION_CREATED,
  async ({ token, edits, newOrg }, dispatch) => {
    dispatch(inProgress());

    const isAssociationOnly = newOrg === undefined;
    const isUpdate = newOrg?.id !== 0;
    let method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;

    let updatedOrg: CMxAPI.Organization | undefined = undefined;

    if (!isAssociationOnly) {
      updatedOrg = await api<CMxAPI.Organization>({
        endpoint: `/organization/v1`,
        init: { method },
        body: newOrg
      });
    }

    const responses: any[] = [];

    for (const edit of edits) {
      if (isOrganizationRelationship(edit.organizationRelationship)) {
        const orgRelationship = {
          ...edit.organizationRelationship
        };
        if (!orgRelationship.toOrganizationId && updatedOrg !== undefined) {
          orgRelationship.toOrganizationId = updatedOrg.id;
        }
        const now = Date.now();

        let rel: CMxAPI.OrganizationRelationship | undefined;

        if (edit.type === RelationshipEditType.REMOVE) {
          orgRelationship.endDate = Date.now();
          rel = await _updateAssociation(orgRelationship);
        } else if (edit.type === RelationshipEditType.ADD) {
          // in the case of some previously end-dated relationships
          // I'm re-enabling them by setting the endDate to a time close to the end
          // of the unix epoch.  there's a better way to do this, but will leave it as is
          // for now.
          if (
            orgRelationship.endDate !== null &&
            orgRelationship.endDate < now
          ) {
            orgRelationship.endDate = 2147403600000; // close to the end of the unix epoch
          }
          rel = await _updateAssociation(orgRelationship);
        }

        if (rel !== undefined) {
          responses.push(rel);
        }
      }
    }

    if (!isAssociationOnly && updatedOrg !== undefined) {
      // need to do one last call to make sure that we get a fresh organization
      const id = updatedOrg.parentId;
      await _getOrganization({ token, id: id as number }, dispatch);
      dispatch(
        showFeedback({
          id: Date.now(),
          message: `Saved information for ${updatedOrg.displayName ||
            updatedOrg.organizationName}.`,
          dismissable: true
        })
      );
    } else if (responses.length) {
      const firstNonNull = responses.find(response =>
        objectUtils.isNumber(response.fromOrganizationId)
      );
      if (firstNonNull) {
        const orgId = firstNonNull.fromOrganizationId;
        await _getOrganization({ token, id: orgId as number }, dispatch);

        dispatch(
          showFeedback({
            id: Date.now(),
            message: `Saved information for ${firstNonNull.displayName ||
              firstNonNull.organizationName}.`,
            dismissable: true
          })
        );
      }
    }

    dispatch(associationCreated());
  }
);

const saveOrganization = createAsync<
  TokenAndOrg,
  void,
  CMxCommonApp.SubmitError
>(Organization.SAVE, async ({ token, organization }, dispatch) => {
  dispatch(inProgress());

  const isUpdate = organization.id !== 0;
  let method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;

  let org;
  try {
    isNewDesign && dispatch(loadingAction({ isLoading: true }));
    org = await api<CMxAPI.Organization>({
      endpoint: `/organization/v1`,
      init: { method },
      body: organization
    });
  } finally {
    isNewDesign && dispatch(loadingAction({ isLoading: false }));
  }

  if (!isUpdate) {
    await _getOrganization({ token, id: org.parentId }, dispatch);
    dispatch(toggleEdit(false));
  } else {
    await _getOrganization({ token, id: org.id }, dispatch);
    dispatch(toggleEdit(false));
  }

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Saved information for ${org.displayName ||
        org.organizationName}.`,
      dismissable: true
    })
  );
});

const getActiveOrginization = createAsync<
  number,
  void,
  CMxCommonApp.SubmitError
>(Organization.STORE_ACTIVE, async (id, dispatch) => {
  dispatch(inProgress());

  return _getActiveOrginization(id, dispatch);
});

const getOrganization = createAsync<
  { params: CMx.GetOrganizationArgs; activeTenantId: string },
  void,
  CMxCommonApp.SubmitError
>(Organization.GET_ORGANIZATION, async (payload, dispatch) => {
  const { params, activeTenantId } = payload;
  const serviceLines = api<CMxAPI.ServiceLine[]>({
    endpoint: `/organization/service/v1`,
    init: { method: HttpMethods.GET }
  });
  serviceLines.then(lines => dispatch(serviceLinesAvailable(lines)));

  if (activeTenantId) {
    const dictionaryInfo = {
      dictionaryName: 'Timezones',
      columnName: 'Timezone'
    };

    const { dictionaryName, columnName } = dictionaryInfo as any;

    const searchParams = new URLSearchParams({
      tenantId: activeTenantId,
      derivedIndicator: 'true',
      effectiveDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE),
      dictionaryName: dictionaryName as string,
      columnName: columnName as string
    });

    let timezones;
    try {
      isNewDesign && dispatch(loadingAction({ isLoading: true }));
      timezones = await api<CMxAPI.RowData>({
        endpoint: `/dictionary/row/v2?${searchParams.toString()}`,
        init: {
          method: HttpMethods.GET
        }
      });
    } finally {
      isNewDesign && dispatch(loadingAction({ isLoading: false }));
    }

    const timezoneList = timezones.rowValues.map(row => row[columnName]);

    dispatch(timezonesAvailable(timezoneList));
  }

  await _getOrganization(params, dispatch);
  return;
});

const getFeaturesBySection = createAsync<
  string,
  CMxAPI.ConfigurationValue[],
  CMxCommonApp.SubmitError
>(Organization.GET_FEATURES, async (section, dispatch) => {
  const features = await api<CMxAPI.ConfigurationValue[]>({
    endpoint: `/configuration/value/section/${section}/v1`
  });

  return features;
});

const updateFeatures = createAsync<
  CMxAPI.ConfigurationValue[],
  void,
  CMxCommonApp.SubmitError
>(Organization.UPDATE_FEATURES, async (updatedFeatures, dispatch) => {
  try {
    await api<any>({
      endpoint: `/configuration/value/section/${CODER_CONFIG}/v1`,
      init: {
        method: HttpMethods.PUT
      },
      body: updatedFeatures
    });
  } catch (e) {
    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Information did not save correctly, error message: ${e}`,
        dismissable: true
      })
    );
    return;
  }

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Successfully saved features`,
      dismissable: true
    })
  );

  dispatch(getFeaturesBySection(CODER_CONFIG));
});

const replaceOrganizationState = actionCreator<CMx.InterfaceState>(
  Organization.REPLACE_ORGANIZATION_STATE
);

export const syncActions = {
  toggleEdit,
  serviceLinesAvailable,
  navigateAncestor,
  availableAssociations,
  establishRelationship,
  organizationSelected,
  organizationChildren,
  addChildOrgType,
  orgRelationships,
  associationCreated,
  timezonesAvailable,
  chooseDiagram
};

export const asyncActions = {
  getOrganization,
  saveOrganization,
  doAssociation,
  navigate,
  addChildOrg,
  getFeaturesBySection
};

export {
  getOrganization,
  saveOrganization,
  doAssociation,
  toggleEdit,
  navigate,
  addChildOrg,
  getActiveOrginization,
  addChildOrgType,
  getFeaturesBySection,
  updateFeatures,
  replaceOrganizationState
};
