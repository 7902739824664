/// <reference path="../types.d.ts" />

import InitialState from './initial-state';
import * as CaseActions from '../actions/cases.action';
import * as ContextActions from '../actions/contexts';
import { docMetadataAvailable } from '../actions/case-documents.actions';
import { LOAD_STATES } from './common';
import {
  cmxDateTime,
  cmxArtifacts,
  commonEnums as enums,
  highlighterUtils,
  userUtils,
  caseUtils,
  bpmSteps,
  chargeConverters,
  activationProcessor,
  vitalware,
  commonEnums,
  cmxTypeguards,
  cmxNotes,
  appServiceLines,
  uiActions,
  documentUtils
} from '@codametrix/ui-common';
import {
  cmxReducerFactory,
  isLoadingClaim,
  loadStateClaim
} from './reducer-utils';
import { bpmTasksAvailable } from '../actions/bpm-steps.action';
import { DefaultGroupedCases } from './state/cases';
import * as ServiceLineActions from '../actions/service-lines';
import { buildRoleSet } from './users/roles';
import { fetchContexts, replaceUIState } from '../actions/ui';
import { formErrors } from '../stubs/form-errors';
import { roleShim } from '../actions/_action-utilities';

const { FilterKeys, CaseArtifactTypes, CustomerPhase } = enums;
const { getArtifactSet } = cmxArtifacts;

const CODER_SET = new Set([
  CaseArtifactTypes.PROCESSED,
  CaseArtifactTypes.SUBMITTED,
  CaseArtifactTypes.CODED
]);

const submitterSet = new Set([commonEnums.InvoiceTypes.SUBMITTED]);
const originalSet = new Set([commonEnums.InvoiceTypes.PROCESSED]);

export const decorateNotes = (notes: CMxAPI.Note[]) => {
  const decorated = notes.map(note => {
    if (
      cmxTypeguards.isIPCNote(note) &&
      note.encoding === 'base64' &&
      note.type !== 'text/html'
    ) {
      note.content = cmxNotes.convertBase64(note.content);
    }

    const segments = highlighterUtils.segmentize(note);
    return documentUtils.decoratedNoteFactory({
      ...note,
      segments,
      spans: []
    });
  });
  return decorated;
};

const getNotes = (caseRun: CMxAPI.CaseRun) => {
  const { notes } = caseRun;
  return decorateNotes(notes as CMxAPI.Note[]);
};

const getStartState = (state: CMx.CaseState) => {
  return state.loadState.initial ? state.loadState : LOAD_STATES.started;
};

const cleanDetail = Object.freeze({
  codeDescriptions: InitialState.cases.codeDescriptions,
  actionsState: { ...InitialState.cases.actionsState },
  artifactList: [],
  notes: [],
  codeEdits: InitialState.cases.codeEdits,
  activeItem: InitialState.cases.activeItem,
  submittedItem: InitialState.cases.submittedItem
});

const runTypes = InitialState.cases.runTypes;

export const sortCaseRuns = (
  caseDetail: CMxAPI.Case,
  caseRuns: CMxAPI.SortedCaseRuns
) => {
  runTypes.forEach(type => {
    caseRuns[type] = caseUtils.getCaseRuns(caseDetail, type);
  });

  return caseRuns;
};

const addDescriptionsToSimplifiedInvoices = (
  simplifiedInvoices: CMxCommonApp.SimplifiedInvoice[],
  codeDescriptions: CMxAPI.CodeResponse
) => {
  const attachDescription = (code: any) => {
    const key = ['code', 'diagnosis_code', 'modifier_code'].find(
      key => code[key]
    );
    if (key) {
      const description: any = codeDescriptions[code[key]];
      if (!description) {
        code.description = 'N/A';
      } else {
        code.description =
          description.descriptor ||
          description.description ||
          description.shortDescription;
      }
    }
  };

  return [...simplifiedInvoices].map(invoice => {
    invoice.transactions.forEach(txn => {
      txn.codes.forEach(attachDescription);
    });

    const dx = invoice.shared_codes?.diagnoses ?? [];
    const mods = invoice.shared_codes?.modifiers ?? [];
    dx.forEach(attachDescription);
    mods.forEach(attachDescription);

    return invoice;
  });
};

const reducer = cmxReducerFactory(InitialState.cases)
  .case(fetchContexts.async.done, (state, { result: context }) => {
    return { ...state, context };
  })
  .case(ContextActions.displayContexts, (state, contexts) => {
    return { ...state, contexts, loading: false };
  })
  .case(CaseActions.featureActivation, (state, featureActivation) => {
    const { notes } = state;
    const activations = activationProcessor(
      featureActivation,
      state.activations.panel,
      state.activations.property,
      state.activations.spans
    );
    if (notes.length === 0) {
      return { ...state, activations };
    }
    const note = notes[0] ?? {};
    const boundedSpans = highlighterUtils.normalizeSpans(
      note.segments || [],
      activations.spans.body ?? []
    );
    // in the case of no notes
    const returnedNotes: CMxCommonApp.DecoratedNote[] = [
      { ...note, spans: boundedSpans }
    ];
    return { ...state, activations, notes: returnedNotes };
  })
  .case(CaseActions.detailToggleArtifacts, (state, toggleAll) => {
    const { activeItem: firstRun, submittedItem, codeEdits, roles } = state;

    const artifactSet = userUtils.isCoder(roles)
      ? CODER_SET
      : getArtifactSet(toggleAll, firstRun);

    let initialInvoices =
      chargeConverters.simplifiedInvoices(firstRun, true, {
        ASSEMBLED: codeEdits
      }) ?? [];

    if (firstRun.artifacts.length) {
      // convert artifacts to invoices so that we have something to display on the UI.
      const artifacts = firstRun.artifacts.filter(
        artifact => artifact.type === CaseArtifactTypes.PREDICTION
      );
      const convertedInvoices = cmxArtifacts.artifactToSimplifiedInvoice(
        artifacts
      );
      initialInvoices = [...initialInvoices, ...convertedInvoices];
    }
    // handle rolled units.
    if (submittedItem) {
      initialInvoices = cmxArtifacts.handleImportedCases(
        initialInvoices,
        submittedItem,
        submitterSet,
        originalSet
      );
    }

    let simplifiedInvoices = initialInvoices
      .filter(invoice => artifactSet.has(invoice.type))
      .sort(cmxArtifacts.sortInvoices);

    simplifiedInvoices = addDescriptionsToSimplifiedInvoices(
      simplifiedInvoices || [],
      state.codeDescriptions
    );

    return {
      ...state,
      artifactSet,
      simplifiedInvoices,
      artifactShowAll: toggleAll
    };
  })
  .case(bpmTasksAvailable, (state, evidenceList) => {
    const { activeItem } = state;
    const stages = bpmSteps.stepsToStages(
      evidenceList[activeItem.process_instance_id]
    );
    return { ...state, stages };
  })
  .case(CaseActions.list.async.started, state => {
    const loadState = getStartState(state);
    const listLoadState = loadStateClaim(loadState, 'list');
    return {
      ...state,
      ...cleanDetail,
      loadState: listLoadState,
      refreshList: false
    };
  })
  .case(CaseActions.list.async.failed, state => {
    const isListLoading =
      isLoadingClaim(state.loadState) && state.loadState.claim === 'list';
    const loadState = isListLoading
      ? loadStateClaim(LOAD_STATES.failed, 'list')
      : { ...state.loadState };

    return { ...state, loadState };
  })
  .case(CaseActions.list.async.done, (state, { result }) => {
    const { content } = result;

    const { sortablePageable } = state;
    const sp: CMxCommonApp.SortablePageable<any> = {
      ...sortablePageable,
      pageableDefinition: result
    };
    const apiResponse: CMx.ApiResponse<CMxAPI.Case> = { ...state.apiResponse };
    apiResponse.items = content;

    const items = content
      .filter(item => {
        return item.id !== null && item.caseRuns.length > 0;
      })
      .map(caseUtils.getCaseRun);

    const isListLoading =
      isLoadingClaim(state.loadState) && state.loadState.claim === 'list';
    const loadState = isListLoading
      ? loadStateClaim(LOAD_STATES.done, 'list')
      : { ...state.loadState };

    return {
      ...state,
      pageable: result,
      sortablePageable: sp,
      loadState,
      items
    };
  })

  .case(CaseActions.getDictionary.async.started, state => {
    const { filterLoadStates } = state;
    filterLoadStates.set('procedure_type_category', LOAD_STATES.started);

    return { ...state, filterLoadStates };
  })
  .case(CaseActions.getDictionary.async.failed, state => {
    return {
      ...state
    };
  })
  .case(CaseActions.amplifyProcessInstance.async.started, state => {
    return {
      ...state,
      actionsState: { ...InitialState.cases.actionsState }
    };
  })
  .case(CaseActions.amplifyProcessInstance.async.failed, state => {
    const actionsState = { ...state.actionsState };
    actionsState.launchAmplify = {
      enabled: false,
      visible: true
    };

    return {
      ...state,
      actionsState
    };
  })
  .cases(
    [
      CaseActions.amplifyProcessInstance.async.done,
      CaseActions.importToAmplify.async.done
    ],
    (state, { result }) => {
      const actionsState = { ...state.actionsState };
      const { roles, activeItem } = state;
      const isSupportEngineer = roles.find(role => role === 'support_engineer');
      const isProfessionalServices = roles.find(
        role => role === 'professional_services'
      );
      const isCodingManager = roles.find(role => role === 'coding_manager');
      const isCqaManager = roles.find(role => role === 'cqa_manager');
      const isCqaCoder = roles.find(role => role === 'cqa_coder');
      const isCoder = roles.find(role => role === 'coder');
      const isCoordinator = roles.find(role => role === 'coordinator');
      const resources = state.context.activeContext?.resources;

      if (result) {
        // if amplify knows about the case, then the case
        // has already been imported and is ready to launch
        actionsState.launchAmplify = {
          enabled: true,
          visible:
            isSupportEngineer ||
            isProfessionalServices ||
            isCodingManager ||
            isCqaManager ||
            isCqaCoder ||
            isCoder ||
            isCoordinator
        };

        actionsState.importAmplify = {
          enabled: false,
          visible: false
        };
      } else {
        const { uiStatus } = commonEnums;

        const terminalStatus = new Set<string>([
          uiStatus.AUTOMATED,
          uiStatus.CANCELLED,
          uiStatus.UPDATED_MANUAL,
          // leaving out dormant for now.
          // uiStatus.DORMANT,
          uiStatus.MANUAL,
          'active'
        ]);

        const { CaseArtifactTypes } = commonEnums;

        const requiredInvoices = new Set<string>([
          CaseArtifactTypes.ASSEMBLED,
          CaseArtifactTypes.CLINICAL,
          CaseArtifactTypes.MAPPED
        ]);

        const invoices: any = (activeItem.invoices ?? []).length
          ? activeItem.invoices
          : activeItem.artifacts;

        const hasRequired = (invoices ?? []).some((invoice: any) =>
          requiredInvoices.has(invoice.type)
        );
        const hasImportAmplify = (resources ?? []).some((resource: any) => {
          return resource.resourceName === enums.ResourceId.IMPORT_AMPLIFY;
        });

        const importable =
          terminalStatus.has(activeItem.case_status) &&
          hasRequired &&
          hasImportAmplify;
        let isImportVisible = false;
        if (isSupportEngineer || isProfessionalServices) {
          isImportVisible = true;
        }
        actionsState.importAmplify = {
          enabled: importable,
          visible: isImportVisible
        };
        actionsState.launchAmplify = {
          enabled: false,
          visible: false
        };
      }

      return {
        ...state,
        activeItem: {
          ...state.activeItem,
          case_type: appServiceLines.isIPC(state.activeItem.service_line)
            ? commonEnums.UIModesEnum.STANDALONE
            : result?.caseType
        },
        actionsState
      };
    }
  )
  .case(CaseActions.getDictionary.async.done, (state, action) => {
    let index = state.criteriaFilter.filterForms.findIndex(criteriaForm => {
      return criteriaForm.key === action.params.key;
    });

    state.criteriaFilter.filterForms[index].formDefinition.fieldGroups = [
      action.result
    ];

    const { filterLoadStates } = state;
    filterLoadStates.set('procedure_type_category', LOAD_STATES.done);

    return {
      ...state,
      filterLoadStates
    };
  })
  .cases(
    [CaseActions.fetchCodeRequests.async.done],
    (state, { result: descriptions }) => {
      const codeDescriptions = { ...state.codeDescriptions, ...descriptions };
      return {
        ...state,
        codeDescriptions,
        loadState: LOAD_STATES.done
      };
    }
  )
  // This is split off from the top bc need to attach descriptions
  // to the invoices.  In the case where we're using artifacts, we only
  // need to pass the map to the case detail view.
  .cases([CaseActions.fetchInvoiceCodeDescriptions], (state, descriptions) => {
    const codeDescriptions = { ...state.codeDescriptions, ...descriptions };
    const simplifiedInvoices = addDescriptionsToSimplifiedInvoices(
      state.simplifiedInvoices || [],
      codeDescriptions
    );
    return {
      ...state,
      codeDescriptions,
      simplifiedInvoices,
      loadState: LOAD_STATES.done
    };
  })
  .case(CaseActions.fetchCodeRequests.async.failed, state => {
    return {
      ...state
    };
  })
  .case(CaseActions.getCaseFilter.async.started, state => {
    const { filterLoadStates } = state;
    filterLoadStates.set('case_status', LOAD_STATES.started);
    return { ...state, filterLoadStates };
  })
  .case(CaseActions.getCaseFilter.async.failed, state => {
    return {
      ...state
    };
  })
  .case(CaseActions.getCaseFilter.async.done, (state, action) => {
    let index = state.criteriaFilter.filterForms.findIndex(
      criteriaForm => criteriaForm.key === action.params.key
    );
    state.criteriaFilter.filterForms[index].formDefinition.fieldGroups = [
      action.result
    ];
    const { filterLoadStates } = state;
    filterLoadStates.set('case_status', LOAD_STATES.done);
    return {
      ...state,
      filterLoadStates
    };
  })
  .case(CaseActions.clearAllFilters, (state, action) => {
    return {
      ...state,
      filterLoadStates: InitialState.cases.filterLoadStates,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: state.criteriaFilter.filters.filter(filter =>
          ['primary_clinical_identifier', 'patient_mrn', 'id'].includes(
            filter.key
          )
        ),
        dateFilter: undefined
      },
      sortablePageable: {
        ...state.sortablePageable,
        filterableOptions: {
          ...state.sortablePageable.filterableOptions,
          filters: state.criteriaFilter.filters.filter(filter =>
            ['primary_clinical_identifier', 'patient_mrn', 'id'].includes(
              filter.key
            )
          ),
          dateFilter: undefined
        }
      }
    };
  })

  .case(CaseActions.applyFilters.async.started, (state, action) => {
    const { filterLoadStates } = state;
    filterLoadStates.set('criteriaFilter', LOAD_STATES.started);
    return { ...state, filterLoadStates };
  })
  .case(CaseActions.applyFilters.async.done, (state, action) => {
    const { filterLoadStates } = state;
    filterLoadStates.set('criteriaFilter', LOAD_STATES.done);

    return {
      ...state,
      criteriaFilter: {
        ...state.criteriaFilter,
        filters: action.result
      },
      filterLoadStates
    };
  })

  .case(CaseActions.applyDateFilter.async.started, (state, action) => {
    const { filterLoadStates } = state;
    filterLoadStates.set('applyDateFilter', LOAD_STATES.started);
    return {
      ...state,
      filterLoadStates
    };
  })
  .case(CaseActions.applyDateFilter.async.done, (state, action) => {
    state.criteriaFilter.dateFilter = action.result;
    const { filterLoadStates } = state;
    filterLoadStates.set('applyDateFilter', LOAD_STATES.done);
    return {
      ...state,
      filterLoadStates
    };
  })
  .case(CaseActions.getBillingGroup.async.started, (state, action) => {
    return {
      ...state,
      billingGroupLoadState: LOAD_STATES.initial,
      groupedCases: InitialState.cases.groupedCases
    };
  })
  .case(CaseActions.getBillingGroup.async.done, (state, { result }) => {
    const { activeItem } = state;
    const groupedCases = caseUtils.getGroupedCases(
      activeItem,
      result.content,
      'billing'
    );
    return {
      ...state,

      billingGroupLoadState: LOAD_STATES.done,
      groupedCases
    };
  })
  .case(CaseActions.getDetail.async.started, state => {
    const detailLoadState = loadStateClaim(LOAD_STATES.started, 'detail');

    return {
      ...state,
      ...cleanDetail,
      activations: { ...InitialState.cases.activations },
      stages: [],
      groupedCases: { ...DefaultGroupedCases },
      loadState: detailLoadState,
      artifactLoadState: LOAD_STATES.initial,
      formErrors: formErrors.noErrors,
      caseRuns: {},
      htmlNotes: []
    };
  })
  .case(CaseActions.getDetail.async.failed, (state, { error }) => {
    const isDetailLoading =
      isLoadingClaim(state.loadState) && state.loadState.claim === 'detail';
    const loadState = isDetailLoading
      ? loadStateClaim(LOAD_STATES.failed, 'detail')
      : { ...state.loadState };

    return {
      ...state,
      ...cleanDetail,
      codeEdits: InitialState.cases.codeEdits,
      codeDescriptions: InitialState.cases.codeDescriptions,
      loadState,
      formErrors: error.errors
    };
  })
  .case(
    CaseActions.getSubmitterProcess.async.done,
    (state, { result: submitterCase }) => {
      if (!submitterCase) {
        return { ...state };
      }

      const { simplifiedInvoices: originalInvoices } = state;
      const submittedItem = caseUtils.getCaseRun(submitterCase);
      const simplifiedInvoices = cmxArtifacts.handleImportedCases(
        originalInvoices,
        submittedItem,
        submitterSet,
        originalSet
      );

      return {
        ...state,
        submittedItem,
        simplifiedInvoices,
        artifactLoadState: LOAD_STATES.done
      };
    }
  )
  .case(CaseActions.getDetail.async.done, (state, { result: caseDetail }) => {
    const { submittedItem } = state;
    const apiResponse: CMx.ApiResponse<CMxAPI.Case> = { ...state.apiResponse };
    apiResponse.activeItem = caseDetail;

    const caseRun = caseUtils.getCaseRun(caseDetail);
    const artifactLoadState =
      caseRun.submitter_process === false
        ? LOAD_STATES.initial
        : LOAD_STATES.done;

    const activeItem = cmxArtifacts.preprocessor(caseRun);
    const codeEdits = vitalware.parseCodeEdits(activeItem);
    // build evidence manager so we can get notes
    const { artifactShowAll, caseRuns, roles } = state;
    const artifactSet = userUtils.isCoder(roles)
      ? CODER_SET
      : getArtifactSet(artifactShowAll, activeItem);

    const notes = getNotes(activeItem);
    const isDetailLoading =
      isLoadingClaim(state.loadState) && state.loadState.claim === 'detail';
    const loadState = isDetailLoading
      ? loadStateClaim(LOAD_STATES.done, 'detail')
      : { ...state.loadState };

    const sortedCaseRuns = sortCaseRuns(caseDetail, caseRuns);

    let initialInvoices =
      chargeConverters.simplifiedInvoices(caseRun, true, {
        ASSEMBLED: codeEdits
      }) ?? [];

    // not all caseRuns have artifacts.
    if (caseRun.artifacts?.length) {
      // For Radv2
      // convert artifacts to invoices so that we have something to display on the UI.
      const artifacts = caseRun.artifacts.filter(
        artifact => artifact.type === CaseArtifactTypes.PREDICTION
      );
      const convertedInvoices = cmxArtifacts.artifactToSimplifiedInvoice(
        artifacts
      );
      initialInvoices = [...initialInvoices, ...convertedInvoices];
    }
    // handle rolled units.
    if (submittedItem) {
      initialInvoices = cmxArtifacts.handleImportedCases(
        initialInvoices,
        submittedItem,
        submitterSet,
        originalSet
      );
    }

    let simplifiedInvoices = initialInvoices
      .filter(invoice => artifactSet.has(invoice.type))
      .sort(cmxArtifacts.sortInvoices);

    simplifiedInvoices = addDescriptionsToSimplifiedInvoices(
      simplifiedInvoices || [],
      state.codeDescriptions
    );

    return {
      ...state,
      artifactLoadState,
      apiResponse,
      activeItem,
      codeEdits,
      notes: state.notes.concat(notes),
      artifactSet,
      loadState,
      simplifiedInvoices,
      formErrors: formErrors.noErrors,
      caseRuns: sortedCaseRuns
    };
  })
  .case(CaseActions.getTenantFilter.async.started, (state, params) => {
    const { filterLoadStates } = state;
    filterLoadStates.set(FilterKeys.TENANT, LOAD_STATES.started);
    return { ...state, filterLoadStates };
  })
  .case(CaseActions.getTenantFilter.async.done, (state, { result }) => {
    let index = state.criteriaFilter.filterForms.findIndex(
      criteriaForm => criteriaForm.key === FilterKeys.TENANT
    );

    const form = state.criteriaFilter.filterForms[index];
    if (
      form.formDefinition.fieldGroups !== undefined &&
      form.formDefinition.fieldGroups?.length > 0
    ) {
      form.formDefinition.fieldGroups[0].fields = result;
    }

    const { filterLoadStates } = state;
    filterLoadStates.set(FilterKeys.TENANT, LOAD_STATES.done);

    return { ...state, filterLoadStates };
  })
  .case(CaseActions.applyDefaultFilters, (state, context) => {
    let { criteriaFilter, sortablePageable } = state;

    criteriaFilter.dateFilter = {
      key: `date_of_service`,
      type: `BETWEEN`,
      from: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE, {
        throw: false,
        localized: true,
        offset: {
          action: cmxDateTime.DateManipulateOperation.SUBTRACT,
          delta: [2, cmxDateTime.DateManipulateUnit.DAY]
        }
      }),
      to: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    };

    sortablePageable.filterableOptions.dateFilter = {
      key: `date_of_service`,
      from: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE, {
        throw: false,
        localized: true,
        offset: {
          action: cmxDateTime.DateManipulateOperation.SUBTRACT,
          delta: [2, cmxDateTime.DateManipulateUnit.DAY]
        }
      }),
      to: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    };

    let freshFilterForms = [];

    const isProfServiceContext = context.activeContext?.resources.some(
      resource => {
        return resource.resourceName === 'power_features';
      }
    );

    if (isProfServiceContext) {
      freshFilterForms = criteriaFilter.filterForms.map(filterForm => {
        if (filterForm.key === 'customer_phase') {
          return {
            ...filterForm,
            disabled: false
          };
        }
        return filterForm;
      });
    } else {
      freshFilterForms = criteriaFilter.filterForms.map(filterForm => {
        if (filterForm.key === 'customer_phase') {
          return {
            ...filterForm,
            disabled: true
          };
        }
        return filterForm;
      });

      const filterGoLive = {
        key: 'customer_phase',
        terms: [CustomerPhase.LIVE],
        type: 'IN'
      };

      if (sortablePageable.filterableOptions.filters !== undefined) {
        sortablePageable.filterableOptions.filters.push(filterGoLive);
      } else {
        sortablePageable.filterableOptions.filters = [filterGoLive];
      }

      criteriaFilter.filters.push(filterGoLive);
    }

    return {
      ...state,
      criteriaFilter: { ...criteriaFilter, filterForms: freshFilterForms },
      sortablePageable
    };
  })
  .case(uiActions.chooseContext, (state, action) => {
    return { ...state, context: { ...action }, roles: buildRoleSet(action) };
  })
  .case(CaseActions.showProcessingTimeline, (state, data) => {
    return {
      ...state,
      displayTimeline: data
    };
  })
  .case(CaseActions.setActiveProcess.async.started, (state, payload) => {
    const { caseRun } = payload;
    const { codeEdits, roles, artifactShowAll } = state;

    const artifactSet = userUtils.isCoder(roles)
      ? CODER_SET
      : getArtifactSet(artifactShowAll, caseRun);

    const firstPass = chargeConverters.simplifiedInvoices(caseRun, true, {
      ASSEMBLED: codeEdits
    });

    const simplifiedInvoices = firstPass
      .filter(invoice => artifactSet.has(invoice.type))
      .sort(cmxArtifacts.sortInvoices);

    return {
      ...state,
      simplifiedInvoices,
      activeItem: caseRun
    };
  })
  .case(
    CaseActions.caseOrganizationContext,
    (state, caseOrganizationContext) => {
      return {
        ...state,
        caseOrganizationContext
      };
    }
  )
  .case(CaseActions.navigateDetail.async.started, (state, caseRun) => {
    return {
      ...state,
      activeItem: caseRun
    };
  })
  .case(ServiceLineActions.navigateServiceLine.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.initial
    };
  })
  .case(
    CaseActions.getDocumentsAsHtml.async.done,
    (state, { result: htmlNotes }) => {
      const newNotes = [...htmlNotes]
        .filter(note => note.document_id !== null)
        .map(documentUtils.shimSegmentsIfNeeded)
        .sort((a, b) => {
          return a.document_id - b.document_id;
        });

      return {
        ...state,
        notes: newNotes
      };
    }
  )
  .case(docMetadataAvailable, (state, docs) => {
    return {
      ...state,
      documentMetadata: docs.sort((a, b) => {
        return a.documentId - b.documentId;
      })
    };
  })
  .case(replaceUIState, (state, uiState) => {
    const roles = roleShim(uiState?.context, state.roles);
    return {
      ...state,
      roles
    };
  })
  .case(CaseActions.getShowOrderNumber.async.started, state => {
    return {
      ...state,
      loadState: LOAD_STATES.started
    };
  })
  .case(CaseActions.getShowOrderNumber.async.done, (state, { result }) => {
    return {
      ...state,
      showOrderNumber: result?.value ? JSON.parse(result?.value) : false,
      loadState: LOAD_STATES.done
    };
  });

export default reducer;
