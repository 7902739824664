import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  CellClickedEvent,
  RowClickedEvent,
  GridOptions,
  RowSelectedEvent,
  SelectionChangedEvent,
  ColDef,
  GridApi,
  GridReadyEvent
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { Grid } from '@chakra-ui/react';
import { gridStyles, styles } from './data-grid.styles';
import { EndpointData, getDataSource } from './data-grid-utils';
import { useDispatch } from 'react-redux';
import { CustomLoadingCellRenderer } from './custom-loading-cell-renderer';

export type DataGridProps<TData = any> = {
  rowData?: TData[];
  columnDefs: ColDef[];
  onRowClicked?: (e: RowClickedEvent) => void;
  onCellClicked?: (e: CellClickedEvent<TData, TData>) => void;
  onRowSelected?: (e: RowSelectedEvent) => void;
  onSelectionChanged?: (e: SelectionChangedEvent) => void;
  autoSizeColumns?: boolean;
  gridOptions?: GridOptions;
  serverSideInfiniteScroll?: boolean;
  paginationPageSize?: number;
  cacheBlockSize?: number;
  filterableOptions?: CMxCommonApp.FilterableOption;
  contentFilter?: Function;
  endpointData?: EndpointData;
  loaderInfo?: string;
  bodyObject?: object;
  customHeader?: any;
};

const DataGrid = ({
  rowData,
  columnDefs,
  onRowClicked,
  onSelectionChanged,
  onCellClicked,
  onRowSelected,
  gridOptions,
  serverSideInfiniteScroll,
  paginationPageSize,
  cacheBlockSize,
  filterableOptions,
  contentFilter,
  endpointData,
  loaderInfo,
  bodyObject,
  customHeader
}: DataGridProps) => {
  const classes = styles();
  const [gridApi, setGridApi] = useState<GridApi>();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (filterableOptions || bodyObject) &&
      endpointData?.endPoint &&
      serverSideInfiniteScroll
    ) {
      gridApi?.setCacheBlockSize(paginationPageSize ?? 25);
      gridApi?.paginationSetPageSize(paginationPageSize ?? 25);
      const datasource = getDataSource(
        endpointData,
        filterableOptions,
        paginationPageSize ?? 25,
        dispatch,
        contentFilter,
        loaderInfo,
        bodyObject
      );
      gridApi?.setServerSideDatasource(datasource);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterableOptions,
    gridApi,
    paginationPageSize,
    serverSideInfiniteScroll,
    contentFilter,
    bodyObject
  ]);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  useEffect(() => {
    if (gridApi && rowData) {
      gridApi.setRowData(rowData);
    }
  }, [gridApi, rowData]);

  const agGridOptions: GridOptions = {
    columnDefs: columnDefs,
    defaultColDef: {
      flex: 1,
      lockPinned: true,
      sortable: true
    },
    components: {
      agColumnHeader: customHeader
    },
    suppressMovableColumns: true,
    suppressCellFocus: true,
    suppressHorizontalScroll: true,
    pagination: true,
    paginationPageSize: paginationPageSize ?? 25,
    rowHeight: 30,
    headerHeight: 30,
    rowModelType: serverSideInfiniteScroll ? 'serverSide' : 'clientSide',
    serverSideInfiniteScroll: serverSideInfiniteScroll ?? false,
    cacheBlockSize: cacheBlockSize ?? 25,
    multiSortKey: 'ctrl',
    ...gridOptions
  };

  const gridRef = useRef<AgGridReact>(null);

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.setColumnDefs(columnDefs);
    }
  }, [columnDefs]);

  const loadingCellRenderer = useMemo(() => {
    return CustomLoadingCellRenderer;
  }, []);

  return (
    <Grid css={gridStyles} sx={classes.root}>
      <AgGridReact
        rowData={rowData}
        ref={gridRef}
        gridOptions={agGridOptions}
        onGridReady={onGridReady}
        domLayout="autoHeight"
        onRowClicked={onRowClicked}
        onRowSelected={onRowSelected}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        className={'ag-theme-alpine'}
        loadingCellRenderer={loadingCellRenderer}
        pagination={true}
        paginationPageSize={paginationPageSize}
      />
    </Grid>
  );
};

const MemoizedDataGrid = React.memo(DataGrid);
export { MemoizedDataGrid as DataGrid };
