/// <reference path="../types.d.ts" />

import { push } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';

import { api } from '../core/net';
import { showFeedback } from './ui';
import { goBack } from 'connected-react-router';
import { Group } from './action-types';
import { Dispatch } from 'redux';
import { HttpMethods } from '@codametrix/ui-common';

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.Group>(actionCreator);

const chooseGroup = actionCreator<CMxAPI.Group>(Group.CHOOSE_GROUP);
const listEligibleGroupMembers = actionCreator<CMxAPI.GroupUser[]>(
  Group.LIST_GROUP_MEMBERS
);

const listItems = createAsync<
  AppProps.listParams,
  CMxAPI.PageableList<CMxAPI.Group>,
  CMxCommonApp.SubmitError
>(Group.LIST, async params => {
  const pageableList = await api<CMxAPI.PageableList<CMxAPI.Group>>({
    endpoint: `/users/groups/tenant/v1?&tenantId=${params.id}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return pageableList;
});

const dispatchGetGroupMemberInfo = async (
  dispatch: Dispatch,
  group: CMxAPI.Group
) => {
  const groupId = group.id;

  const eligibleMembers = await api<CMxAPI.GroupUser[]>({
    endpoint: `/users/groups/${groupId}/users/v1?eligibleMembers=true`,
    init: {
      method: HttpMethods.GET
    }
  });

  dispatch(listEligibleGroupMembers(eligibleMembers));
};

const getGroupDetail = createAsync<
  AppProps.actionParams<CMxAPI.Group>,
  CMxAPI.Group,
  CMxCommonApp.SubmitError
>(Group.FETCH_DETAIL, async (params, dispatch: any) => {
  const { data: group, path } = params;
  const id = group.id;

  if (id === undefined || id === null) {
    return {} as CMxAPI.Group;
  }

  const detail = await api<CMxAPI.Group>({
    endpoint: `/users/groups/${id}/users/v1`
  });

  dispatch(chooseGroup(group));
  dispatch(push(path));
  dispatchGetGroupMemberInfo(dispatch, group);

  return detail;
});

const addGroupMember = createAsync<
  AppProps.addGroupMemberParams,
  CMxAPI.GroupUser,
  CMxCommonApp.SubmitError
>(Group.ADD_GROUP_USER, async (params, dispatch: any) => {
  const { groupId, groupMembers } = params;

  const saved = await api<CMxAPI.GroupUser>({
    endpoint: `/users/groups/${groupId}/users/v1`,
    init: {
      method: HttpMethods.POST
    },
    body: groupMembers
  });

  return saved;
});

const saveGroup = createAsync<CMxAPI.Group, void, CMxCommonApp.SubmitError>(
  Group.SAVE,
  async (group, dispatch) => {
    group = {
      ...group,
      active: true
    };

    // Updating group not supported on backend yet
    const isUpdate = group.id != null;
    let method: string = isUpdate ? HttpMethods.PUT : HttpMethods.POST;
    let endpoint: string = isUpdate
      ? `/users/groups/${group.id}/v1`
      : `/users/groups/v1`;

    await api<CMxAPI.Group>({
      endpoint,
      init: {
        method
      },
      body: group
    });

    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Group Saved.`,
        dismissable: true,
        className: 'feedback-working'
      })
    );
    dispatch(goBack());
    return;
  }
);

const deleteGroup = createAsync<number, void, CMxCommonApp.SubmitError>(
  Group.DELETE,
  async (groupId, dispatch) => {
    const group = {
      id: groupId
    };

    let method: string = HttpMethods.DELETE;

    await api<CMxAPI.Group>({
      endpoint: `/users/groups/${groupId}/v1`,
      init: {
        method
      },
      body: group
    });

    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Group Deleted.`,
        dismissable: true,
        className: 'feedback-working'
      })
    );
    dispatch(goBack());
    return;
  }
);

export const groupActions = {
  chooseGroup,
  listItems,
  getGroupDetail,
  saveGroup,
  deleteGroup,
  listEligibleGroupMembers,
  addGroupMember
};
