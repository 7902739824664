import React from 'react';
import theme from '../../theme';
import { ReactComponent as AdminIcon } from '../../assets/images/settings-36px.svg';
import { ReactComponent as CaseIcon } from '../../assets/images/cases-36px.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/images/reports-36px.svg';
import { ReactComponent as UserPlus } from '../../assets/images/providers-36px.svg';
import { ReactComponent as CoderQueueIcon } from '../../assets/images/coders-and-queues-36px.svg';
import { ReactComponent as WorklistIcon } from '../../assets/images/worklist-36px.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/search-36px.svg';
import { ReactComponent as QualityAssessment } from '../../assets/images/quality-assessment-36px.svg';

export const navComponents: { [key: string]: JSX.Element } = {
  Admin: <AdminIcon width={theme.space[36]} height={theme.space[36]} />,
  Cases: <CaseIcon width={theme.space[36]} height={theme.space[36]} />,
  Analytics: <AnalyticsIcon width={theme.space[36]} height={theme.space[36]} />,
  Insights: <AnalyticsIcon width={theme.space[36]} height={theme.space[36]} />,
  'Provider Directory': (
    <UserPlus width={theme.space[36]} height={theme.space[36]} />
  ),
  'Queue Settings': (
    <CoderQueueIcon width={theme.space[36]} height={theme.space[36]} />
  ),
  'Coding Quality': (
    <UserPlus width={theme.space[36]} height={theme.space[36]} />
  ),
  'Quality Assessment': (
    <QualityAssessment width={theme.space[36]} height={theme.space[36]} />
  ),
  Worklist: <WorklistIcon width={theme.space[36]} height={theme.space[36]} />,
  Search: <SearchIcon width={theme.space[36]} height={theme.space[36]} />
};
