/// <reference path="../../types.d.ts"" />

import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { buildDispatchable } from '../../actions/_action-utilities';

import { DashboardSelection } from './dashboard-selection';
import { setActiveDashboard } from '../../actions/analytics';

const mapStateToProps = (state: CMx.ApplicationState) => {
  const { analytics } = state;
  return {
    activeDashboard: analytics.activeDashboard
  };
};

const mapDispatchToProps = (dispatch: any) => {
  const runDispatchable = buildDispatchable(dispatch);

  return {
    handleDashboardSelection: (boardName: string) => {
      runDispatchable(() => {
        dispatch(setActiveDashboard(boardName.split(' ')[0].toLowerCase()));
        return dispatch(push(`/cmx/tableau/`));
      });
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardSelection);
