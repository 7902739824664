import React, { useState } from 'react';
import { Icon } from '@codametrix/ui-components';
import './button-toggle.scss';

export type ButtonToggleProps = {
  active?: boolean;
  label: string;
  style?: Object;
  onSelect: (state: boolean) => void;
};

const ButtonToggle = (props: ButtonToggleProps) => {
  const [activeState, setActiveState] = useState(false);
  const byProps = typeof props?.active !== 'undefined';
  const active = byProps ? props.active : activeState;

  const selectToggle = (ev: React.MouseEvent) => {
    ev.preventDefault();
    if (!byProps) {
      setActiveState(!activeState);
    }
    props.onSelect(props?.active ? props?.active : activeState);
  };

  return (
    <>
      <span
        style={props?.style ?? {}}
        className="button-toggle"
        onClick={selectToggle}>
        <div className={`button-toggle-insider ${active ? 'active' : ''}`}>
          <Icon
            svgIconName="BiomeCheckSVG20"
            svgIconSize="12"
            svgIconClass={`check ${active ? 'active' : ''}`}
          />
          <p className="button-toggle-label">{props.label}</p>
        </div>
      </span>
    </>
  );
};

ButtonToggle.displayName = 'ButtonToggle';

export { ButtonToggle };
