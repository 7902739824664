/// <reference path="../types.d.ts" />

import { push, replace } from 'connected-react-router';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import {
  DictionaryValues,
  DictionaryUpload,
  ColumnMetadataEditor,
  ArchiveModal
} from './action-types';

import {
  showFeedback,
  dismissFeedback,
  showFeedbackError,
  showFeedbackWarning
} from './ui';
import { filterStatusFields } from '../reducers/state/dictionary-values';
import { ActionToast } from '../views/ui/action-toast';
import { commonEnums, HttpMethods, cmxErrors } from '@codametrix/ui-common';
import { isNewDesign } from '../core/middleware/new-design-middleware';
import { cmxDateTime } from '@codametrix/ui-common';
import { AmplifyCore } from '@codametrix/ui-common';

const { paginationUtils } = AmplifyCore;

const { BadRequestError } = cmxErrors;
const { DictionaryStatus, FilterTypes } = commonEnums;

const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMxAPI.DictionaryRow>(actionCreator);

const chooseRow = actionCreator<CMxAPI.DictionaryRow>(
  DictionaryValues.CHOOSE_ROW
);

const chooseColumnReference = actionCreator<CMxAPI.ColumnDefinition>(
  DictionaryUpload.CHOOSE_COL_REFERENCE
);

const removeColumnMetadata = actionCreator<CMxAPI.ColumnDefinitionMetadata>(
  DictionaryUpload.REMOVE_COLUMN_METADATA
);

const setStatusFilter = actionCreator<string>(
  DictionaryValues.SET_STATUS_FILTER
);

const selectKeyColumn = actionCreator<number>(
  ColumnMetadataEditor.SELECT_KEY_COLUMN
);

const confirmMappingsExist = actionCreator<boolean>(
  ColumnMetadataEditor.CONFIRM_MAPPINGS_EXISTS
);

const setDictionaryMappings = actionCreator<CMxAPI.ColumnDefinitionMetadata[]>(
  ColumnMetadataEditor.SET_DICTIONARY_MAPPINGS
);

const selectActiveColumn = actionCreator<CMxAPI.ColumnDefinition>(
  ColumnMetadataEditor.SELECT_ACTIVE_COLUMN
);

const removeColumnMapping = actionCreator<CMxAPI.ColumnDefinition>(
  ColumnMetadataEditor.REMOVE_COLUMN_MAPPING
);

const saveColumnMapping = actionCreator<CMx.saveColumnMappingPayload>(
  ColumnMetadataEditor.SAVE_COLUMN_MAPPING
);

const toggleArchiveModal = actionCreator<boolean>(
  ArchiveModal.TOGGLE_ARCHIVE_MODAL
);

const setArchiveChildren = actionCreator<boolean>(
  ArchiveModal.ARCHIVE_CHILDREN
);

const savePotentialArchives = actionCreator<CMxAPI.DictionaryRow[]>(
  ArchiveModal.SAVE_POTENTIAL_ARCHIVES
);

const toggleDeleteModal = actionCreator<boolean>(
  DictionaryValues.TOGGLE_DELETE_MODAL
);

const toggleUploadType = actionCreator<boolean>(
  DictionaryUpload.TOGGLE_UPLOAD_TYPE
);

const toggleInfoModal = actionCreator<boolean>(
  DictionaryValues.TOGGLE_INFO_MODAL
);

const toggleSecurityModal = actionCreator<boolean>(
  DictionaryValues.TOGGLE_SECURITY_MODAL
);

const updateDictionaryValue = actionCreator<AppProps.DefaultStateData>(
  DictionaryValues.UPDATE_COLUMN_DEFS
);

//We want to use async so  we can get a loadstate change to cause a rerender on the form component
const initUpload = createAsync<
  CMxAPI.Dictionary,
  void,
  CMxCommonApp.SubmitError
>(DictionaryUpload.UPLOAD_INIT, async () => {
  return;
});

/** async to reload form, thus allowing the forms state to refresh */
const addColumnMetadata = createAsync<
  CMxAPI.ColumnDefinitionMetadata,
  CMxAPI.ColumnDefinitionMetadata,
  CMxCommonApp.SubmitError
>(DictionaryUpload.ADD_COLUMN_METADATA, async (value, dispatch: any) => {
  return value;
});

const loadValue = createAsync<
  AppProps.actionParams<number>,
  number,
  CMxCommonApp.SubmitError
>(DictionaryValues.LOAD_VALUE, async (params, dispatch: any) => {
  const { path, data: rowId } = params;

  dispatch(push(path + `/row/${rowId}/edit`));
  return rowId;
});

export const shimDraftFilter = (filters: CMxCommonApp.Filter[]) => {
  const shimmedFilters = filters.filter(
    filter => !['edit_status', 'status'].includes(filter.key)
  );

  shimmedFilters.push(
    { key: 'status', terms: ['draft', 'published'], type: 'IN' },
    {
      key: 'edit_status',
      terms: ['ADD', 'UPDATE', null],
      type: 'IN'
    } as any
  );
  return shimmedFilters;
};

const getDraftFilters = async (
  filters: CMxCommonApp.Filter[],
  dictionaryId: number
) => {
  const isDraft = filters.filter((f: any) => {
    const isEqual = f.terms.includes('draft');
    return isEqual;
  }).length;
  if (!isDraft) return null;
  const deleteAndUpdateRowSearch = api<
    CMxAPI.PageableList<CMxAPI.DictionaryRow>
  >({
    endpoint: `/dictionary/${dictionaryId}/row/v2?page=0&size=1&listType=column`,
    init: {
      method: HttpMethods.POST
    },
    body: [
      {
        key: 'status',
        terms: ['published'],
        type: 'IN'
      },
      {
        key: 'edit_status',
        terms: ['DELETE', 'UPDATE'],
        type: 'IN'
      }
    ]
  });

  const draftRowSearch = api<CMxAPI.PageableList<CMxAPI.DictionaryRow>>({
    endpoint: `/dictionary/${dictionaryId}/row/v2?page=0&size=1&listType=column`,
    init: {
      method: HttpMethods.POST
    },
    body: [
      {
        key: 'status',
        terms: ['draft'],
        type: 'IN'
      }
    ]
  });
  const [deletedAndUpdateRows, draftRows] = await Promise.all([
    deleteAndUpdateRowSearch,
    draftRowSearch
  ]);
  if (
    deletedAndUpdateRows.content.length !== 0 ||
    draftRows.content.length !== 0
  ) {
    const shimmedDraftFilters = shimDraftFilter(filters);
    return shimmedDraftFilters;
  }
  return null;
};

export const getDictionaryFilters = async (
  dictionaryId: number,
  sortablePageable: CMxCommonApp.SortablePageable<any>
) => {
  const {
    filterableOptions: { filters = [] }
  } = sortablePageable;

  const statusTerm = filters.find(f => f.key === 'status')?.terms[0];

  // Filtering for Deleted Rows, Added Rows, Or updated rows requires shimming in an editStatus filter.
  const shimEditStatusFilter = filterStatusFields.find(
    field => field.key === statusTerm
  )?.transform;

  let shimmedFilters: CMxCommonApp.Filter[] = filters;
  if (shimEditStatusFilter) {
    shimmedFilters = shimEditStatusFilter(filters);
  }

  let shimmedDraftFilters: CMxCommonApp.Filter[] | null = shimmedFilters;

  const draftFilters = await getDraftFilters(filters, dictionaryId);
  if (draftFilters) shimmedDraftFilters = draftFilters;
  return shimmedDraftFilters;
};

const checkIfKeyColumnPresent = (
  dictionary: CMxAPI.Dictionary,
  dispatch: any
) => {
  const hasKeyColumn: boolean =
    dictionary.columnDefinitions.find(col => {
      return col.keyColumnIndicator === true;
    }) !== undefined;

  if (!hasKeyColumn) {
    dispatch(
      showFeedbackWarning({
        message: ActionToast({
          actionText: 'here',
          message: `Key column not set. Please select one`,
          onAction: (path: string) => {
            dispatch(push(`${path}/metadata/keyColumn`));
            dispatch(dismissFeedback(true));
          }
        }),
        meta: { level: 'WARNING' },
        dismissable: true,
        className: 'feedback-error'
      })
    );
  }
};
/**
 * Listing draft rows may not include all the rows of a draft dictionary.
 * Some may be a part of the existing published dictionary.
 * Therefore we need to check to see if any rows exist in the published dictionary with editStatus ADD/UPDATE/DELETE
 **/
const listItems = createAsync<
  {
    dictionary: CMxAPI.Dictionary;
    sortablePageable: CMxCommonApp.SortablePageable<any>;
  },
  CMxAPI.PageableList<CMxAPI.DictionaryRow>,
  CMxCommonApp.SubmitError
>(DictionaryValues.LIST_VALUES, async (params, dispatch) => {
  const { dictionary, sortablePageable } = params;

  var searchParams = paginationUtils.searchParamsFactory(sortablePageable);

  const filters = await getDictionaryFilters(dictionary.id, sortablePageable);
  let pageableList = await api<CMxAPI.PageableList<CMxAPI.DictionaryRow>>({
    endpoint: `/dictionary/${
      dictionary.id
    }/row/v2?${searchParams.toString()}&listType=column`,
    init: {
      method: HttpMethods.POST
    },
    body: filters || []
  });

  return pageableList;
});

const loadDictionary = createAsync<
  AppProps.listParams,
  CMxAPI.Dictionary,
  CMxCommonApp.SubmitError
>(DictionaryValues.LOAD_DICTIONARY, async (params, dispatch) => {
  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Loading dictionary values...`,
      dismissable: false,
      className: 'feedback-working'
    })
  );

  const { id, sortablePageable } = params;

  const dictionary = await api<CMxAPI.Dictionary>({
    endpoint: `/dictionary/${id}/filters/v2?pageSize=0`,
    init: {
      method: HttpMethods.POST
    },
    body: []
  });
  dispatch(initializeColumnMetadataEditor(dictionary));
  !isNewDesign && dispatch(listItems({ dictionary, sortablePageable }));
  checkIfKeyColumnPresent(dictionary, dispatch);
  dispatch(dismissFeedback(true));

  return dictionary;
});

const saveDictionaryRow = createAsync<
  CMxAPI.DictionaryRowDto,
  CMxAPI.DictionaryRowDto,
  CMxCommonApp.SubmitError
>(DictionaryValues.SAVE_VALUE, async (value, dispatch: any) => {
  const endpoint = `/dictionary/row/v2`;

  const res = await api<CMxAPI.DictionaryRowDto>({
    endpoint: endpoint,
    init: {
      method: value.id ? HttpMethods.PUT : HttpMethods.POST
    },
    body: value
  });

  return res;
});

const listDictionaryOptions = createAsync<
  { tenantId: any; orgName: string },
  CMxAPI.DictionaryOptionsObj,
  CMxCommonApp.SubmitError
>(DictionaryUpload.LIST_DICTIONARIES, async (params, dispatch) => {
  const pageableList = await api<CMxAPI.PageableList<CMxAPI.Dictionary>>({
    endpoint: `/dictionary/v2?tenantId=${
      params.tenantId
    }&sort=name%2CASC&size=1000&&derivedIndicator=false&uniqueByName=true&status=${DictionaryStatus.PUBLISHED.toUpperCase()}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return { pageableList: pageableList, organizationName: params.orgName };
});

const listColumnOptions = createAsync<
  {
    tenantId: string;
    dictionaryName: string;
  },
  CMxAPI.ColumnDefinition[],
  CMxCommonApp.SubmitError
>(DictionaryUpload.LIST_COLUMNS, async (params, dispatch) => {
  const dateStr = cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE);
  const pageableList = await api<CMxAPI.ColumnDefinition[]>({
    endpoint: `/dictionary/${params.dictionaryName}/column-definition/v2?derivedIndicator=false&effectiveDateStr=${dateStr}&tenantId=${params.tenantId}`,
    init: {
      method: HttpMethods.GET
    }
  });

  return pageableList;
});

//export for testing
export const getPublishableDictionaryIds = (
  associatedDictionaries: CMxAPI.Dictionary[]
): [number | undefined, number | undefined] => {
  const publishedDictionaryId = associatedDictionaries.find(dict => {
    return dict.status === DictionaryStatus.PUBLISHED.toUpperCase();
  })?.id;

  let draftDictionaryId = associatedDictionaries.find(dict => {
    return dict.status === DictionaryStatus.DRAFT.toUpperCase();
  })?.id;

  if (publishedDictionaryId && draftDictionaryId) {
    return [draftDictionaryId, publishedDictionaryId];
  }

  if (publishedDictionaryId && !draftDictionaryId) {
    return [publishedDictionaryId, undefined];
  }
  return [draftDictionaryId, undefined];
};

const publishDictionary = createAsync<
  CMxAPI.Dictionary[],
  CMxAPI.DictionaryRequestResponse,
  CMxCommonApp.SubmitError
>(DictionaryValues.PUBLISH_DICTIONARY, async (assocDictionaries, dispatch) => {
  const dateStr = cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE);

  const [recentDictionaryId, optOldDictionaryId] = getPublishableDictionaryIds(
    assocDictionaries
  );

  let searchParams = new URLSearchParams();
  searchParams.set('effectiveDateStr', dateStr);

  if (optOldDictionaryId) {
    searchParams.set('oldDictionaryId', optOldDictionaryId.toString());
  }

  const uploadResponse = await api<CMxAPI.DictionaryRequestResponse>({
    endpoint: `/dictionary/${recentDictionaryId}/publish/v2?${searchParams.toString()}`,
    init: {
      method: HttpMethods.PUT
    }
  }).catch(e => {
    if (e instanceof BadRequestError) {
      dispatch(dismissFeedback(false));
      dispatch(
        showFeedbackError({
          id: Date.now(),
          message: `Dictionary Publish Unsuccessful`,
          dismissable: true
        })
      );
    }
    throw e;
  });

  const successRoute = window.location.pathname
    .split('/')
    .slice(0, 6)
    .join('/');

  const navParams = new URLSearchParams({
    requestId: uploadResponse.id.toString()
  });

  dispatch(push(successRoute + `/request?${navParams}`));

  return uploadResponse;
});

const downloadDictionaryByRequestID = createAsync<
  { requestId: string },
  void,
  CMxCommonApp.SubmitError
>(
  DictionaryValues.DOWNLOAD_DICTIONARY_BY_REQUEST_ID,
  async (payload, dispatch) => {
    const { requestId } = payload;

    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Downloading Dictionary. Please Wait`,
        dismissable: true,
        className: 'feedback-working'
      })
    );

    const endpoint = `/dictionary/request/${requestId}/csv/v2`;

    const response = await api<any>(
      {
        endpoint,
        headers: {
          'Content-Type': 'application/csv'
        }
      },
      false
    ).catch(e => {
      dispatch(dismissFeedback(false));
      dispatch(
        showFeedbackError({
          id: Date.now(),
          message: `Dictionary Download Unsuccessful`,
          dismissable: true
        })
      );
    });

    const filename = response.contentDisposition?.split('=')[1];

    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(response.blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${filename}`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    dispatch(
      showFeedback({
        id: Date.now(),
        message: `Dictionary Download Finished`,
        dismissable: true,
        className: 'feedback-working'
      })
    );
  }
);

const downloadDictionary = createAsync<
  CMx.dictionaryDownloadPayload,
  void,
  CMxCommonApp.SubmitError
>(DictionaryValues.DOWNLOAD_DICTIONARY, async (payload, dispatch) => {
  const {
    dictionaryName,
    tenantId,
    effectiveDate,
    updatedDate,
    withDraft
  } = payload;

  let searchParams = new URLSearchParams();
  searchParams.append('tenantId', tenantId);
  if (withDraft) {
    searchParams.append('withDraft', 'true');
  }
  if (effectiveDate) {
    searchParams.append(
      'effectiveDateStr',
      updatedDate
        ? updatedDate
        : cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    );
  }

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Downloading Dictionary. Please Wait`,
      dismissable: true,
      className: 'feedback-working'
    })
  );

  const endpoint = `/dictionary/${encodeURI(
    dictionaryName
  )}/csv/v2?${searchParams.toString()}`;

  const response = await api<any>(
    {
      endpoint,
      headers: {
        'Content-Type': 'application/csv'
      }
    },
    false
  ).catch(e => {
    dispatch(dismissFeedback(false));
    dispatch(
      showFeedbackError({
        id: Date.now(),
        message: `Dictionary Download Unsuccessful`,
        dismissable: true
      })
    );
  });

  const filename = response.contentDisposition?.split('=')[1];

  var link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    var url = URL.createObjectURL(response.blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${filename}`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Dictionary Download Finished`,
      dismissable: true,
      className: 'feedback-working'
    })
  );
});

const listAssociatedDictionaries = createAsync<
  CMxAPI.Dictionary,
  CMxAPI.Dictionary[],
  CMxCommonApp.SubmitError
>(
  DictionaryValues.LIST_ASSOCIATED_DICTIONARIES,
  async (dictionary, dispatch) => {
    const searchParams = new URLSearchParams();
    searchParams.set('tenantId', dictionary.tenantId.uuid);
    const dictionaries =
      (await api<CMxAPI.Dictionary[]>({
        endpoint: `/dictionary/${
          dictionary.name
        }/list/v2?${searchParams.toString()}`
      })) || [];

    return dictionaries;
  }
);

/**
 * Async function to get history of a dictionary
 * @param DictionaryValues.LIST_DICTIONARY_HISTORY
 * @param <Function>
 * @retuns
 */
const listDictionaryHistory = createAsync<
  Number,
  CMxAPI.DictionaryHistory[],
  CMxCommonApp.CMxError
>(DictionaryValues.LIST_DICTIONARY_HISTORY, async (dictionaryId, dispatch) => {
  // default row params
  const defaultRowParams = new URLSearchParams({
    page: '0',
    size: '200'
  });

  // create necessary params
  const rowBody = [
    {
      key: 'dictionary_id',
      terms: [dictionaryId],
      type: FilterTypes.EQ
    }
  ];

  // request to API
  const history = await api<{ content: CMxAPI.DictionaryHistory[] }>({
    endpoint: `/dictionary/request/v2?${defaultRowParams.toString()}`,
    init: {
      method: HttpMethods.POST
    },
    body: rowBody
  }).catch(e => {
    if (e instanceof BadRequestError) {
      dispatch(dismissFeedback(false));
      dispatch(
        showFeedbackError({
          id: Date.now(),
          message: `Getting History Dictionary Unsuccessful`,
          dismissable: true
        })
      );
    }
    throw e;
  });

  return history.content;
});

const initializeColumnMetadataEditor = createAsync<
  CMxAPI.Dictionary,
  CMxAPI.ColumnDefinition[],
  CMxCommonApp.CMxError
>(ColumnMetadataEditor.INITIALIZE, async (dictionary, dispatch) => {
  const searchParams = new URLSearchParams();
  searchParams.append('tenantId', dictionary.tenantId.uuid);
  searchParams.append('derivedIndicator', 'false');
  if (dictionary.effectiveDate && dictionary.status !== 'PUBLISHED') {
    searchParams.append('effectiveDateStr', dictionary.effectiveDate);
  } else if (dictionary.status !== 'DRAFT') {
    searchParams.append(
      'effectiveDateStr',
      dictionary.updatedDate ??
        cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    );
  }
  const cols = await api<CMxAPI.ColumnDefinition[]>({
    endpoint: `/dictionary/${
      dictionary.name
    }/column-definition/v2?${searchParams.toString()}`
  });

  const hasKeyColumn = cols?.some(col => col.keyColumnIndicator === true);

  if (!hasKeyColumn && cols.length > 0) {
    cols[0].keyColumnIndicator = true;
  }
  return cols;
});

const saveColumnDefinitions = createAsync<
  CMx.dictionaryMetadataSavePayload,
  CMxAPI.Dictionary,
  CMxCommonApp.CMxError
>(ColumnMetadataEditor.SAVE_COLUMN_METADATA, async (payload, dispatch) => {
  //Column definition save call

  const { columnDefinitions, succesPath, dictionaryId } = payload;

  const res = await api<CMxAPI.DictionaryRequestError>({
    endpoint: `/dictionary/${dictionaryId}/column-definition/v2`,
    init: {
      method: HttpMethods.PUT
    },
    body: columnDefinitions
  });

  if (res && res.status === 400) {
    dispatch(
      showFeedbackError({
        id: Date.now(),
        message: `Metadata Save Unsuccessful!`,
        dismissable: true
      })
    );
    throw res;
  }

  const updatedDictionary = await api<CMxAPI.Dictionary>({
    endpoint: `/dictionary/${dictionaryId}/filters/v2?pageSize=0`,
    init: {
      method: HttpMethods.POST
    },
    body: []
  });

  dispatch(push(succesPath));
  dispatch(
    showFeedback({
      id: Date.now(),
      message: `Metadata saved succesfully`,
      dismissable: true,
      className: 'feedback-working'
    })
  );

  return updatedDictionary;
});

/**
 * When selecting a row to archive, we give the user the option to also archive the rows in the same dictionary at child organizations.
 * Therefore we recursivley search for the dictionary by name for each org, then we look for the specific row in each dictionary
 **/
const selectArchiveRow = createAsync<
  number,
  CMxAPI.DictionaryRow,
  CMxCommonApp.CMxError
>(
  ArchiveModal.SELECT_ARCHIVE_ROW,
  async (rowId, dispatch): Promise<CMxAPI.DictionaryRow> => {
    dispatch(toggleArchiveModal(true));
    const row = await api<CMxAPI.DictionaryRow>({
      endpoint: `/dictionary/row/${rowId}/v2`
    });

    const orgChildrenParams = new URLSearchParams({
      tenantId: row.tenantId.uuid
    });

    const orgs = await api<CMxAPI.Organization[]>({
      endpoint: `/organization/children/v1?${orgChildrenParams.toString()}`
    });

    const defaultDictionaryParams = {
      derivedIndicator: 'false',
      effectiveDateStr:
        row.effectiveDate ||
        cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    };
    const dictionaryQueries = orgs.map(org => {
      const dictionarySearchParams = new URLSearchParams({
        ...defaultDictionaryParams,
        tenantId: org.tenantId
      });

      return api<CMxAPI.Dictionary>({
        endpoint: `/dictionary/${
          row.dictionaryName
        }/v2?${dictionarySearchParams.toString()}`
      }).catch(() => undefined);
    });

    const [...dictionaries] = await Promise.all(dictionaryQueries);

    const keyCell = row.cells.find(
      cell => cell.columnDefinition.keyColumnIndicator
    );

    const defaultRowParams = new URLSearchParams({
      page: '0',
      size: '25',
      listType: 'column'
    });

    const rowBody = [
      {
        key: 'status',
        terms: [DictionaryStatus.PUBLISHED],
        type: FilterTypes.IN
      },
      {
        key: keyCell?.columnDefinition.name,
        terms: [keyCell?.value],
        type: FilterTypes.LIKE
      }
    ];

    const removeNulls = <S>(value: S | undefined): value is S => value != null;
    const rowQueries = dictionaries.filter(removeNulls).map(dict => {
      return api<CMxAPI.PageableList<CMxAPI.DictionaryRow>>({
        endpoint: `/dictionary/${
          dict.id
        }/row/v2?${defaultRowParams.toString()}`,
        init: {
          method: HttpMethods.POST
        },
        body: rowBody
      }).catch(() => paginationUtils.emptyPageable);
    });

    const [...potentialArchives] = await Promise.all(rowQueries);

    // We can return rows[0] since we are looking for a value match in the key column, which is unique.

    dispatch(
      savePotentialArchives(
        potentialArchives
          .filter(rows => rows.numberOfElements)
          .map(rows => rows.content[0])
      )
    );

    return row;
  }
);

const archiveRow = createAsync<
  CMx.archiveRowPayload,
  CMxAPI.DictionaryRow[],
  CMxCommonApp.CMxError
>(
  ArchiveModal.ARCHIVE_ROW,
  async (payload, dispatch): Promise<CMxAPI.DictionaryRow[]> => {
    const { row, archiveChildren } = payload;

    const keyValue = row.cells.find(
      cell => cell.columnDefinition.keyColumnIndicator
    )?.value;

    let archivedRows: CMxAPI.DictionaryRow[] = [];
    if (archiveChildren && keyValue) {
      const searchParams = new URLSearchParams({
        endDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE),
        rowKey: keyValue,
        tenantId: row.tenantId.uuid
      });

      archivedRows = await api<CMxAPI.DictionaryRow[]>({
        endpoint: `/dictionary/${
          row.dictionaryName
        }/row/archive/v2?${searchParams.toString()}`,
        init: {
          method: HttpMethods.PUT
        }
      });
    }
    const searchParams = new URLSearchParams({
      endDate: cmxDateTime.format(new Date(), cmxDateTime.FORMATS.DATE)
    });

    const archivedRow = await api<CMxAPI.DictionaryRow>({
      endpoint: `/dictionary/row/${
        row.id
      }/archive/v2?${searchParams.toString()}`,
      init: {
        method: HttpMethods.PUT
      }
    });

    dispatch(listItems(payload));
    return [archivedRow, ...archivedRows];
  }
);

const deleteDictionary = createAsync<
  string[],
  boolean,
  CMxCommonApp.SubmitError
>(DictionaryValues.DELETE_DICTIONARY, async (deleteIds, dispatch) => {
  const deleteResponses = await Promise.all(
    deleteIds.map(id => {
      return (
        api<CMxAPI.DictionaryRequestResponse>({
          endpoint: `/dictionary/${id}/v2`,
          init: {
            method: HttpMethods.DELETE
          }
        }) || false
      );
    })
  ).catch(e => {
    if (e instanceof BadRequestError) {
      dispatch(dismissFeedback(false));
      dispatch(
        showFeedbackError({
          id: Date.now(),
          message: `Dictionary Delete Unsuccessful`,
          dismissable: true
        })
      );
    }
    throw e;
  });

  const successRoute = window.location.pathname
    .split('/')
    .slice(0, 6)
    .join('/');

  let navParams = new URLSearchParams();
  deleteResponses.forEach(res => {
    navParams.append('requestId', res.id.toString());
  });

  dispatch(replace(successRoute + `/request?${navParams.toString()}`));

  return false;
});

const updateDictionaryDescription = createAsync<
  CMxAPI.Dictionary,
  CMxAPI.Dictionary,
  CMxCommonApp.CMxError
>(DictionaryValues.UPDATE_DICTIONARY_DESCRIPTION, async (payload, dispatch) => {
  const updatedDictionary = await api<CMxAPI.Dictionary>({
    endpoint: `/dictionary/description/v2`,
    init: {
      method: HttpMethods.PUT
    },
    body: payload
  });

  return updatedDictionary;
});

const unrestrictDictionary = createAsync<Number, void, CMxCommonApp.CMxError>(
  DictionaryValues.UNRESTRICT_DICTIONARY,
  async (dictionaryId, dispatch) => {
    await api<CMxAPI.Dictionary>({
      endpoint: `/dictionary/${dictionaryId}/unrestricted/v2`,
      init: {
        method: HttpMethods.PUT
      }
    }).catch(e => {
      dispatch(dismissFeedback(false));
      dispatch(
        showFeedbackError({
          id: Date.now(),
          message: `Dictionary Unrestrict Unsuccessful`,
          dismissable: true
        })
      );
    });
  }
);

const applyFilters = createAsync<string, string, CMxCommonApp.SubmitError>(
  DictionaryValues.SET_STATUS_FILTER,
  async (status, dispatch) => {
    dispatch(setStatusFilter(status));
    return status;
  }
);

export const syncActions = {
  chooseRow,
  setStatusFilter,
  selectKeyColumn,
  confirmMappingsExist,
  setDictionaryMappings,
  selectActiveColumn,
  saveColumnMapping,
  removeColumnMapping,
  toggleArchiveModal,
  setArchiveChildren,
  savePotentialArchives,
  updateDictionaryValue
};

export {
  listItems,
  loadDictionary,
  loadValue,
  saveDictionaryRow,
  listDictionaryOptions,
  listColumnOptions,
  publishDictionary,
  addColumnMetadata,
  removeColumnMetadata,
  chooseColumnReference,
  initUpload,
  downloadDictionary,
  downloadDictionaryByRequestID,
  listAssociatedDictionaries,
  listDictionaryHistory,
  initializeColumnMetadataEditor,
  saveColumnDefinitions,
  selectArchiveRow,
  archiveRow,
  toggleDeleteModal,
  deleteDictionary,
  toggleUploadType,
  toggleInfoModal,
  toggleSecurityModal,
  unrestrictDictionary,
  updateDictionaryDescription,
  applyFilters,
  getDraftFilters
};
