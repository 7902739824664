/// <reference path="../types.d.ts" />

import actionCreatorFactory from 'typescript-fsa';
import { Analytics } from '../actions/action-types';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { api } from '../core/net';
import { HttpMethods } from '@codametrix/ui-common';

/** factories for actions */
const actionCreator = actionCreatorFactory();
const createAsync = asyncFactory<CMx.AnalyticsState>(actionCreator);
const instantiateDashboard = actionCreator<void>(
  Analytics.INSTANTIATE_DASHBOARD
);

const setActiveDashboard = actionCreator<string>(
  Analytics.SET_ACTIVE_DASHBOARD
);

type TableauToken = {
  tableauToken: string;
};

const fetchTableauToken = createAsync<
  CMx.ServiceLine | undefined,
  String,
  void
>(Analytics.GET_TABLEAU_TOKEN, async (serviceLine, dispatch) => {
  const searchParams = new URLSearchParams({});

  if (serviceLine) {
    searchParams.set('serviceLine', serviceLine.name);
  }

  const result = await api<TableauToken>({
    endpoint: `/tableau/token/v1?${searchParams.toString()}`,
    init: {
      method: HttpMethods.POST
    }
  });

  return result?.tableauToken ?? '';
});

const showProductivityManagerReport = createAsync<
  {
    key: string;
    tenantId: string;
  },
  CMxAPI.ConfigurationValue[],
  CMxCommonApp.SubmitError
>('', async (payload, dispatch) => {
  const { key, tenantId } = payload;

  const searchParams = new URLSearchParams({
    key: key,
    tenantId: tenantId
  });

  try {
    const showReport = await api<CMxAPI.ConfigurationValue[]>({
      endpoint: `/configuration/value/v1?${searchParams.toString()}`,
      init: {
        method: HttpMethods.GET
      }
    });
    return showReport;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

export {
  instantiateDashboard,
  fetchTableauToken,
  setActiveDashboard,
  showProductivityManagerReport
};
