import theme from '../../theme';
import { Divider, Grid, GridItem } from '@chakra-ui/react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { applyFilters, clearAllFilters } from '../../actions/audit-log';
import { DataGrid } from '../../components/data-grid/data-grid';
import { SearchBar } from '../../components/search-bar/search-bar';
import { PopUpFiltersV2 } from '../pop-up-filter/pop-up-filtersV2';
import { styles } from './audit-log.styles';
import { commonEnums as enums } from '@codametrix/ui-common';
import { RowsPerPage } from '../../components/rows-per-page/rows-per-page';

const { FilterTypes } = enums;

const _isDictAction = (action: any): action is CMxAPI.DictionaryAction => {
  return action.dictionaryEventType !== undefined;
};

export const colDefs: ColDef[] = [
  {
    field: 'timestamp',
    headerName: 'Event Date',
    valueFormatter: data => data.data.eventDate?.substring(0, 10),
    tooltipField: 'timestamp',
    width: 50,
    sortable: false
  },
  {
    field: 'username-email',
    headerName: 'Username',
    valueFormatter: data => data.data?.username,
    tooltipField: 'username-email',
    tooltipValueGetter(params: ITooltipParams) {
      return params.data?.username;
    },
    sortable: false
  },
  {
    field: 'dictionaryEventType',
    headerName: 'Event Type',
    valueFormatter: data => {
      return _isDictAction(data.data)
        ? data.data?.dictionaryEventType
        : data.data?.organizationEventType;
    }
  },
  {
    field: 'eventTarget',
    headerName: 'Event Target',
    valueFormatter: data => {
      if (_isDictAction(data.data)) {
        return data.data.dictionaryName || data.data.deletedDictionaryName;
      }
      return data.data.organizationName || data.data.serviceLineName;
    }
  },
  {
    field: 'tenantID',
    headerName: 'Tenant',
    valueFormatter: data => data.data?.tenantId,
    sortable: false
  },
  {
    field: 'dictEffectiveDate',
    headerName: 'Effective Date',
    valueFormatter: data => data.data?.newDictionaryEffectiveDate,
    sortable: false
  }
];

const AuditLog = (props: AppProps.AuditLogProps) => {
  const dispatch = useDispatch();
  const classes = styles();
  const {
    sortablePageable,
    criteriaFilter,
    filterLoadStates,
    getList,
    activeOrgId,
    auditLogs
  } = props;
  const searchForms = useMemo(
    () =>
      criteriaFilter?.searchForms.map(filterForm => {
        return {
          label: filterForm.label,
          value: filterForm.key
        };
      }),
    [criteriaFilter]
  );
  const [selectedOption, setSelectedOption] = useState(searchForms?.[0]?.label);
  const [searchText, setSearchText] = useState<string>('');
  const [pageSize, setPageSize] = useState<number>(25);
  const [filterableOptions, setFilterableOptions] = useState<
    CMxCommonApp.FilterableOption
  >(sortablePageable?.filterableOptions);

  useEffect(() => {
    getList({
      ...sortablePageable,
      filterableOptions,
      pageableDefinition: {
        ...sortablePageable.pageableDefinition,
        pageable: {
          ...sortablePageable.pageableDefinition.pageable,
          pageSize: sortablePageable.pageableDefinition.totalElements
        }
      }
    });
    dispatch(applyFilters(filterableOptions));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterableOptions]);

  const handleSearchOptionChange = (event: any) => {
    setSelectedOption(event?.target?.textContent);
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchText(event?.target?.value);
  };

  const handleSearchKeyPress = (
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event?.keyCode === 13) {
      let newFilterList: CMxCommonApp.Filter[] = [];
      const selectedOptionKey: string =
        searchForms.find(searchForm => searchForm.label === selectedOption)
          ?.value ?? '';

      newFilterList = criteriaFilter.filters.filter(filter => {
        return filter.type === FilterTypes.IN;
      });

      const SearchFormMatch = criteriaFilter.searchForms.find(filter => {
        return filter.key === selectedOptionKey;
      });

      if (SearchFormMatch) {
        const newFilter: CMxCommonApp.Filter = {
          key: selectedOptionKey,
          terms: [searchText.trim()],
          type: SearchFormMatch.type
        };
        newFilterList.push(newFilter);
      }
      setFilterableOptions({
        filters: newFilterList,
        dateFilter: filterableOptions.dateFilter
      });
    }
  };

  const clearFilters = () => {
    setFilterableOptions({});
    setSearchText('');
    dispatch(clearAllFilters());
  };

  const onPageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
  };

  return (
    <div style={classes.root}>
      <Grid
        flexDirection={'row'}
        gridTemplateRows={'min-content min-content min-content'}
        gap={theme.space[12]}>
        <GridItem>
          <SearchBar
            selectOptions={searchForms}
            selectedOption={selectedOption}
            onSelectOptionChange={handleSearchOptionChange}
            onSearchInputChange={handleSearchInputChange}
            onSearchKeyPress={handleSearchKeyPress}
          />
        </GridItem>
        <GridItem>
          <Divider sx={classes.divider} />
        </GridItem>
        <GridItem>
          <Grid
            display={'flex'}
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between">
            <PopUpFiltersV2
              criteriaFilter={criteriaFilter}
              loadStates={filterLoadStates}
              sortablePageable={sortablePageable}
              list={() =>
                getList({
                  ...sortablePageable,
                  filterableOptions
                })
              }
              getDictionary={() => {}}
              getStatuses={() => {}}
              getTenants={() => {}}
              clearFilters={clearFilters}
              activeOrgId={activeOrgId}
              setFilterableOptions={setFilterableOptions}
            />
            <RowsPerPage
              pageSize={pageSize}
              handlePageSize={onPageSizeChange}
            />
          </Grid>
        </GridItem>
        <GridItem>
          <DataGrid
            columnDefs={colDefs}
            rowData={auditLogs}
            paginationPageSize={pageSize}
          />
        </GridItem>
      </Grid>
    </div>
  );
};

export { AuditLog };
