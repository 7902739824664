import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps
} from '@chakra-ui/react';
import { ButtonSize, ButtonVariant } from '../../core/enums';

export type ButtonProps = {
  size?: ButtonSize;
  label: string;
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  variant?: ButtonVariant;
  rightIcon?: React.ReactElement;
  leftIcon?: React.ReactElement;
  className?: string;
  color?: string;
  backgroundColor?: string;
  dataTestId?: string;
} & ChakraButtonProps;

export const Button: React.FC<ButtonProps> = ({
  size,
  label,
  disabled,
  variant,
  rightIcon,
  leftIcon,
  className,
  color,
  backgroundColor,
  dataTestId,
  ...props
}) => {
  return (
    <ChakraButton
      {...props}
      variant={variant}
      disabled={disabled}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      size={size}
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      data-testid={dataTestId?.trim() ?? 'button'}>
      {label}
    </ChakraButton>
  );
};

Button.defaultProps = {
  variant: ButtonVariant.PRIMARY,
  size: ButtonSize.MD,
  className: '',
  disabled: false
};

Button.displayName = 'Button';
