import { Flex, Grid } from '@chakra-ui/react';
import React from 'react';
import { Button } from '../../../components/button/button';
import { SearchBar } from '../../../components/search-bar/search-bar';
import { MenuItemType } from '../../../components/select/select';
import { ButtonVariant } from '../../../core/enums';
import { styles } from '../dictionary-styles';
import { getDictionaryValueItems } from './dictionary-value-utils';
import { ReactComponent as VerticalMenu } from '../../../assets/images/vertical-menu.svg';
import { MenuIcon } from '../../../components/menu-icon/menu-icon';
import { PopUpFiltersV2 } from '../../pop-up-filter/pop-up-filtersV2';
import { RowsPerPage } from '../../../components/rows-per-page/rows-per-page';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';

export type DictionaryValueListHeaderProps = {
  deleteDisabled?: boolean;
  downloadDisabled?: boolean;
  handleHistoryClick: () => void;
  handleMetadataClick: () => void;
  handleSearchKeyPress: (event?: React.KeyboardEvent<HTMLInputElement>) => void;
  handleSearchOptionChange: (event?: React.MouseEvent<HTMLElement>) => void;
  handleSearchInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  metadataDisabled?: boolean;
  publishDisabled?: boolean;
  selectedOption?: string;
  searchForms?: any;
  uploadDisabled?: boolean;
  uploadItems: MenuItemType[];
  toggleInfoModal: (payload: boolean) => void;
  isInfoModalOpen?: boolean;
  viewOnly: boolean;
  toggleUploadType: (isCorrection: boolean) => void;
  navigate?: (path: string) => void;
  handlePublish: () => void;
  handleDownload: () => void;
  handleDeleteSelect: () => void;
  toggleSecurityModal: (payload: boolean) => void;
  isSecurityModalOpen?: boolean;
  criteriaFilter: AppProps.CriteriaFilter;
  sortablePageable: CMxCommonApp.SortablePageable<any>;
  setFilterableOptions?: Function;
  isUnrestrictedDictionary?: boolean;
  pageSize: number;
  onPageSizeChange: (pageSize: number) => void;
};

const DictionaryValueListHeader: React.FC<DictionaryValueListHeaderProps> = props => {
  const {
    deleteDisabled,
    downloadDisabled,
    handleHistoryClick,
    handleMetadataClick,
    handleSearchKeyPress,
    handleSearchOptionChange,
    handleSearchInputChange,
    metadataDisabled,
    publishDisabled,
    selectedOption,
    searchForms,
    toggleInfoModal,
    isInfoModalOpen,
    viewOnly,
    toggleUploadType,
    navigate,
    handlePublish,
    handleDownload,
    handleDeleteSelect,
    toggleSecurityModal,
    isSecurityModalOpen,
    criteriaFilter,
    sortablePageable,
    setFilterableOptions,
    isUnrestrictedDictionary,
    pageSize,
    onPageSizeChange
  } = props;
  const classes = styles();

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLButtonElement;
    if (target.value === 'History') {
      handleHistoryClick();
    }
    if (target.value === 'Info') {
      handleInfoSelect();
    }
    if (target.value === 'Security') {
      handleSecuritySelect();
    }
    if (target.value === 'Delete') {
      handleDeleteSelect();
    }
    if (target.value === 'Metadata') {
      handleMetadataClick();
    }
    if (target.value === 'Upload New Version') {
      toggleUploadType(false);
      handleUpload();
    }
    if (target.value === 'Make a Correction') {
      toggleUploadType(true);
      handleUpload();
    }
    if (target.value === 'Download') {
      if (!downloadDisabled) {
        handleDownload();
      }
    }
  };
  const handleInfoSelect = () => {
    toggleInfoModal(!isInfoModalOpen);
  };

  const handleSecuritySelect = () => {
    toggleSecurityModal(!isSecurityModalOpen);
  };

  const handleUpload = () => {
    if (navigate) {
      navigate(window.location.pathname + `/upload`);
    }
  };

  const handleAddRow = () => {
    if (navigate) {
      navigate(window.location.pathname + `/row/add`);
    }
  };

  return (
    <>
      <Grid display="flex" flexDirection="row">
        <Flex marginBottom={5}>
          <BreadCrumbsComponent />
        </Flex>
        {isUnrestrictedDictionary ? (
          <i
            className="fas fa-unlock"
            style={classes.unrestrictedDictionary}></i>
        ) : null}
        <Grid sx={classes.btnHeader}>
          <PopUpFiltersV2
            loadStates={undefined}
            criteriaFilter={criteriaFilter}
            sortablePageable={sortablePageable}
            getDictionary={() => {}}
            getStatuses={() => {}}
            list={() => {}}
            getTenants={() => {}}
            activeOrgId={0}
            setFilterableOptions={setFilterableOptions}
            isUnrestrictedDictionary={isUnrestrictedDictionary}
          />
          <Button
            label="Publish"
            disabled={publishDisabled}
            variant={ButtonVariant.SECONDARY}
            name="Publish"
            dataTestId="publish"
            onClick={handlePublish}
          />
          <Button
            label="+ Add Row"
            variant={ButtonVariant.PRIMARY}
            name="AddRow"
            disabled={viewOnly}
            dataTestId="addRow"
            onClick={handleAddRow}
          />
          <MenuIcon
            items={getDictionaryValueItems(
              deleteDisabled,
              metadataDisabled,
              downloadDisabled
            )}
            dataTestId="dictionaryValueMenu"
            icon={<VerticalMenu />}
            onChange={handleMenuItemClick}
          />
        </Grid>
      </Grid>
      <Grid
        display={'flex'}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between">
        <SearchBar
          selectOptions={searchForms}
          selectedOption={selectedOption}
          onSelectOptionChange={handleSearchOptionChange}
          onSearchInputChange={handleSearchInputChange}
          onSearchKeyPress={handleSearchKeyPress}
        />
        <RowsPerPage pageSize={pageSize} handlePageSize={onPageSizeChange} />
      </Grid>
    </>
  );
};

DictionaryValueListHeader.displayName = 'DictionaryValueListHeader';
export { DictionaryValueListHeader };
