import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOAD_STATES } from '../../../reducers/common';
import {
  chooseColumnReference,
  listColumnOptions,
  listDictionaryOptions,
  syncActions
} from '../../../actions/dictionary-values';
import { push } from 'connected-react-router';
import { Flex, Grid } from '@chakra-ui/react';
import { Form } from '../form';
import { BreadCrumbsComponent } from '../../../components/breadcrumbs/breadcrumbsComponent';
import { showFeedback } from '../../../actions/ui';
import LoaderOverlay from '../../../components/loader-overlay/loader-overlay';
import { styles } from '../dictionary-styles';

const CrossMapEdit = (props: any) => {
  const dispatch = useDispatch();

  const { loadState, activeColumn, activeColumnMetadataObj } = useSelector(
    (state: CMx.ApplicationState) => {
      return state.dictionaryValues.columnMetadataEditor;
    }
  );

  const tenantId = useSelector((state: CMx.ApplicationState) => {
    return state.dictionaryRequest.dictionaryMetadata.tenantId;
  });

  const { columnMetadataForm, loadState: tenantLoad } = useSelector(
    (state: CMx.ApplicationState) => {
      return state.dictionaryRequest;
    }
  );

  const tenantOptions = useSelector((state: CMx.ApplicationState) => {
    // Getting tenant list from metadata form
    return state.dictionaryRequest?.columnMetadataForm?.fields![1]?.options;
  });

  useEffect(() => {
    if (activeColumn.keyColumnIndicator) {
      dispatch(
        showFeedback({
          message: (
            <div>
              Crossmapping the key column <b>{activeColumn.name}</b> is not
              advised. Key columns should be unique to a dictionary
            </div>
          ),
          meta: { level: 'WARNING' },
          dismissable: true,
          className: 'feedback-error',
          id: Date.now()
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePropChange = (prop: string, value: any) => {
    if (prop === 'referenceTenant') {
      let organizationName: string = '';
      tenantOptions?.forEach((tenant: any) => {
        if (tenant.key === value) {
          organizationName = tenant.tenantName;
        }
      });
      const payload = { tenantId: value, orgName: organizationName };
      dispatch(listDictionaryOptions(payload));
    }
    if (prop === 'referenceDictionary') {
      const payload = {
        tenantId: tenantId,
        dictionaryName: value
      };
      dispatch(listColumnOptions(payload));
    }
    if (prop === 'referenceColumn') {
      dispatch(chooseColumnReference(value));
    }
  };

  const handleSaveCrossmap = (submitEvent: CMx.ColumnMetadataDataItem) => {
    const payload = {
      mappingInfo: submitEvent,
      columnInfo: activeColumn
    };
    dispatch(syncActions.saveColumnMapping(payload));
    props.history.goBack();
  };

  const handleAction = (event: CustomEvent) => {
    const { detail } = event;
    if (detail.eventType === 'cancel') {
      const pathParts = window.location.pathname.split('/');
      pathParts.pop();
      pathParts.pop();
      dispatch(push(`${pathParts.join('/')}`));
    }
  };

  const subformEnabler = () => {
    return loadState !== LOAD_STATES.started;
  };

  const classes = styles();
  return (
    <div>
      <Grid sx={tenantLoad.loading ? classes.breadCrumbGrid : {}}>
        <Flex>
          <BreadCrumbsComponent />
        </Flex>
      </Grid>
      {tenantLoad.loading ? (
        <Grid sx={classes.loaderWrapper}>
          <LoaderOverlay />
        </Grid>
      ) : (
        <Form
          formDefinition={columnMetadataForm}
          dataItem={activeColumnMetadataObj}
          onPropChange={handlePropChange}
          onSave={handleSaveCrossmap}
          enable={subformEnabler}
          onAction={handleAction}
          title={`${activeColumn?.name} Crossmaps`}
          errors={{
            status: '',
            message: null,
            errors: [],
            fieldErrors: {}
          }}
          inline={false}
        />
      )}
    </div>
  );
};

export { CrossMapEdit };
