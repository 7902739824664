import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

import { logoutApp } from '../../actions/login.action';
import * as uiActions from '../../actions/ui';
import { jumpContext } from '../../actions/contexts';
import {
  commonActions,
  cmxURL,
  authenticationChannel,
  commonEnums,
  logger
} from '@codametrix/ui-common';
import { runtimeOverrides, RUNTIME_OVERRIDE_KEY } from '../runtime-overrides';
import { isNewDesign } from './new-design-middleware';
import { codeBenchActions } from '@codametrix/ui-common';
import { roleShim } from '../../actions/_action-utilities';

const { UserInterface } = commonActions;
const { loadRoles } = codeBenchActions;

const openWindows: Window[] = [];
const cleanUpActions = new Set([
  `${jumpContext.async.started}`,
  `${logoutApp.async.started}`,
  `${uiActions.logout}`
]);

const DEFAULT_WINDOW_ARGS = {
  windowTitle: '_blank'
};

const cleanupWindows = () => {
  while (openWindows.length) {
    const handle = openWindows.pop();
    try {
      if (handle) {
        handle.close();
      }
    } catch (e) {
      logger.error(`issue closing window`);
    }
  }
};

const newDesignRuntimeOverride = '{"newAdminDesign":"true"}';

export const WindowOpenMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  const shouldCleanup = cleanUpActions.has(action.type);

  // open the window and keep
  if (action.type === UserInterface.OPEN_CHILD_WINDOW) {
    const windowOpts = { ...DEFAULT_WINDOW_ARGS, ...action.payload };
    // construct URL
    const loc = `${window.location.origin}`;
    const searchParams = new URLSearchParams({
      ...action.payload.params,
      channel: authenticationChannel.name
    });
    searchParams.set(
      commonEnums.AuthenticationChannelEvents.BOOTSTRAP_AUTH,
      `1`
    );

    // if runtime overrides are present pass them along to child
    // windows as well.
    if (Object.keys(runtimeOverrides).length !== 0) {
      console.log(Object.keys(runtimeOverrides));
      searchParams.set(RUNTIME_OVERRIDE_KEY, JSON.stringify(runtimeOverrides));
    }

    // Temporary fix for new design because
    // some popups do not include a component-based redesign
    if (isNewDesign) {
      searchParams.append(RUNTIME_OVERRIDE_KEY, newDesignRuntimeOverride);
    }
    // CQA-UI append v2/ in case of new UI flow
    const isNewFlow = window.location.pathname.endsWith('/v2/');
    const openLocation = `${action.payload.url}${
      isNewFlow ? 'v2/' : ''
    }?${searchParams}`;
    const url = new URL(openLocation, loc);
    const formattedUrl = cmxURL.pathBuilder(url);

    // construct window title.  each auth channel will have it's own set.
    // Commenting below to enable single child window functionality
    const windowTitle = `${windowOpts.windowTitle}_${
      authenticationChannel.name
    }_${searchParams.get('windowNameExtension') ?? ''}`;

    const handle = window.open(
      formattedUrl,
      windowTitle,
      windowOpts.windowFeatures
    );

    // Ensure any pop-up window opens up is managed
    const managedString = `${windowOpts?.params?.managed ?? false}`;
    const managed = managedString === 'true';

    if (handle !== null && managed) {
      openWindows.push(handle);
    }
  } else if (action.type === UserInterface.REPLACE_UI_STATE) {
    const roles = roleShim(action?.payload?.context, []);
    if (roles?.length > 0) {
      next(loadRoles(roles));
    }
  } else if (shouldCleanup) {
    cleanupWindows();
  }

  return next(action);
};

window.addEventListener('unload', cleanupWindows);

export default WindowOpenMiddleware;
