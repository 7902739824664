import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Flex, Text } from '@chakra-ui/react';
import Input from '../input/input';
import './datepicker-component.scss';
import { styles } from './datepicker-component.styles';
import { DatePickerHeader } from './datepicker-component-header';
import * as dateUtils from '../../core/utils/date';
import { InputVariant } from '../../core/enums';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';

export type DatePickerComponentProps = {
  onSelection?: (startDate: Date | null, endDate: Date | null) => void;
  startDate: Date | null;
  endDate: Date | null;
  label?: string;
  placeholder?: string;
  showRange?: boolean;
  classNames?: any;
  selectsRange?: boolean;
  iconRight?: React.ReactElement;
  iconLeft?: React.ReactElement;
  showActiveBackground?: boolean;
};

const TEXT_CONSTANTS = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year'
};

const getDisplayDate = (
  startDate: Date | null,
  endDate: Date | null,
  showRange: boolean
) => {
  if (!startDate) {
    return null;
  }
  const formattedStartDate = dateUtils.convertToDateFormat(startDate);
  let displayDate: string = formattedStartDate;
  if (showRange) {
    displayDate = `${formattedStartDate} to ${formattedStartDate}`;
  }

  if (endDate) {
    const formattedEndDate = dateUtils.convertToDateFormat(endDate);
    displayDate = `${formattedStartDate} to ${formattedEndDate}`;
  }
  return displayDate;
};

const DatePickerComponent: React.FC<DatePickerComponentProps> = props => {
  const {
    onSelection,
    placeholder = 'Select Date',
    label,
    startDate,
    endDate,
    showRange = true,
    classNames,
    selectsRange = true,
    iconRight,
    showActiveBackground
  } = props;

  const [_startDate, setStartDate] = useState<Date | null>(startDate);
  const [_endDate, setEndDate] = useState<Date | null>(endDate);
  const [datePickerType, setDatePickerType] = useState(TEXT_CONSTANTS.DAY);
  const [closeDatePicker, setCloseDatePicker] = useState<boolean>(true);
  const [isDatePickerOpen, setDatePickerOpen] = useState<boolean>(false);

  useEffect(() => {
    setStartDate(startDate);
    setEndDate(endDate);
  }, [startDate, endDate]);

  const onChange = (dates: Array<Date | null> | Date | null) => {
    let start = null;
    let end = null;
    if (Array.isArray(dates)) {
      [start, end] = dates;
    } else {
      start = dates;
    }
    /** show months picker when year is selected,
     * show days picker when month is selected and
     *  set start and end dates when date is selected
     * */
    switch (datePickerType) {
      case TEXT_CONSTANTS.DAY: {
        setStartDate(start);
        setEndDate(end);
        onSelection && onSelection(start, end);
        break;
      }
      case TEXT_CONSTANTS.MONTH: {
        setDatePickerType(TEXT_CONSTANTS.DAY);

        /** Close the datepicker on select under the following conditions
         * Selecting a single date (or) the start and end dates in a range picker
         */
        if (!selectsRange && dates) {
          setCloseDatePicker(true);
        } else if (selectsRange && Array.isArray(dates)) {
          if (dates[0] && dates[1]) {
            setCloseDatePicker(true);
          }
        } else {
          setCloseDatePicker(false);
        }
        break;
      }
      case TEXT_CONSTANTS.YEAR: {
        setDatePickerType(TEXT_CONSTANTS.MONTH);
        break;
      }
      default: {
        console.warn(`Unsupported datepicker change ${datePickerType}`);
      }
    }
  };

  const handleClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    setDatePickerType(TEXT_CONSTANTS.DAY);
    onSelection && onSelection(null, null);
  };

  const onDayPickerMonthClick = () => {
    closeDatePicker && setCloseDatePicker(false);
    setDatePickerType(TEXT_CONSTANTS.MONTH);
  };

  const onHeaderYearClick = () => {
    closeDatePicker && setCloseDatePicker(false);
    setDatePickerType(TEXT_CONSTANTS.YEAR);
  };

  const handleCalendarClose = () => {
    isDatePickerOpen && setDatePickerOpen(false);
    setDatePickerType(TEXT_CONSTANTS.DAY);
  };

  const handleInputClick = () => {
    !isDatePickerOpen && setDatePickerOpen(true);
  };

  const isClearBtnDisabled: boolean = !Boolean(_startDate || _endDate);
  const classes = styles();
  return (
    <DatePicker
      className="datepicker-component"
      placeholderText={placeholder}
      selected={_startDate}
      startDate={_startDate}
      endDate={_endDate}
      onChange={onChange}
      selectsRange={selectsRange}
      shouldCloseOnSelect={closeDatePicker}
      dateFormat={dateUtils.FORMATS.DATE}
      onCalendarClose={handleCalendarClose}
      customInput={
        (_startDate && _endDate) || !selectsRange ? (
          <div>
            <Flex alignItems={'center'}>
              <CalendarIcon style={classes.placeholderCalendar} />
              <Input
                autoComplete="off"
                iconRight={iconRight}
                variant={InputVariant.TEXT}
                value={getDisplayDate(_startDate, _endDate, showRange) ?? label}
                placeholder={placeholder}
                classes={{
                  input:
                    isDatePickerOpen && showActiveBackground
                      ? classes.selectedInput
                      : classNames?.input ?? classes.input,
                  formLabel: classNames?.formLabel ?? classes.formLabel,
                  leftIcon: classNames?.leftIcon ?? classes.leftIcon,
                  rightIcon: classNames?.rightIcon
                }}
                onClick={handleInputClick}
              />
            </Flex>
          </div>
        ) : (
          <div style={classes.inputPlaceholder}>
            <CalendarIcon style={classes.placeholderCalendar} />
            <Text sx={classes.defaultText}>Service Date</Text>
          </div>
        )
      }
      showYearPicker={TEXT_CONSTANTS.YEAR === datePickerType}
      showMonthYearPicker={TEXT_CONSTANTS.MONTH === datePickerType}
      showFourColumnMonthYearPicker
      renderCustomHeader={({
        monthDate,
        date,
        decreaseMonth,
        increaseMonth,
        decreaseYear,
        increaseYear
      }) => (
        <div>
          {datePickerType === TEXT_CONSTANTS.DAY && (
            <DatePickerHeader
              onLeftArrowClick={decreaseMonth}
              onRightArrowClick={increaseMonth}
              monthDate={monthDate}
              onMonthClick={onDayPickerMonthClick}
              onYearClick={onHeaderYearClick}
              dayPicker
              isClearBtnDisabled={isClearBtnDisabled}
              handleClearDate={handleClearDate}
            />
          )}
          {datePickerType === TEXT_CONSTANTS.YEAR && (
            <DatePickerHeader
              onLeftArrowClick={decreaseYear}
              onRightArrowClick={increaseYear}
              yearRange={dateUtils.getYearRange(date)}
              yearPicker
              isClearBtnDisabled={isClearBtnDisabled}
              handleClearDate={handleClearDate}
            />
          )}
          {datePickerType === TEXT_CONSTANTS.MONTH && (
            <DatePickerHeader
              onLeftArrowClick={decreaseYear}
              onRightArrowClick={increaseYear}
              selectedYear={date.getFullYear().toString()}
              onMonthPickerYearClick={onHeaderYearClick}
              monthPicker
              isClearBtnDisabled={isClearBtnDisabled}
              handleClearDate={handleClearDate}
            />
          )}
        </div>
      )}
    />
  );
};

DatePickerComponent.displayName = 'DatePickerComponent';
export { DatePickerComponent };
