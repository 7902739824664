import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

const findFunctions = (payload: any) => {
  Object.keys(payload).forEach(prop => {
    if (
      payload[prop] &&
      typeof payload[prop] !== 'string' &&
      Object.keys(payload[prop]).length > 0
    ) {
      findFunctions(payload[prop]);
    } else if (typeof payload[prop] === 'function') {
      console.warn(
        "State is at risk of containing non-JSON objects! If you're reading this, please report this incident to the WEBAPP team."
      );
    }
  });
};

// Redux requires that the state is comprised of only plain JSON objects.
// The goal of this middleware is to send a warning when a non-JSON object
// is found being passed into a reducer
const stateDisinfectantMiddleware: Middleware = (api: MiddlewareAPI) => (
  next: Dispatch<AnyAction>
) => (action: AnyAction) => {
  const { payload } = action;

  if (payload) {
    findFunctions(payload);
  }

  return next(action);
};

export default stateDisinfectantMiddleware;
